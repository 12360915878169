import { FETCH_DEALER_LOCATION } from "../actions";

/* Function for Dealer Location Reducer */
const getDealerLocationReducer = (dealerLocation = {dealerRadius:"",latitude:"",longitude:"",bookATestDrive:"",bookAService:"",bookACallBack:"",dealerRadiusUnit:""}, action) => {
    switch (action.type) {
        case FETCH_DEALER_LOCATION:
            return action.payload;
        default:
            return dealerLocation;

    }
};

export default getDealerLocationReducer;