import { FETCH_ALL } from "../actions";

/* Function for models Reducer */
const modelsReducer = (models = [], action) => {
    switch (action.type) {
        case FETCH_ALL:
            return action.payload;

        default:
            return models;

    }
};

export default modelsReducer;