import { FETCH_LANDINGPAGE_ALL } from "../actions";

/* Function for landingPage Reducer */
const landingPagesReducer = (landingPage = [], action) => {
    switch (action.type) {
        case FETCH_LANDINGPAGE_ALL:
            return action.payload;
        default:
            return landingPage;

    }
};

export default landingPagesReducer;