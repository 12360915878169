import { useState } from "react";
import PlusIcon from "../../../assets/images/plus_1.svg";
import MinusIcon from "../../../assets/images/minus.svg";
import { Col, Row } from "react-bootstrap";

const SidebarAccordion = ({ title, children, submenu }) => {
  const [collapse, setCollapse] = useState(false);
  return (
    <>
      <div className="sidebarAccordion">
        {/* side bar title */}
        <div>
            <button
              className={[
                "sidebarAccordion__header",
                !collapse && "sidebarAccordion__header--active",
              ].join(" ")}
              onClick={() => {
                setCollapse(!collapse);
              }}
            >
              <div>{title}</div>
              <div className="sidebarAccordion__headerIcon">
                <img src={collapse? PlusIcon: MinusIcon} />
              </div>
            </button>
          </div>
        {/*  */}
        {/*  */}
        {/*  */}
        {/* <div
          className="sidebarAccordion__header"
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <h5 className={`${!collapse && "active"}`}>
            <Row style={{ alignItems: "center",}}>
              <Col>{title}</Col>
              <div>
                <img src={collapse ? PlusIcon : MinusIcon} />
              </div>
            </Row>
          </h5>
        </div> */}
        {/*  */}
        {/*  */}
        {/*  */}
        <div
          className={[
            "sidebarAccordion__body",
            collapse && "sidebarAccordion__body--collapse",
            submenu && "sidebarAccordion__body--submenu"
          ].join(" ")}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default SidebarAccordion;
