import { FETCH_HOMEPAGE_ALL } from "../actions";

/* Function for homepages Reducer */
const homePagesReducer = (homePage = [], action) => {
    switch (action.type) {
        case FETCH_HOMEPAGE_ALL:
            return action.payload;
        default:
            return homePage;

    }
};

export default homePagesReducer;