import { useEffect } from "react";


/* Function for close popup when click outside */
export const useCloseWhenClickOutside = (toggleState, setToggleState, refName, appButtonRef) => {
  useEffect(() => {
    const ifClickedOutside = (e) => {
      appButtonRef ?
        e.target !== appButtonRef.current && !appButtonRef.current.contains(e.target) && toggleState && refName.current && !refName.current.contains(e.target) && refName.current.children && setToggleState(false)
        :
        toggleState && refName.current && !refName.current.contains(e.target) && refName.current.children && setToggleState(false)
    }
    document.addEventListener("mousedown", ifClickedOutside)

    return () => {
      document.removeEventListener("mousedown", ifClickedOutside)
    }

  }, [toggleState])
}