import * as ACTIONS from "../actions";


export const configuratorIntImage1Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE1:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage2Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE2:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage3Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE3:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage4Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE4:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage5Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE5:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage6Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE6:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage7Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE7:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage8Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE8:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage9Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE9:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage10Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE10:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage11Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE11:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage12Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE12:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage13Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE13:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage14Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE14:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage15Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE15:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage16Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE16:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage17Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE17:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage18Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE18:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage19Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE19:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage20Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE20:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage21Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE21:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage22Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE22:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage23Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE23:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage24Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE24:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage25Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE25:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage26Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE26:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage27Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE27:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage28Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE28:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage29Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE29:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage30Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE30:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage31Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE31:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage32Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE32:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage33Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE33:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage34Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE34:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage35Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE35:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorIntImage36Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.INT_IMAGE36:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};



// <------------------------- Exterior Reducers --------------------------- >


export const configuratorExtImage1Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE1:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage2Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE2:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage3Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE3:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage4Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE4:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage5Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE5:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage6Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE6:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage7Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE7:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage8Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE8:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage9Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE9:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage10Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE10:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage11Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE11:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage12Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE12:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage13Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE13:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage14Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE14:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage15Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE15:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage16Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE16:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage17Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE17:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage18Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE18:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage19Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE19:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage20Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE20:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage21Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE21:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage22Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE22:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage23Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE23:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage24Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE24:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage25Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE25:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage26Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE26:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage27Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE27:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage28Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE28:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage29Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE29:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage30Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE30:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage31Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE31:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage32Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE32:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage33Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE33:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage34Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE34:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage35Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE35:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const configuratorExtImage36Reducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_IMAGE36:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
/*Function for static image in configurator mobile and desktop */
export const extConfiguratorStaticDesktopFileReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.CONFIG_STATIC_EXT_DESK:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const extConfiguratorStaticMobileFileReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.CONFIG_STATIC_EXT_MOB:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const intConfiguratorStaticDesktopFileReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.CONFIG_STATIC_INT_DESK:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
export const intConfiguratorStaticMobileFileReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.CONFIG_STATIC_INT_MOB:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
