import { FETCH_WIDGET_MAP_LIST } from "../actions";

/* Function for Widget Map Reducer */
const widgetMapsReducer = (widgetMap = [], action) => {
    switch (action.type) {
        case FETCH_WIDGET_MAP_LIST:
            return action.payload;
        default:
            return widgetMap;

    }
};

export default widgetMapsReducer;