import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";
import AuthGuard from "./components/Auth/AuthGuard";

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (renderRoutes(route.routes)) : (<Component {...props} />)}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);


const Data = JSON.parse(process.env.REACT_APP_ROUTES_DATA)

const routesArray=["/dashboard"]
Data.forEach(e=>{
  if(e.status == "true"){
      e.routes.forEach(el=>{
        routesArray.push(el)
      })
  }
})


const routes = [

  {
    exact: true,
    path: "/login-process",
    component: lazy(() => import("./views/auth/signin/LoginProcess")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("./views/errors/NotFound404")),
  },
  {
    path: "*",
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: "/form",
        component: lazy(() => import("./views/forms-dnd")),
      },
      {
        exact: true,
        path: "/dashboard",
        component: lazy(() => import("./views/dashboard/DashDefault")),
      },
      {
        exact: true,
        path: "/manage-homepage",
        component: lazy(() => import("./views/homepage/ManageHomepage")),
      },
      {
        exact: true,
        path: "/landing-page",
        component: lazy(() => import("./views/landing-page/index")),
      },
      {
        exact: true,
        path: "/car-models",
        component: lazy(() => import("./views/carmodels/Car-Model")),
      },
      {
        exact: true,
        path: "/car-models/add-new-model",
        component: lazy(() => import("./views/brandpage/Brand")),
      },
      {
        exact: true,
        path: "/menu-manager",
        component: lazy(() => import("./views/menuManager")),
      },
      {
        exact: true,
        path: "/manage-allPage/add-new-allPage",
        component: lazy(() => import("./views/allPages/ManageAllPage")),
      },
      {
        exact: true,
        path: "/manage-allPage",
        component: lazy(() => import("./views/allPages/allPages")),
      },
      {
        exact: true,
        path: "/widgets",
        component: lazy(() => import("./views/widgets")),
      },
      {
        exact: true,
        path: "/contactUs",
        component: lazy(() => import("./views/contactForm/ContactUs")),
      },
      {
        exact: true,
        path: "/testimonial",
        component: lazy(() => import("./views/testimonials/testimonial")),
      },
      {
        exact: true,
        path: "/dealerLocation",
        component: lazy(() => import("./views/dealerLocation")),
      },
      {
        exact: true,
        path: "/settings",
        component: lazy(() => import("./views/settings"))
      },
      {
        exact: true,
        path: "/category",
        component: lazy(() => import("./views/categories/list"))
      },
      {
        exact: true,
        path: "/blogs",
        component: lazy(() => import("./views/blogs/allPages"))
      },
      {
        exact: true,
        path: "/blogs/manage-blogs",
        component: lazy(() => import("./views/blogs/ManageAllPage"))
      },
      {
        exact: true,
        path: "/ecommerce-setting",
        component: lazy(() => import("./views/ecommerce/Index"))
      },
      {
        exact: true,
        path: "/EmiCalculator",
        component: lazy(() => import("./views/EmiCalculator/index"))
      },
      {
        path: "*",
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
      },

    ].filter(e=>{ return routesArray.indexOf(e.path)>-1 || e.path== '*' }),
  },
];


export default routes;
