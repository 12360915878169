import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { CONFIG } from "../../../config/constant";
import * as _helper from "../../../utils/helper";

import { CONSTANTS } from "../../../_helper/constant";
const HEADER_CONST = CONSTANTS.app.HEADER;

const Submenu = ({ showSubMenu, setShowSubmenu }) => {

  const user = _helper.getUser();
  const submenuRef = useRef(null);


  const handleLogout = async (e) => {
    try {
      e.preventDefault();
      const changeResponse = await _helper.saveConfirmation('logout');
      const changes = _helper.getSessionStorage('valueChange');
      const URL = CONFIG.LOGOUT_REDIRECT_URL;

      if (changes === 'true') {
        if (changeResponse && changeResponse === true) {
          _helper?.logoutHandler();
        }
      } else {
        _helper?.logoutHandler();
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="commonheader__submenu " ref={submenuRef} >
      <div className="pro-head">
        <p style={{ borderBottom: "solid 1px #ccc", margin: "0", padding: "10px 0" }}><span>{user?.["username"]}</span></p>
        <p style={{ borderBottom: "solid 1px #ccc", margin: "0", padding: "10px 0" }}><span>{user?.["userEmail"]}</span></p>
        <p style={{ borderBottom: "solid 1px #ccc", margin: "0", padding: "10px 0" }}> <span>{user?.["userPhoneNumber"]}</span></p>
        <p style={{ margin: "0", padding: "10px 0", paddingBottom: "0" }}>
          <Link style={{ color: "black", fontWeight: "bold" }} className="" title="Logout" to="/" onClick={handleLogout} >
            {HEADER_CONST.LOGOUT_TITLE}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Submenu;
