import * as qs from 'query-string';
import toast from 'react-hot-toast';
import { Badge } from 'react-bootstrap';
import { createCipheriv, createDecipheriv } from "crypto";
import { CONFIG } from "../config/constant";
// import { createNotification } from "../services/api/notifications.js";
import Swal from "sweetalert2"; 
import withReactContent from "sweetalert2-react-content";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';


export const logoutHandler = async () => {
    await axios.post(CONFIG.LOGOUT_URL, {}, { withCredentials: true });
    removeCookie('sdUserSession');
    localStorage.clear();
    window.location.href = CONFIG.LOGOUT_REDIRECT_URL;
}


export const setUser = (value) => {
    const encrypted = encrypt(value);
    localStorage.setItem('user', encrypted);
}

export const getUser = () => {
    const userData = localStorage.getItem('user');
    if (userData) {
        const decrypted = decrypt(localStorage.getItem('user'));
        return JSON.parse(decrypted);
    } else {
        return null;
    }
}


export const setCookie = (cname, cvalue, exp) => {
    let expires = "expires=" + exp;
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
export const removeCookie = (cname) => {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
export const getCookie = (cname) => {
    const value = ('; ' + document.cookie).split(`; ${cname}=`).pop().split(';')[0];
    return value;
}

export const setLocalStorage = (name, value) => {
    localStorage.setItem(name, value);
}
export const removeLocalStorage = (name) => {
    localStorage.removeItem(name);
}
export const getLocalStorage = (name) => {
    return localStorage.getItem(name);
}


export const setSessionStorage = (name, value) => {
    sessionStorage.setItem(name, value);
}
export const removeSessiontorage = (name) => {
    sessionStorage.removeItem(name);
}
export const getSessionStorage = (name) => {
    return sessionStorage.getItem(name);
}



export const getCompletePath = () => {
    const path = window.location.pathname;
    const params = new URL(window.location.href).searchParams;
    const searchParams = new URLSearchParams(params);
    searchParams.set('tab', 0);

    const newPath = path.replace('/suzuki-cms/', '');
    const location = `${newPath}?${searchParams}`;
    return location;
}


export const getQueryParams = (param) => {
    const location = window.location.search;
    const parsed = qs.parse(location);
    const response = param === 'tab' ? parseInt(parsed[param] ? parsed[param] : 0) : (parsed[param] ? `${parsed[param]}` : '');
    return response;
}


export const encrypt = (reqData) => {
    const algorithm = CONFIG?.CRYPTO?.ALGORITHM;
    const secretKey = CONFIG?.CRYPTO?.SECRET;
    const iv = Buffer.from(CONFIG?.CRYPTO?.IV, 'hex');
    const cipher = createCipheriv(algorithm, secretKey, iv);
    const encrypted = Buffer.concat([cipher.update(JSON.stringify(reqData)), cipher.final()]);
    return encrypted.toString('hex');
}


export const decrypt = (response) => {
    if (response) {
        const algorithm = CONFIG?.CRYPTO?.ALGORITHM;
        const secretKey = CONFIG?.CRYPTO?.SECRET;
        const hash = response;
        const iv = Buffer.from(CONFIG?.CRYPTO?.IV, 'hex');
        const decipher = createDecipheriv(algorithm, secretKey, Buffer.from(iv.toString('hex'), 'hex'));
        const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash, 'hex')), decipher.final()]);
        return JSON.parse(decrpyted.toString());
    }
}


export const decodeToken = async (token) => {
    const decode = await jwt_decode(token);
    return decode;
}


export const updateNotifications = async (page_id, page_type, data, reason, type,) => {
    const location = getCompletePath();
    const obj = { "page_id": `${page_id}`, "page_url": `${location}`, "page_type": page_type, page_data: data, "reason": reason, "type": Number(type), "read": false }
    const notifications = await import('../services/api/notifications')
    const response = await notifications?.createNotification(obj);
    return response;
}



const successMessage = (msg) => toast.success(msg, { style: { borderRadius: '10px', background: '#28a745', color: '#fff' } })
const errorMessage = (msg) => toast.error(msg, { style: { borderRadius: '10px', background: '#dc3545', color: '#fff' } })
const infoMessage = (msg) => toast(msg, { style: { borderRadius: '10px', background: '#17a2b8', color: '#fff' } });
const warningMessage = (msg) => toast(msg, { style: { borderRadius: '10px', background: '#ffc107', color: '#fff' } });


export const sendResponse = (type, message) => {
    if (type === 'success') {
        successMessage(message);
    }
    if (type === 'error') {
        errorMessage(message);
    }
    if (type === 'warning') {
        warningMessage(message);
    }
    if (type === 'info') {
        infoMessage(message);
    }
}


export const validateUrl = (url) => {
    let data = /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/i.test(url);
    return data;
}


export const urlValidation = (url) => {
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(url);
}

export const scriptValidator = (script) => {
    if (!script) {
        return true
    }
    // const regexp = /^<script\b[^>]*>[\s\S]*?<\/script\b[^>]*>/g ;
    // const newRegex = /^<noscript\b[^>]*>[\s\S]*?<\/noscript\b[^>]*>/g ;
    // return regexp.test(script) || newRegex.test(script)
    return true;
}
export const onlyStringNumValidator = (url) => {
    const regexp = /^([a-zA-Z0-9 _-]+)$/
    return regexp.test(url);
}


export const tagValidator = (tag) => {
    const regexp = /<(.*)>/;
    return regexp.test(tag);
}


export const hasMultipleSpaces = (str) => {
    return /\s{2,}/.test(str)
}

export const inputCheckSptialChar = (str) => {
    return str.replace(/[<>{}\/\\\[\]~]|^ /g, '')
}
export const inputCheckSptialCharCTA = (str) => {
    return str.replace(/[<>{}\\\\[\]~]|^/g, '');
}

export const emailValidator = (email) => {
    let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
}



export const successBadge = <Badge variant="success">New</Badge>
export const warningBadge = <Badge variant="warning">Modified</Badge>
export const dangerBadge = <Badge variant="danger">Deleted</Badge>



export const confirmationPopup = async (data) => {
    const MySwal = withReactContent(Swal);
    const response = await MySwal.fire({ text: data?.text, type: "warning", confirmButtonText: 'Yes', denyButtonText: 'Cancel', showDenyButton: true })
        .then(async (item) => {
            if (item.value) {
                return true;
            } else {
                return false;
            }
        });
    return response;
}


export const saveConfirmation = async (currentTab) => {
    // return true;
    const changeType = getSessionStorage('valueChange');
    if (changeType === 'true') {
        const receivedData = await confirmationPopup({ title: "Changes not saved", text: "Are you sure you want to proceed before saving the changes ?" });
        // if (receivedData) EventEmitter.emit(`${currentTab}`, { receivedData: receivedData });
        return receivedData;
    }
}


export const compareConfiguratorVariants = async (variantArray, anotherCarVariants) => {

    for (const [index, carVariant] of variantArray.entries()) {
        const anotherVariant = anotherCarVariants[index];

        if (anotherVariant) {

            let diffIntObj = {};
            for (const key in carVariant.interior) {
                if ((carVariant.interior[key] != anotherVariant.interior[key])) {
                    diffIntObj[key] = carVariant.interior[key];
                }
            }
            delete diffIntObj['updated_keys']; delete diffIntObj['status'];
            let int_updated_keys = _.keys(diffIntObj);

            if (int_updated_keys.length > 0) {
                carVariant['status'] = 1;
                carVariant.interior['status'] = 1;
                carVariant.interior['updated_keys'] = int_updated_keys;
            }

            for (const [prop, value] of Object.entries(carVariant?.colors)) {
                let diffObj = {};

                for (const key in value) {
                    if (anotherVariant?.colors[prop]) {
                        if ((value[key] != anotherVariant?.colors[prop][key])) {
                            diffObj[key] = value[key];
                        }
                    }
                }
                delete diffObj['updated_keys']; delete diffObj['status'];
                let updated_keys = _.keys(diffObj);

                if (updated_keys.length > 0) {
                    carVariant['status'] = 1;
                    value['status'] = 1;
                    value['updated_keys'] = updated_keys;
                }
            }
        }
    }

    return variantArray;
}

export const retrieveDiffObj = (db, body_data) => {
    let diffObj = {};
    for (const key in body_data) {
        if ((body_data[key] != db[key])) {
            diffObj[key] = body_data[key];
        }
    }
    return diffObj;
}


export function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}