import { CONSTANTS } from "./_helper/constant";
import dealerLocationImage from './assets/images/dealer_location_white.svg';
import testimonialImage from './assets/images/testmonialwhite.svg';
import homePagesImage from './assets/images/Homepage_white.svg';
import menuManagerImage from './assets/images/Menu_Manager_white.svg';
import modelImage from './assets/images/model_white.svg';
import allPagesImage from './assets/images/All_pages_white.svg';
import contactImage from './assets/images/contactwhite.svg';
import formSettingImage from './assets/images/Form_Settings_white.svg';
import siteSettingImage from './assets/images/site_setting_white.svg';
import emiCalculator from "./assets/images/emicalculator.svg"

const Data = JSON.parse(process.env.REACT_APP_ROUTES_DATA)
const menuItemsArray = ["dashboard"]

Data.forEach(e => {
  if (e.status == "true") {
    menuItemsArray.push(e.module)
  }
})

const menuItems = {
  items: [
    {
      id: "navigation",
      title: "",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "dashboard",
          title: `${CONSTANTS.dashBoard.LAYOUT.TITLE}`,
          type: "item",
          url: "/dashboard",
          classes: "nav-item",
          icon: "feather icon-home",
          img: "",
        },
        {
          id: "menumanager",
          title: `${CONSTANTS.menuManager.LAYOUT.TITLE}`,
          type: "item",
          url: "/menu-manager",
          classes: "nav-item",
          icon: "feather icon-grid",
          img: menuManagerImage,
        },
        {
          id: "landing-page",
          title: `${CONSTANTS.landingPage.LAYOUT.TITLE}`,
          type: "item",
          url: "/landing-page",
          classes: "nav-item",
          icon: "feather icon-file-text",
          img: homePagesImage,
        },
        {
          id: "homepage",
          title: `${CONSTANTS.homePage.LAYOUT.TITLE}`,
          type: "item",
          url: "/manage-homepage",
          classes: "nav-item",
          icon: "feather icon-file-text",
          img: homePagesImage,
        },
        {
          id: "carmodels",
          title: `${CONSTANTS.models?.LAYOUT.TITLE}`,
          type: "item",
          url: "/car-models",
          subUrl: "/add-new-model",
          classes: "nav-item",
          icon: "feather icon-box",
          img: modelImage,
        },
        // {
        //   id: "cmspages",
        //   title: "Content Pages",
        //   type: "item",
        //   url: "/manage-contentpage",
        //   subUrl:"/add-new-cmsPage",
        //   classes: "nav-item",
        //   icon: "feather icon-sidebar",
        // },
        {
          id: "allpages",
          title: `${CONSTANTS?.allPage?.LAYOUT?.TITLE}`,
          type: "item",
          url: "/manage-allPage",
          subUrl: "/add-new-allPage",
          classes: "nav-item",
          icon: "feather icon-sidebar",
          img: allPagesImage,
        },
        {
          id: "settings",
          title: `${CONSTANTS.siteSettings.LAYOUT.TITLE}`,
          type: "item",
          url: "/settings",
          classes: "nav-item",
          icon: "feather icon-settings",
          img: siteSettingImage,
        },
        {
          id: "forms",
          title: `${CONSTANTS.formSettings.LAYOUT.TITLE}`,
          type: "item",
          url: "/form",
          classes: "nav-item",
          icon: "feather icon-settings",
          img: formSettingImage,
        },
        {
          id: "widgets",
          title: `${CONSTANTS.widget.LAYOUT.TITLE}`,
          type: "item",
          url: "/widgets",
          classes: "nav-item",
          icon: "feather icon-layers",
          img: "",
        },
        {
          id: "contactUs",
          title: `${CONSTANTS.contactUs.LAYOUT.TITLE}`,
          type: "item",
          url: "/contactUs",
          classes: "nav-item",
          icon: "feather icon-layers",
          img: contactImage,
        },
        {
          id: "blogs",
          title: `${CONSTANTS.blog.LAYOUT.TITLE}`,
          type: "collapse",
          icon: "",
          children: [
            {
              id: "blog",
              title: `${CONSTANTS.blog.LAYOUT.BLOG_TITLE}`,
              type: "item",
              url: "/blogs",
              classes: "nav-item",
              icon: "feather icon-layers",
              img: '',
            },
            {
              id: "category",
              title: `${CONSTANTS.blog.LAYOUT.CATEGORY_TITLE}`,
              type: "item",
              url: "/category",
              classes: "nav-item",
              icon: "feather icon-layers",
              img: '',
            },
          ],
        },
        {
          id: "dealerLocation",
          title: `${CONSTANTS.dealerLocation.LAYOUT.TITLE}`,
          type: "item",
          url: "/dealerLocation",
          classes: "nav-item",
          icon: "feather icon-layers",
          img: dealerLocationImage,
        },

        {
          id: "testimonial",
          title: `${CONSTANTS.testimonial.LAYOUT.TITLE}`,
          type: "item",
          url: "/testimonial",
          classes: "nav-item",
          icon: "feather icon-layers",
          img: testimonialImage,
        },
        {
          id: "ecommerce",
          title: `${CONSTANTS.ecommerce.LAYOUT.TITLE}`,
          type: "item",
          url: "/ecommerce-setting",
          classes: "nav-item",
          icon: "feather icon-box",
          img: modelImage,
        },
        {
          id: "emicalculator",
          title: `${CONSTANTS.EMI_Calculator.LAYOUT.TITLE}`,
          type: "item",
          url: "/EmiCalculator",
          classes: "nav-item",
          icon: "feather icon-box",
          img: emiCalculator,
        },
      ].filter(e => { return menuItemsArray.indexOf(e.id) > -1 }),
    },
  ],
};

export default menuItems;
