import { useEffect } from "react";

const useOutsideClick = (ref, callback) => {

  /* Function for check position and callback */
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

  /* Function for check position of click */
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

export default useOutsideClick;
