export const GET_USER = 'api/v1/get-user';
export const PUBLISHER = 'api/v1/publisher';
export const CONTENT_PUBLISHER = 'api/v1/content_publisher';

export const MODEL_URL = "api/v1/car_models";
export const IMAGES = "api/v1/car-images";
export const VARIANT_URL = "api/v1/car-variants";
export const COLOR_URL = "api/v1/car-colors-price";
export const FEATURES = "api/v1/car-features";
export const FEATURES_URL = "api/v1/master-features";
export const SLIDERS_URL = "api/v1/car-sliders";
export const DEGREE_VIEW = "api/v1/car-360-views";
export const CONFIGURATIONS = "api/v1/car-configurations";
export const PERFORMANCE = "api/v1/car-performances";
export const COMFORT_EQUIPMENT = "api/v1/car-comfort-equipments";
export const KEY_FEATURES = "api/v1/car-key-features";
export const SEE_DETAILS = "api/v1/car-see-details";
export const MENU_LIST = "api/v1/car-menu-list";
export const VIDEO_LIST = "api/v1/car-video";
export const BRAND_LIST = "api/v1/car-brandproduct";
export const TOPIC_LIST = "api/v1/car-topic";
export const MODEL_TOPIC = "api/v1/cms-pages-editor";
export const UPLOAD_VIDEO = "api/v1/car-upload-videos";
export const SEO_SETTINGS = "api/v1/car-seo-settings";
export const SYNC_MASTER = "api/v1/car_models/update/master";
export const CONSUMER = "api/v1/consumer/rollback_model";

export const FILE_UPLOAD = "api/v1/file-upload";
export const FILE_UPLOAD_PRIVACY_POLICY = "api/v1/file-upload-privacy";
export const FILE_UPLOAD_VIDEO = "api/v1/file-upload-allvideos";
export const FILE_UPLOAD_PAGE_VIDEO = "api/v1/file-upload-videos";

// export const FILE_UPLOAD = 'api/v1/file-upload';
// export const FILE_UPLOAD_VIDEO = 'api/v1/file-upload-allvideos';
// export const FILE_UPLOAD_PAGE_VIDEO = 'api/v1/file-upload-videos';

export const FILE_UPLOAD1 = 'api/v1/file-upload';

export const BANNERS_URL = 'api/v1/homepage-banner';
export const LANDING_PAGE = 'api/v1/landing-page';
export const LANDING_PAGE_DEFAULT = 'api/v1/landing-page/make-default';
export const BANNER_ACTIVATE = 'api/v1/landing-page/activate';
export const CARS_URL = 'api/v1/homepage-cars';
export const PLACEHOLDER = 'api/v1/homepage-placeholder';
export const CAR_LIST_URL = 'api/v1/homepage-cars';
export const HOME_PAGE_APPROVE_URL = 'api/v1/content_publisher/approve/homepage';
export const LANDING_PAGE_APPROVE_URL = 'api/v1/content_publisher/approve/landing-page';
export const PAGE_LIST_URL = 'api/v1/homepage-cms-pages';

export const PRIMARY_DETAILS_URL = 'api/v1/cms-pages-editor';
export const CMS_PAGE_APPROVE_URL = 'api/v1/cms-pages-publisher';
export const PAGE_SETTING_URL = 'api/v1/page-settings';
export const SEO_SETTINGS_URL = 'api/v1/seo-settings';
export const FEATURE_IMAGES = 'api/v1/page-settings/images';

export const MENU = "api/v1/menu";
export const SETTINGS = "api/v1/settings";

export const FORM_FIELDS = 'api/v1/form_fields';
export const FORMS = 'api/v1/forms';
export const SENT_TO_APPROVE_FORM = 'api/v1/forms/approve';


export const ALL_PAGES_PRIMARY_DETAILS_URL = 'api/v1/cms-pages-editor';
export const ALL_PAGES_APPROVE_URL = 'api/v1/content_publisher/approve/all-pages';
export const ALL_PAGES_PAGE_SETTING_URL = 'api/v1/page-settings';
export const ALL_PAGES_SEO_SETTINGS_URL = 'api/v1/seo-settings';
export const ALL_PAGES_FEATURE_IMAGES = 'api/v1/page-settings/images';
export const ALL_PAGES_HIGHLIGHTS = 'api/v1/highlights';
// export const ALL_PAGES_ONESLIDE = 'api/v1/highlights';

export const ALL_PAGES_FAQS = 'api/v1/faqs';
export const ALL_PAGES_FEATURE_VIDEOS = 'api/v1/page-settings/videos';
export const ALL_PAGES_GALLERY_CATEGORIES = 'api/v1/gallery-categories';
export const ALL_PAGES_UPLOADS = 'api/v1/cms-pages-editor/uploads';
export const ALL_PAGES_FORMS = 'api/v1/cms-pages-editor/forms/';
export const HOME_PAGES_SEO_SETTINGS_URL = 'api/v1/homepage-seo-settings';
export const HOME_PAGE_SEO_DETAILS = 'api/v1/menu/submenu';

export const WIDGETS = "api/v1/widgets";
export const WIDGETSMAP = "api/v1/widgetsmap";
export const WIDGETS_APPROVE = "api/v1/widget-publisher";
export const HOMEPAGE_VIDEO_URL = 'api/v1/homepage-videos';

export const COMMON_URL = "api/v1/common-url";

export const RANDOM_STRING_GENERATE_ALL_PAGES = "api/v1/content/preview/allpages";
export const RANDOM_STRING_GENERATE_HOME_PAGES = "api/v1/content/preview/homepage";
export const RANDOM_STRING_GENERATE_LANDING_PAGES = "api/v1/content/preview/landingpage";
export const RANDOM_STRING_GENERATE_BRAND_PAGES = "api/v1/product/preview/brand-page";

export const DEALER_LOCATION_SAVE_AND_CONTINUE = "api/v1/dealer-location";
export const DEALER_LOCATION_SEO_SETTINGS_SAVE_AND_CONTINUE = "api/v1/dealerseo-location";
export const DEALER_LOCATION_FETCH = "api/v1/dealer-location";
export const DEALER_LOCATION_APPROVE = "api/v1/dealer-location/approve"



export const CONTACT_URL = "api/v1/contact-us"
export const SEND_TO_APPROVE = "api/v1/contact-us/approve/"
export const CONTACT_US_PUBLISHER = "api/v1/content_publisher/approve/contact-us"
export const DEALER_LOCATION_PUBLISHER = "api/v1/content_publisher/approve/dealer-location"


export const TESTIMONIAL = "api/v1/testimonial";
export const TESTIMONIAL_SEND_APPROVAL = "api/v1/testimonial/approve/";
export const TESTIMONIAL_APPROVE = "api/v1/content_publisher/approve/testimonial/";

export const BLOGS_URL = "api/v1/manage-blog/blogs"
export const CATEGORY_URL = "api/v1/manage-blog/category"

export const THEME_SETTING = "api/v1/settings/theme_setting";
export const THEME_SEND_APPROVAL = "api/v1/theme_setting/approve/";
export const THEME_APPROVE = "api/v1/content_publisher/approve/theme_setting/";
export const APPLIED_THEME = "api/v1/settings/apply_theme";

export const NOTIFICATION_URL = "api/v1/notifications";

export const CONFIGURATOR_URL = "api/v1/car-configurators";

export const ECOMMERCE = "api/v1/ecommerce-setting";
export const ECOMMERCE_SEND_APPROVAL = "api/v1/ecommerce-setting/approve/";
export const ECOMMERCE_APPROVE = "api/v1/content_publisher/approve/eco-setting/"; 
export const EMI_CALCULATOR = "api/v1/emi-calculator";
export const EMI_CALCULATOR_SEND_APPROVAL = "api/v1/emi-calculator/approve/";
export const EMI_CALCULATOR_APPROVE = "api/v1/content_publisher/approve/emi-calculator/";

export const ALL_PAGES_ONESLIDE_UPDATE = 'api/v1/cms-pages-editor/slides';

export const ALL_PAGES_ONESLIDE = 'api/v1/cms-pages-editor/slides/';
export const ALL_PAGES_DELETESLIDE = 'api/v1/cms-pages-editor/slides/';

export const ALL_PAGES_CREATE_TESTIMONIAL = "api/v1/page_testimonial/";

export const ALL_PAGES_MULTITAB_SAVE = "api/v1/cms-pages-editor/tabs/batch";
export const ALL_PAGES_SUZUKI_HYBRID_SAVE =
  "api/v1/cms-pages-editor/suzukihybrid";
export const ALL_PAGES_MULTITAB_THREE_SAVE =
  "api/v1/cms-pages-editor/multitabthree";
export const ALL_PAGES_MULTITAB_FOUR_SAVE =
  "api/v1/cms-pages-editor/multitabfour";
export const ALL_PAGES_MENU_SAVE = "api/v1/cms-pages-editor/allpage-menu-list";

