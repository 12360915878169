import { FETCH_WIDGET_LIST } from "../actions";

/* Function for Content Widgets Reducer */
const contentWidgetsReducer = (widget = [], action) => {
    switch (action.type) {
        case FETCH_WIDGET_LIST:
            return action.payload;
        default:
            return widget;

    }
};

export default contentWidgetsReducer;
 