import { useEffect, useRef, useState } from "react";
import Polygon from "../../assets/images/Polygon.svg";
import CMS from "../../assets/images/apps-icons/icon.svg";
import LQS from "../../assets/images/apps-icons/LQS.svg";
import LoremIpsum from "../../assets/images/apps-icons/LoremIpsum.svg";
import DolorSit from "../../assets/images/apps-icons/DolorSit.svg";
import Amet from "../../assets/images/apps-icons/Amet.svg";
import VelitInterdum from "../../assets/images/apps-icons/VelitInterdum.svg";
import Support from "../../assets/images/apps-icons/Support.svg";
import ICON from "../../assets/images/apps-icons/icon.svg";
import { Col } from "react-bootstrap";
import { useCloseWhenClickOutside } from "../commonleftmenu/useCloseWhenClickOutside";
import { CONFIG } from "../../config/constant";

import { CONSTANTS } from "../../_helper/constant";
import { data } from "jquery";
import { getCookie } from "../../utils/helper";
import * as _helper from "../../utils/helper";

const APP_SELECTOR_CONST = CONSTANTS.commonAppSelector;

const CommonAppSelector = ({ headerHeight }) => {
  const [collapse, setCollapse] = useState(false);
  const [activeApp, setActiveApp] = useState(APP_SELECTOR_CONST.CMS);
  const appSelectorRef = useRef(null);
  const [appsdata, setappsdata] = useState([]);

  useCloseWhenClickOutside(collapse, setCollapse, appSelectorRef);

  const api = async (method, url) => {
    try {
      const response = await fetch(url, { method, credentials: "include" });
      const responseData = await response?.json();

      if (responseData) {
        return responseData;
      } else {
        _helper.sendResponse("error", responseData);
        return Promise.reject(responseData || "Something went wrong");
      }
    } catch (error) {
      throw error;
    }
  };

  const [apps, setApps] = useState([]);
  const getDefaultApp = async () => {
    if (CONFIG?.IS_DEV_ENV) {
      // push CMS
      const allApps = [];
      allApps.push({
        img: ICON,
        label: "CMS",
        target: "#",
      });
      setApps(allApps);
    } else {
      const getuserapps = async () => {
        const response = await api(
          "GET",
          `${CONFIG?.COMMON_MASTERS_USERAPPS_URL}`,
        );
        return response;
      };
      const defaultAppRes = await getuserapps();
      const allApps = [];
      let target = "#";
      defaultAppRes?.data?.apps.map((appData) => {
        if (appData.app === "LQS") {
          target = process.env.REACT_APP_LQS_URL;
        }
        if (appData.app === "DMS") {
          target = process.env.REACT_APP_DMS_URL;
        }
        if (appData.app === "Common Masters") {
          target = process.env.REACT_APP_CM_URL;
        }

        allApps.push({
          img: ICON,
          label: appData.app,
          target,
        });
        target = "#";
      });
      setApps(allApps);
    }
  };

  useEffect(() => {
    getDefaultApp();
  }, []);

  /* Function for create button for select app */
  const AppSelectorButton = ({ title, imgsrc, target }) => {
    return (
      <div key={title} className="commonAppSelector__optionsOuter">
        <a href={target}>
          <button
            className={[
              "commonAppSelector__optionsButton text-white",
              activeApp === title &&
                "commonAppSelector__optionsButton--active  text-white",
            ].join(" ")}
            onClick={() => {
              setActiveApp(title);
            }}
          >
            <div key={title} style={{ display: "inline" }}>
              <img src={imgsrc} alt={title} height="26px" width="26px" />
            </div>
            <Col>{title}</Col>
          </button>
        </a>
      </div>
    );
  };

  return (
    <>
      <div
        className="commonAppSelector"
        style={{
          width: !collapse ? "28px" : "240px",
          maxHeight: `calc( 100vh - ${headerHeight} )`,
        }}
        ref={appSelectorRef}
      >
        {/* App selector button */}
        <div
          className={`commonAppSelector__AppButtonText ${
            collapse ? "AppButtonTextHidden" : "AppButtonTextVisible"
          }`}
        >
          <button
            onClick={() => {
              setCollapse(!collapse);
            }}
          >
            {APP_SELECTOR_CONST.APPS}
          </button>
        </div>
        <div
          className={[
            "commonAppSelector__AppButton",
            !collapse && "commonAppSelector__AppButton--collapse",
          ].join(" ")}
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <div className="commonAppSelector__AppButtonIcon">
            {<img src={Polygon} />}
          </div>
        </div>
        {/* App selector options */}
        <div className="commonAppSelector__options">
          <p>{APP_SELECTOR_CONST.APPLICATIONS}</p>
          <div>
            {apps &&
              apps.map((v, i) => {
                return (
                  <div style={{ padding: "4px 0px" }}>
                    <AppSelectorButton
                      title={v.label}
                      imgsrc={v.img}
                      target={v.target}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonAppSelector;
