import api from '../../utils/contentApi';
import * as URL from './index';


/* Function for create notifications api call */
export const createNotification = async (data) => {
    try {
        const response = await api('POST', `${URL?.NOTIFICATION_URL}`, data);
        return response;
    } catch (error) {
        return error;
    }
}

/* Function for get notifications api call */
export const fetchAllNotifications = async () => {
    try {
        const response = await api('GET', `${URL?.NOTIFICATION_URL}`);
        return response;
    } catch (error) {
        return error;
    }
}

/* Function for get notifications by id api call */
export const fetchNotification = async (notification_id) => {
    try {
        const response = await api('GET', `${URL?.NOTIFICATION_URL}/${notification_id}`);
        return response;
    } catch (error) {
        return error;
    }
}

/* Function for update notifications api call */
export const updateNotification = async (notification_id) => {
    try {
        const response = await api('PATCH', `${URL?.NOTIFICATION_URL}/${notification_id}`, '');
        return response;
    } catch (error) {
        return error;
    }
}


/* Function for update seen at notifications api call */
export const updateNotificationSeenAt = async (type) => {
    try {
        const response = await api('PATCH', `${URL?.NOTIFICATION_URL}`, { type: type });
        return response;
    } catch (error) {
        return error;
    }
}
