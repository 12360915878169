import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { updateNotification } from "../../../services/api/notifications";
import { useCloseWhenClickOutside } from "../../../views/commonleftmenu/useCloseWhenClickOutside";

import { CONSTANTS } from "../../../_helper/constant";
const NOTIF = CONSTANTS.notifications;

const NotificationSubmenuList = ({ title, discription, date, item, setShowNotificationPopup }) => {

  const [showFullDiscription, setShowFullDiscription] = useState(false)
  const [showFullDiscriptionButton, setShowFullDiscriptionButton] = useState(false)
  const submenuListRef = useRef(null)
  const submenuDescRef = useRef(null)

  useEffect(() => {
    let scrollHeight = submenuDescRef.current?.scrollHeight;
    let clientHeight = submenuDescRef.current?.clientHeight
    if (scrollHeight > clientHeight) {
      setShowFullDiscriptionButton(true)
    }
  }, []);

  useCloseWhenClickOutside(showFullDiscription, setShowFullDiscription, submenuListRef);

  const readNotification = async (item) => {
    const response = await updateNotification(item?._id);
    setShowNotificationPopup(false);
  }

  return (
    <>
      <li className="notificationSubmenuList" style={{ background: item.read == true ? '#D9D9D960' : '' }} ref={submenuListRef}>
        <Link to={`/${item?.page_url}`} onClick={() => readNotification(item)}>
          <button>
            <Row className="m-0" style={{ alignItems: "center" }}>
              <Col xs={10}>
                <div>
                  <h5 style={{ color: item.read === true ? "#888" : "#001151", fontWeight: item.read === true ? "400" : "900", marginBottom: "0" }}>  {(item?.page_type).toUpperCase()}</h5>
                  {/* <p style={{ color: item.read === true ? "#888" : "#001151", fontWeight: item.read === true ? "400" : "900", marginBottom: "0" }}>{(item?.page_data?.title)}</p> */}
                  <p style={{ color: item.read === true ? "" : "#001151" }} className={["notificationSubmenuList__discription ", !showFullDiscription && "notificationSubmenuList__discription--lineclamp blue-"].join(" ")}
                    ref={submenuDescRef}
                  >
                    {item.read != true &&
                      <span className="blue-dot2"></span>} {discription}
                  </p>
                  {showFullDiscriptionButton && <button
                    type="button"
                    className="notificationSubmenuList__discriptionButton"
                    onClick={() => {
                      setShowFullDiscription(!showFullDiscription)
                    }}
                  >
                    {showFullDiscription ? "View Less" : "View More"}
                  </button>}
                </div>
              </Col>
              <div style={{ color: item.read === true ? "" : "" }} className="notificationSubmenuList__date container">{date}
                <span style={{ color: title == 'Rejected' ? "#ff0000" : "#008000" }}>
                  {title}
                </span>
              </div>
            </Row>
          </button>

        </Link>
      </li>
    </>
  );
};

const NotificationSubmenu = ({ notificationData, setShowNotificationPopup }) => {

  const submenuRef = useRef(null);

  return (
    <>
      <div className="commonheader__submenu commonheader__submenu--notification notification-height card " ref={submenuRef} >
        <div className="pro-head pro-head--notification ">
          <ul >
            {notificationData.map((item, i) => {
              let message = '';
              if (item.type === 0) {
                message = `${(item.page_type).toUpperCase()} ${NOTIF.SENT_FOR_APPROVAL_DESC}`
                if (((item?.page_type).toLowerCase() === 'models') || ((item?.page_type).toLowerCase() === 'all pages') || ((item?.page_type).toLowerCase() === 'blog')) {
                  message = `${(item?.page_data?.title).toUpperCase()} ${NOTIF.SENT_FOR_APPROVAL_DESC}`
                }
              }
              else if (item.type === 1) {
                message = `${(item.page_type).toUpperCase()} ${NOTIF.APPROVED_DESC}`
                if (((item?.page_type).toLowerCase() === 'models') || ((item?.page_type).toLowerCase() === 'all pages') || ((item?.page_type).toLowerCase() === 'blog')) {
                  message = `${(item?.page_data?.title).toUpperCase()} ${NOTIF.APPROVED_DESC}`
                }
              }
              else {
                message = item.reason
              }

              return (
                <React.Fragment key={i}  >
                  <NotificationSubmenuList
                    //0-sentforapproval,1-approved,2-rejected
                    title={item.type === 0 ? NOTIF.SENT_FOR_APPROVAL_TITLE : item.type === 1 ? NOTIF.APPROVED_TITLE : NOTIF.REJECTED_TITLE}
                    discription={message}
                    date={(new Date(item.updatedAt)).toLocaleString()}
                    item={item}
                    setShowNotificationPopup={setShowNotificationPopup}
                  />
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default NotificationSubmenu;

