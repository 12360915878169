import { FETCH_ALL_PAGES } from "../actions";

/* Function for Pages Reducer */
const allPagesReducer = (allPages = [], action) => {
    switch (action.type) {
        case FETCH_ALL_PAGES:
            return action.payload;
        default:
            return allPages;

    }
};

export default allPagesReducer;