export const CONSTANTS = {
  commonAppSelector: {
    CMS: "CMS",
    LQS: "LQS",
    COMMONMASTERS: "COMMON MASTERS",
    DMS: "DMS",
    APPS: "Apps",
    APPLICATIONS: "Applications",
  },
  commonLeftMenu: {
    SWITCH_TO: "Switch To",
    LQS2: "LQS2",
    LQS: "LQS",
    CMS: "CMS",
    DEFAULT: "Default",
    SET_AS_DEFAULT: "Set as default",
    LMS2: "Lead Management System2",
    CLIENT_MS: "Client Management System",
    NO_RESULT_FOUND: "No result found",
  },
  file_upload: {
    UPLOAD_FILE: " Upload file",
    FILE_UPLOADED_SUCCESSFULLY: "File uploaded successfully",
    UPLOAD_PDF_FILE_ONLY: "Please upload pdf file only",
    UPLOAD_PDF_LESS_THAN: "Please upload pdf file less then 5MB",
    BROCHURE_UPLOADED_SUCCESSFULLY: "Brochure upload successfully",
    UPLOAD_IMAGE_FILE_ONLY: "Please upload only image files",
    SLIDER: {
      IMAGE_SIZE_VALIDATION_1:
        "Image size should be min 1000 X 380 (W X H) and ratio should be 1:2.6",
    },
    CONFIG_DESKTOP: {
      IMAGE_SIZE_VALIDATION_1:
        "Image size should be min 1288 X 956 (W X H) and ratio should be 1:1.35",
    },
    CONFIG_MOBILE: {
      IMAGE_SIZE_VALIDATION_1:
        "Image size should be min 768 X 564 (W X H) and ratio should be 1:1.36",
    },
    HOMEPAGE_BANNER: {
      IMAGE_SIZE_VALIDATION_1_A: "Image size should be min",
      IMAGE_SIZE_VALIDATION_1_B: "(W X H) and ratio should be 10:",
    },
    FEATURE_IMAGE: {
      IMAGE_SIZE_VALIDATION_1_A: "Image size should be min",
      IMAGE_SIZE_VALIDATION_1_B: "(W X H) and ratio should be 10:",
    },
    EXT_DESKTOP: {
      IMAGE_SIZE_VALIDATION_1_A: "Image size should be min 1922 X 1153",
      IMAGE_SIZE_VALIDATION_1_B: "(W X H) and ratio should be 1:1.6",
    },
    EXT_MOBILE: {
      IMAGE_SIZE_VALIDATION_1_A: "Image size should be min 768 X 920",
      IMAGE_SIZE_VALIDATION_1_B: "(W X H) and ratio should be 1:0.8",
    },
    INT_DESKTOP: {
      IMAGE_SIZE_VALIDATION_1_A: "Image size should be min 1920 X 765",
      IMAGE_SIZE_VALIDATION_1_B: "(W X H) and ratio should be 1:2.5",
    },
    INT_MOBILE: {
      IMAGE_SIZE_VALIDATION_1_A: "Image size should be min 768 X 985",
      IMAGE_SIZE_VALIDATION_1_B: "(W X H) and ratio should be 1:0.7",
    },
  },
  app: {
    LAYOUT: {
      TITLE: "APP",
      DEFAULT: "Default",
      SWITCH_TO: "Switch To",
      SET_AS_DEFAULT: "Set As Default",
      DEALERS: "Dealers",
    },
    HEADER: {
      LOGOUT_TITLE: "LOGOUT",
    },
    NAVBAR: {
      TITLE: "Suzuki CMS",
    },
  },
  layout: {
    LAYOUT_TITLE: "Layouts",
    RESET_BTN_TITLE: "Reset to Default",
  },

  landingPage: {
    NOTIFY_TITLE: "Landing Page",
    LANDING_PAGE_DEFAULT: "Select Default Page",
    DEFAULT_PAGE: {
      PAGE_TITLE: "Default Page",
      SELECT_PAGE: "Select Default Page",
      VEHICLE2: "2W-Vehicle",
      VEHICLE4: "4W-Vehicle",
      MARINE: "Marine-Vehicle",
      LANDING_PAGE: "Landing Page",
    },
    BANNERS: {
      TITLE: "Banners",
      EDIT_BANNER: "Edit Banner",
      EDIT: "Edit",
      ACTIVE: "Enable",
      INACTIVE: "Disable",
      VIEW: "View",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      UPLOAD_FILE: "Upload File",
      BANNER_TITLE: "Banner Title",
      IMG_REMARK: "Please Upload The Three Variations Of Desktop Banner",
      ONE_COL_IMG: "One Column Image",
      TWO_COL_IMG: "Two Column Image",
      THREE_COL_IMG: "Three Column Image",
      LOB_BANNER: "Mobile Banner",
      TWO_COL_IMG: "Two Column Image",
      THREE_COL_IMG: "Three Column Image",
      DEFAULT_PAGE: "Default Page",
      BANNER_CLICKABLE: "Banner Clickable",
      DEFAULT: "Select Default Page",
      CTA_TEXT: "CTA TEXT",
      CTA_LINK: "CTA LINK",
      DESCRIPTION: "Description",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      CLOSE: "Close",
      ADD_BANNER: "Add Banner",
      EDIT_BANNER: "Edit Banner",
      VIEW_BANNER: "View Banner",
      SAVE: "Save",
      NEXT: "Next",
      PREVIEW: "Preview",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      NEW: "New",
      DELETE_CONFIRM: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! banner has been deleted!",
        CANCEL_CLICK: "Your data is safe!"
      },
      VALIDATION_MESSAGE: {
        EDITOR: {
          BANNER_IMAGE_UPLOAD_DESKTOP: "This field is required",
          BANNER_IMAGE_UPLOAD_MOBILE: "This field is required",
          BANNER_TITLE: "This field is required",
          CTA_TEXT: "This field is required",
          CTA_LINK: "This field is required",
          DESCRIPTION: "This field is required"

        },
        PUBLISHER: {

        }
      },
      SUCCESSFULLY_UPDATED: "The Landing Page has been succesfully updated"
    },
    LAYOUT: {
      TITLE: "Landing Page",
      PREVIEW: "Preview",
      PREVIOUS: "Previous",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      NEXT: "Next",
      PREVIEW_NEW_TAB:"New Tab Preview",
      BTN_REJECT: "Reject"
    },
    MODAL: {
      HOMEPAGE_PREVIEW: "Landing Page preview",
      TITLE: "Title",
      SAVE: "Save",
      CLOSE: "Close",
    },
    SUCCESS: "success",
    SEND_APPROVAL: {
      SUCCESS: "success",
      MESSAGE: "Landing Page sent for approval"
    },
    APPROVE_CONFIRMATION: {
      OK: "Ok",
      CANCEL: "Cancel",
      PROMPT_MSG: "CONFIRM APPROVAL",
      MSG_BODY: "IT WILL APPROVE LANDING PAGE <br> <br> press OK to APPROVE or Cancel to review again",
      OK_CLICK: "Landing Page has been approved!",
      CANCEL_CLICK: "APPROVAL REQUEST CANCELLED"
    },
    REJECT: {
      TITLE: "Reason for Rejection",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Landing Page has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED"
    },
    APPROVED: "The Landing Page has been succesfully approved"
  },
  carModels: {
    LAYOUT: {
      SELECT_CAR_MODEL: "Select Car Model",
      EDIT: "Edit",
      CLEAR: "Clear",
      DELETE_NAME: "Delete",
      ADD_NEW_MODEL: "Add New Model",
      CAR_MODEL_AND_VARIANTS: "Car Model And Variants",
      CLOSE: "Close",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! Model has been deleted!",
        CANCEL_CLICK: "Your data is safe!",
      },
    },
  },
  dashBoard: {
    LAYOUT: {
      TITLE: "Dashboard",
      WELCOME_MESSAGE: "Welcome to Suzuki CMS",
      HELLO_USER: "Hello User! ",
    },
  },
  menuManager: {
    NOTIFY_TITLE:"Menu Manager",
    LAYOUT: {
      TITLE: "Menu Manager",
     
      PREVIEW: "Preview",
      PREV: "Previous",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      BTN_REJECT: "Reject",
      NEXT: "Next",
      ADD_NEW_MENU: "Add New Menu",
      ADD_ITEMS: "Add Items",
      CREATE_AN_ITEM: "Create An Item",
      EDIT_A_MENU: "Edit A Menu",
      MENU_NAME: "Menu Name",
      SAVE: "Save",
      EDIT_AN_ITEM: "Edit An Item",
      VIEW_AN_ITEM: "View An Item",
      ITEM_NAME: "Item Name",
      TARGET: "Target",
      SELECT_TARGET: "Select Target",
      NEW_TAB: "New Tab",
      ORDER_INDEX: "Order Index",
      SAME_TAB: "Same Tab",
      URL: "URL",
      EVENT: "Event",
      ENTER_URL: "Enter URL",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        CANCEL_CLICK: "Your data is safe!",
      },
      VALIDATION: {
        URL: "This field is required",
        VALID_URL: "Please enter a valid URL",
        SELECT_TAB: "This field is required",
        ITEM_NAME: "This field is required",
        MENU_NAME: "This field is required",
        VALID_MENU_NAME: "Please enter a valid title",
      },
    },
    APPROVE: {
      TITLE: "CONFIRM APPROVAL",
      HTML: "IT WILL APPROVE THIS SECTION <br> <br> press OK to APPROVE or Cancel to review again",
      OK_CLICK: "Menu has been approved!",
      CANCEL_CLICK: "APPROVAL REQUEST CANCELLED",
    },
    REJECT: {
      TITLE: "Reason for Rejection ",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Menus has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED",
    },
  },
  homePage: {
  NOTIFY_TITLE:"Home Page",
  CTA_LISTING: {
  TITLE: "CTA Listing",
  ADD_NEW_CTA_TYPE: "Add New CTA",
  NO_FIELD_ADDED: "You have not added any CTA. Please add atleast one CTA.",
  TARGET: "Target",
  SELECT_TARGET: "Select Target",
  NEW_TAB: "New Tab",
  SAME_TAB: "Same Tab",
  CTA: "CTA Name",
  CTA_LINK: "CTA Link",
  FORM: "Form",
  IMAGE: "Image",
  EDIT_FORM: "Edit CTA",
  FORM_DETAILS: "CTA Details",
  FORM_FIELD_TITLE: "Title",
  DETAILS: "Model Type Details",
  ADD_NEW_FORM: "Add New Model Type",
  TITLE_VALUE: "Models Type",
  FORM_TITLE: "Model Type Title",
  ADD_TITLE_HERE: "Add title here",
  DRAG_ITEM_HERE: "Drag item here ...",
  SAVE: "Save",
  CANCEL: "Cancel",
  NEXT: "Next",
  PREVIEW: "Preview",
  MODIFIED: "Modified",
  DELETED: "Deleted",
  NEW: "New",
  FROM: "From",
  SEATS: "Seats",
  VALIDATION: {
    ALL_ERADY_TAKEN: "Model type is already taken",
    FORM_TITLE: "This field is required",
    NO_CAR_MODEL: "You have not any model Please add atleast one Model from Models section"
  },
  carsImage: {
    NO_IMAGE_MSG: "You do not have any Model. Please add atleast one Model from Model Model section",
    FROM: "From"
  },
  DELETE: {
    TITLE: "Are you sure?",
    PROMPT_MSG: "Are you sure?",
    BODY_MSG: "Once deleted, you will not be able to recover this!",
    OK: "OK",
    CANCEL: "Cancel",
    CANCEL_CLICK: "Your data is safe!",
    OK_CLICK: "Poof! Data has been deleted!"
  }
},
 SLIDERS: {
  TITLE: "Sliders",
  SELECT_TYPE: "Select Type"
},
    LAYOUT: {
      TITLE: "Home Page",
      PREVIEW: "Preview",
      PREVIEW_NEW_TAB:"New Tab Preview",
      PREVIOUS: "Previous",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      NEXT: "Next",
      BTN_REJECT: "Reject",
    },
    REJECT: {
      TITLE: "Reason for Rejection",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Homepage has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED",
    },
    APPROVED: "The Homepage has been succesfully approved",
    MODAL: {
      HOMEPAGE_PREVIEW: "Home page preview",
      TITLE: "Title",
      SAVE: "Save",
      CLOSE: "Close",
      ADD_PLACEHOLDER: "Add Placeholder",
      PLACEHOLDER_VALIDATION: "Placeholder title is already taken",
      ADD_CAR: "Add Car",
      CAR_TYPE: "Car Type",
      CAR_TYPE_VALIDATION: "Car type is already taken",
    },
    SUCCESS: "success",
    SEND_APPROVAL: {
      SUCCESS: "success",
      MESSAGE: "Home page sent for approval",
    },
    APPROVE_CONFIRMATION: {
      OK: "Ok",
      CANCEL: "Cancel",
      PROMPT_MSG: "CONFIRM APPROVAL",
      MSG_BODY:
        "IT WILL APPROVE ALL THREE SECTIONS <br> <br> press OK to APPROVE or Cancel to review again",
      OK_CLICK: "Homepage has been approved!",
      CANCEL_CLICK: "APPROVAL REQUEST CANCELLED",
    },
    BANNERS: {
      TITLE: "Banners",
      EDIT_BANNER: "Edit Banner",
      DELETE:"Delete",
      ORDER_INDEX:"Order Index",
      EDIT: "Edit",
      VIEW: "View",
      UPLOAD_FILE: "Upload File",
      BANNER_TITLE: "Banner Title",
      BANNER_IMAGE: "Banner Image",
      BANNER_CLICKABLE: "Banner Clickable",
      CTA_TEXT: "CTA TEXT",
      CTA_LINK: "CTA LINK",
      DESCRIPTION: "Description",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      CLOSE: "Close",
      ADD_BANNER: "Add Banner",
      VIEW_BANNER: "View Banner",
      SAVE: "Save",
      NEXT: "Next",
      PREVIEW: "Preview",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      NEW: "New",
      DELETE_CONFIRM: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! banner has been deleted!",
        CANCEL_CLICK: "Your data is safe!",
      },
      VALIDATION_MESSAGE: {
        EDITOR: {
          BANNER_IMAGE_UPLOAD_DESKTOP: "This field is required",
          BANNER_IMAGE_UPLOAD_MOBILE: "This field is required",
          BANNER_TITLE: "This field is required",
          CTA_TEXT: "This field is required",
          CTA_LINK: "This field is required",
          DESCRIPTION: "This field is required",
        },
        PUBLISHER: {},
      },
      SUCCESSFULLY_UPDATED: "The Banner has been successfully updated",
    },
    CARS: {
	  CLOSE :"Close",
      TITLE: "Model Listing",
      DETAILS: "Model Type Details",
      ADD_NEW_FORM: "Add New Model Type",
      BASE_PRICE: "Base Price",
      TRANSACTION_PRICE: "Transaction Price",
      RABLA_PRICE: "Rabla Price",
      TITLE_VALUE: "Models Type",
      FORM_TITLE: "Model Type Title",
      ADD_TITLE_HERE: "Add title here",
      DRAG_ITEM_HERE: "Drag item here ...",
      EDIT_FORM: "Edit Model Type",
      SAVE: "Save",
      CANCEL: "Cancel",
      NEXT: "Next",
      PREVIEW: "Preview",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      NEW: "New",
      FROM: "From",
      SEATS: "Seats",
      VALIDATION: {
        ALL_ERADY_TAKEN: "Model type is already taken",
        FORM_TITLE: "This field is required",
        NO_CAR_MODEL:
          "You have not any car model Please add atleast one from Car Models section",
      },
      carsImage: {
        NO_IMAGE_MSG:
          "You do not have any Car.Please add atleast one car from Car Model section",
        FROM: "From",
      },
      DELETE: {
        TITLE: "Are you sure?",
        PROMPT_MSG: "Are you sure?",
        BODY_MSG: "Once deleted, you will not be able to recover this!",
        OK: "OK",
        CANCEL: "Cancel",
        CANCEL_CLICK: "Your data is safe!",
        OK_CLICK: "Poof! Data has been deleted!",
      },
    },
    CONTENT_PLACEHOLDER: {
      TITLE: "Content Placeholder",
      ADD_NEW_FORM: "Add New Placeholder",
      TITLE_VALUE: "Placeholder",
      EDIT_FORM: "Edit Placeholder",
      FORM_DETAILS: "Placeholder Details",
      FORM_TITLE: "Title",
      COLUMNS: "Columns",
      SAVE: "Save",
      CANCEL: "Cancel",
      NEXT: "Next",
      ADD_NEW: "Add New",
      PREVIEW: "Preview",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      NEW: "New",
      DRAG_ITEM_HERE: "Drag Item Here...",
      IS_CTA: "CTA",
      IS_IMAGE: "Image",
      TYPE: {
        TYPE: "Type",
        STATIC: "Static",
        SLIDER: "Slider",
        IMAGE: "Image",
        CTA: "CTA",
      },
      SEARCH: "Search...",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! Data has been deleted!",
        CANCEL_CLICK: "Your data is safe!",
      },
      VALIDATION: {
        ALL_ERADY_TAKEN: "Placeholder is already taken",
        FORM_TITLE: "This field is required",
        NO_PAGE_SELECTED: "No page selected.Please select atleast one in",
        NO_CMS_PAGE:
          "You have not add any Page Please add atleast one Page from All Pages section",
      },
    },
    SEO_SETTINGS: {
      TITLE: "Seo Settings",
      META_TITLE: "Meta Title",
      NEW: "new",
      MODAFIED: "modified",
      META_DESCRIPTION: "Meta Description",
      KEYWORDS: "Keywords",
      TRACKING: "Tracking",
    },
    VIDEO: {
      TITLE: "Video",
      COLUMNS: "COLUMNS",
      SAVE: "Save",
      NEXT: "Next",
      ADD_NEW: "Add New Video",
      PREVIEW: "Preview",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      NEW: "New",
      FORM: {
        TITLE: "Title",
        URL: "Youtube Url",
        DESCRIPTION: "Description",
        BUTTON_NAME: "Save",
        PLACEHOLDER_TITLE: "Enter Title",
        PLACEHOLDER_URL: "Enter Youtube Url",
        PLACEHOLDER_DESCRIPTION: "Enter Description",
      },
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! Data has been deleted!",
        CANCEL_CLICK: "Your data is safe!",
      },
      VALIDATION: {
        URL: "This field is required",
        VALID_URL: "Please enter a valid URL",
        NO_PAGE_SELECTED: "No page selected.Please select atleast one in",
        NO_CMS_PAGE:
          "You have not add any Page Please add atleast one Page from All Pages section",
      },
      PAGE_SETTINGS: {
        TITLE: "Page Settings",
        INCLUSIONS_IN_THE_MENU: "Inclusions in the Menu ",
        MODIFIED: "modified",
        INCLUSIONS_IN_THE_SITEMAP: "Inclusions in the Sitemap",
        IS_CRAWABLE: "Is Crawlable",
        INCLUSIONS_IN_THE_SITESEARCH: "Inclusions in the Site search",
        URL_REWRITE: "URL Rewrite",
        PAGE_STATUS: "Page Status",
        ACTIVE: "Active",
        INACTIVE: "Inactive",
      },
      HTML_CONTENT: {
        TITLE: "HTML Content",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
      },
      AllCOMPONENTS: {
        TITLE: "All Compoenets",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The All Compoenets's has been successfully updated",
      },
      ONESLIDE: {
        TITLE: "One Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The oneslide has been successfully updated",
      },
      TWOSLIDE: {
        TITLE: "Two Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The  Two slide has been successfully updated",
      },
      THREESLIDE: {
        TITLE: "Three Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Three Slide has been successfully updated",
      },
      FOURSLIDE: {
        TITLE: "Four Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The four slide has been successfully updated",
      },
      LEFTSIDE: {
        TITLE: "Left Side",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Left Side has been successfully updated",
      },
      RIGHTSIDE: {
        TITLE: "Right Side",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Right Side has been successfully updated",
        TITLEINDEX: "Title",
        DESCRIPTION: " Description",
        ORDERINDEX: "Order Index",
      },
      PERFERENCE: {
        TITLE: "Preference Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Preference has been successfully updated",
      },

      FAQS: {
        TITLE: "FAQ's",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Faq's has been successfully updated",
      },
      CATEGORY_DETAILS: {
        PENDING: "Pending",
        APPROVE: "Approve",
        APPROVED: "Approved",
        TITLE: "Category Details",
        NEW: "new",
        MODIFIED: "Modified",
        TITLE_NEW: "Title",
        NAME: "Name",
        BTN_ADD: "Add",
        TABLE: {
          NAME: "Name",
          ACTION: "Action",
        },
        VALIDATION: {
          TITLE: "This field is required",
        },
        DELETE: {
          TITLE: "Are you sure?",
          TEXT: "Once deleted, you will not be able to recover this!",
          OK_CLICK: "Poof! Data has been deleted!",
          CANCEL_CLICK: "Your category is safe!",
        },
      },
      UPLOADS: {
        PENDING: "Pending",
        APPROVE: "Approve",
        APPROVED: "Approved",
        TITLE: "Upload Documents",
        NEW: "new",
        MODIFIED: "Modified",
        TITLE_NEW: "Title",
        NAME: "Name",
        VALIDATION: {
          TITLE: "This field is required",
        },
        DELETE: {
          TITLE: "Are you sure?",
          TEXT: "Once deleted, you will not be able to recover this!",
          OK_CLICK: "Poof! Document has been deleted!",
          CANCEL_CLICK: "Your Document is safe!",
        },
        UPLOAD_BTN: "Upload",
        LIST: {
          NAME: "Name",
          ACTION: "Action",
        },
        UPLOAD_PDF_EXCEL_DOCS_FILE_ONLY:
          "Please Upload Pdf, Excel and Docs file only",
        UPLOAD_PDF_LESS_THAN: "Please upload file less then 15MB",
      },
      FORMS: {
        PENDING: "Pending",
        APPROVE: "Approve",
        APPROVED: "Approved",
        TITLE: "Forms",
        NEW: "new",
        MODIFIED: "Modified",
        TITLE_NEW: "Form",
        SELECT: "Select From here..........",
        NAME: "Name",
        VALIDATION: {
          TITLE: "This field is required",
        },
        DELETE: {
          TITLE: "Are you sure?",
          TEXT: "Once deleted, you will not be able to recover this!",
          OK_CLICK: "Poof! Document has been deleted!",
          CANCEL_CLICK: "Your Document is safe!",
        },
        UPLOAD_BTN: "Upload",
        LIST: {
          NAME: "Name",
          ACTION: "Action",
        },
        UPLOAD_PDF_EXCEL_DOCS_FILE_ONLY:
          "Please Upload Pdf, Excel and Docs file only",
        UPLOAD_PDF_LESS_THAN: "Please upload file less then 15MB",
      },
      MULTIPLE_TABS: {
        MULTI_TAB_ONE: "Multi Tab-1",
        MULTI_TAB_TWO: "Multi Tab-2",
        MULTI_TAB_THREE: "Multi Tab-3",
        MULTI_TAB_FOUR: "Multi Tabs",
        CONTACT_US: "Contact Us",
        CTA_LAYOUT: "CTA Layout",
        TAB_TITLE: "TAB TITLE",
        IMAGE: "IMAGE",
        DESKTOP: "Desktop",
        MOBILE: "Mobile",
        CTA_TEXT: "CTA TEXT",
        CTA_LINK: "CTA LINK",
        FORMS: "Forms",
        SHORT_DESCRIPTION: "Short Description",
        DESCRIPTION: "Description",
        TITLE: "TITLE",
        ADD_NEW: "ADD NEW",
        SECTION_TITLE: "Section Title",
        ADD_NEW_TAB: "ADD NEW TAB",
        VALIDATION_TITLE: "This field is required",
        ORDER_INDEX: "Order Index",
        SHORT_DESC_TITLE: "Short Description",
        MODEL_TEXT: "Model Title",
        UPDATED: "The Testimonials has been successfully updated",
        MULTI_TAB_UPDATED: "The Multi Tabs has been successfully updated",
      },
    },
    HIGHLIGHT: {
      HIGHLIGHT: "Highlight",
      HIGHLIGHTS: "Highlights",
      ADD_HIGHLIGHT: "Add Highlight",
      EDIT_HIGHLIGHT: "Edit Highlight",
      VIEW_HIGHLIGHT: "View Highlight",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_TEXT2: "CTA Text2",
      CTA_TEXT3: "CTA Text3",
      CTA_LINK: "CTA Link",
      CTA_LINK2: "CTA Link2",
      CTA_LINK3: "CTA Link3",
      FORM: "Form",
      FORM2: "Form2",
      FORM3: "Form3",
      DESC: "Description",
      MOBILE: "Mobile",
      DESKTOP: "Desktop",
      CATEGORY: "Category",
      YOUTUBE_TITLE: "Enter Youtube Url",
      VALID_URL: "Please enter a valid URL",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      SMALL_IMAGE: "Small Image (768wX564h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      LARGE_IMAGE: "Large Image",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
    },
    ONESLIDE: {
      ONESLIDE: "One Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      ICON: "Icon",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      ICON_WITH_SIZE: "Icon (35wX40h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
      VALIDATION_TITLE: "This field is required",
      VALIDATION_PHONE: "This field is not a Phone Number",
      CTA_DIAL1: "Cta Dial 1",
      CTA_DIAL2: "Cta Dial 2",
      CTA_ORDER_INDEX: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
      ],
    },
    TWOSLIDE: {
      ONESLIDE: "Two Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    THREESLIDE: {
      ONESLIDE: "Three Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM_id: "Form ID",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    CONTACTUS: {
      ONESLIDE: "Contact us",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM_id: "Form ID",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    CTALAYOUT: {
      ONESLIDE: "CTA Layout",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM_id: "Form ID",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    FOURSLIDE: {
      ONESLIDE: "Four Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM_id: "Form ID",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    LEFTSLIDE: {
      ONESLIDE: "Left Slide",
      ONESLIDES: "Left Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    RIGHTSLIDE: {
      ONESLIDE: "Right Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    PREFSLIDE: {
      ONESLIDE: "Preference Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      DESCRIPTION: "Description",
      ORDERINDEX: "Order Index",
    },
    FAQS: {
      TITLE: "FAQS",
      FAQ: "FAQ",
      ADD: "Add FAQ",
      EDIT: "Edit FAQ",
      QUESTION: "Question",
      ANSWER: "Answer",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! FAQ has been deleted!",
        CANCEL_CLICK: "Your FAQ is safe!",
      },
      VALIDATION: {
        QUES: "This field is required",
      },
      UPDATED: "The FAQ's has been successfully updated",
    },
    DRIVINGSUPPORT: {
      TITLE: "Driving Support",
    },
    SUZUKISUPPORT: {
      TITLE: "Suzuki Support",
    },
    TEMPLATE: {
      TITLE: "Template",
      BTN_ADD: "Add",
      PLACEHOLDER: "Select...",
    },
    HIGHLIGHT_UPDATED: "The Highlight has been successfully updated",
    CATEGORY_UPDATED: "The Category has been successfully updated",
    UPLOADS_UPDATED: "The Uploads Document has been successfully updated",
    FORMS_UPDATED: "The Form has been successfully updated",
    SLIDE_ORDER_INDEX: [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
      { value: 6, label: 6 },
      { value: 7, label: 7 },
      { value: 8, label: 8 },
      { value: 9, label: 9 },
      { value: 10, label: 10 },
    ],
    TEMPLATE_OPTIONS: [
      { value: "cms_pages", label: "CMS Pages" },
      { value: "image_gallery", label: "Image Gallery" },
      { value: "video_gallery", label: "Video Gallery" },
      { value: "cms_form_pages", label: "CMS form pages" },
      { value: "all_components", label: "CMS Template with All Components" },
      // { value: 'finance_options', label: 'Finance Options' },
      // { value: 'service_page', label: 'Service Page' },
      // { value: 'calculate_payment', label: 'Calculate Payment' },
      // { value: 'warranty', label: 'Warranty' },
      // { value: 'news_and_blogs', label: 'News and Blogs' },
      // { value: 'get_quote', label: 'Get a Quote' },
      // { value: 'promotions', label: 'Promotions' },
      // { value: 'cms_page_with_cta', label: 'CMS page with CTA' },
    ],
  },
  models: {
    LAYOUT: {
      TITLE: "Models",
      SEARCH: "Search...",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      PREV: "Previous",
      NEXT: "Next",
      SAVE_AND_CONTINUE: "Save And Continue",
      ROLL_BACK: "Roll Back",
      ADD_NEW_MODEL: "Add New Model",
      SEND_TO_APPROVE: "Send To Approve",
      REJECT: "Reject",
      PREVIEW: "Preview",
      PREVIEW_NEW_TAB:"New Tab Preview",
      NEW: "New",
      MODIFIED: "Modified",
      MODEL_PREVIEW: "Model Preview",
      SYNC_MASTER: "Sync Master",
    },
    SEND_FOR_APPROVAL: {
      SUCCESS_MSG: "Model sent for approval",
    },
    APPROVAL: {
      TITLE: "CONFIRM APPROVAL",
      TEXT: "IT WILL APPROVE THIS MODEL press OK to APPROVE or Cancel to review again",
      OK_CLICK: "Model has been approved!",
      CANCEL_CLICK: "APPROVAL REQUEST CANCELLED",
    },
    REJECT: {
      TITLE: "Reason for Rejection",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Model has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED",
    },
    SYNC_MASTER_APPROVAL: {
      TITLE: "CONFIRM APPROVAL",
      TEXT: "Are you sure you want to sync master api data ?",
    },
    ROLL_BACK: {
      TITLE: "CONFIRM APPROVAL",
      TEXT: "Are you sure you want to Roll Back model data ?",
    },
    BASIC_DETAILS: {
      TITLE: "Basic Details",
      NEW: "new",
      MODIFIED: "modified",
      MILEAGE: "Mileage",
      CAR_TYPE: "Car Type",
      SORT_BY: "Sort By (Variant Price)",
      SORT_BY_OPTION1: "Price - Low to High",
      SORT_BY_OPTION2: "Price - High to Low",
      BROCHURE_ONE: "Banner Element One",
      BROCHURE_TWO: "Banner Element Two",
      BROCHURE_THREE: "Banner Element Three",
      NONE: "NONE",
      SELECT_ONE: "Select",
      MILEAGE_PLACEHOLDER: "Enter Mileage",
      CAR_NAME_MODEL: "Car Model Name ",
      MODEL_NAME: "model_name",
      MODEL_CODE_COND: "model_code",
      MODEL_CODE: "Model Code",
      SELECT_MODEL_NAME: "Select model name",
      SELECT_MODEL_CODE: "Select model code",
      PAGE_URL_COND: "page_url",
      PAGE_URL: "Page URL / External URL",
      SEGMENTS: "Segment",
      SELECT_SEGMENTS: "Select Segment",
      RETAIL_PRICE: "Retail Price",
      DRPDWN_PRICE1: "price1",
      DRPDWN_PRICE2: "price2",
      DRPDWN_PRICE3: "price3",
      DRPDWN_PRICE4: "price4",
      VARIANT_CMP_SECTION: "Variant Comparsion Section",
      CAR_PAGE_BANNER: "Car Page Banner",
      PRICE1: "Price1",
      PRICE2: "Price2",
      PRICE3: "Price3",
      PRICE4: "Price4",
      PRICE1_PLACEHOLDER: "Enter price1",
      PRICE2_PLACEHOLDER: "Enter price2",
      PRICE3_PLACEHOLDER: "Enter price3",
      SHORT_DESCRIPTION: "Short Description",
      MODEL_DESCRIPTION: "Model Description",
      CAR_MODEL_BROCHURE: "Car Model Brochure (upto 10MB)",
      ENABLE_ADDITIONAL_INFORMATION: "Enable Additional Information",
      INFORMATION_DESCRIPTION: " Information Description",
      INFORMATION_TITLE: "Information Title",
      VALIDATION: {
        MODEL_DESCRIPTION: "This field is required",
        SHORT_DESCRIPTION: "This field is required",
        MILEAGE: "This field is required",
        RETAIL_PRICE: "This field is required",
        SELECT_SEGMENTS: "This field is required",
        SELECT_MODEL_NAME: "This field is required",
        SELECT_MODEL_CODE: "This field is required",
        MODEL_CODE_ALREADY_EXIST: "This model code already exists.",
        EMPTY_URL: "This field is required",
        INVALID_URL: "Please enter a valid URL",
        URL_ALREADY_EXISTS: "Page url already exists",
        NOT_ALLOWED: "Please enter a valid value",
      },
    },
    IMAGES: {
      TITLE: "Images & Video",
      TITLE_VIDEO: "Video",
      IMAGE_VIDEO: "Images & Video",
      NEW: "new",
      MODIFIED: "modified",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      SMALL_IMAGE: "Small Image",
      DELETE_NAME: "Delete",
      ALT_TEXT:"Alt Text",
      BANNER_IMAGES: "Banner Images",
      SMALL_VIDEO: "Small Video",
      BANNER_VIDEO: "Banner Video",
      BANNER_1: "Banner 1",
      SUCCESS_MESSAGE: "The video has been successfully updated",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK_IMG: "Poof! Image has been deleted!",
        OK_CLICK_VID: "Poof! Video has been deleted!",
        CANCEL_CLICK: "Your image file has been safe!",
        CANCEL_CLICK_VIDEO: "Your video file has been safe!",
      },
      VALIDATION: {
        ON_FILE_CHANGE: "Please upload only image files",
        ON_VIDEO_CHANGE: "Please upload only mp4 video",
        ON_VIDEO_UPLOAD: "Video size is greater than 10mb limit",
        IMAGE_SIZE_3:
          "Image size should be min 767 X 625 (W X H) and ratio should be 1:1.23",
        IMAGE_SIZE_1004_1928:
          "Image size should be min 1928 X 1004 (W X H) and ratio should be 1:1.92",
        IMAGE_SIZE_200_300:
          "Image size should be min 310 X 200 (W X H) and ratio should be 1:1.55",
      },
    },
    VARIANTS_AND_COLORS: {
      TITLE: "Variants & Colors",
      FEATURES: "Features",
      NEW: "new",
      MODIFIED: "modified",
      ADD_NEW_VARIANT: "Add New Variant",
      ADD_COLOR: "Add Color",

      DELETE_VARIANT: "Delete Variant",

      DELETE_NAME: "Delete",
      PRICE: "Price:",
      MAKE_DEFAULT: "Make Default",
      NO_DATA_TO_DISPLAY: "No Data To Display",
      ADD_VARIANT_DETAILS: "Add Variant Details",
      VARIANT_NAME: "Variant Name",
      SELECT_VARIANT_NAME: " Select variant name",
      VARIANT_CODE: "Variant Code",
      SELECT_VARIANT_CODE: "Select variant code",
      SELECT_ORDER_INDEX: "Select order index",
      FUEL_TYPE: "Fuel Type",
      ENGINE_CC: "Engine Cc",
      TRANSMISSION_TYPE: "Transmission Type",
      EMISSION: "Emission",
      PROPULSION: "Propulsion",

      SELECT_FUEL_TYPE: "Select fuel type",
      SELECT_ENGINE_CC: "Select engine cc",
      SELECT_TRANSMISSION_TYPE: "Select transmission type",
      SELECT_EMISSION: "Select emission",
      CLOSE: "Close",
      SAVE: "Save",
      ADD_NEW_COLOR: "Add New Color",
      ACTION: "Action",

      COLOR_NAME: "Color Name",
      SELECT_COLOR_NAME: " Select color name",
      COLOR_CODE: "Color Code ",
      SELECT_COLOR_CODE: " Select color code",
      HEX_CODE: "Hex Code",
      HEX_CODE2: "Hex Code2",
      ALT_TEXT:"Alt Text",
      ORDER_INDEX: "Order Index",
      EDIT_COLOR: "Edit Color",
      IMAGES: "Images",
      EX_SHOWROOM_PRICE: "Ex-showroom Price",
      CAR_MODEL_AND_VARIANTS: "Car Model and variants",
      PLEASE_CLICK_ADD_NEW_VARIANT_BUTTON_TO_ADD:
        "Please Click Add New variant button on to add.",
      VALIDATE: "Validate",
      UPDATED_MSG: "Variants successfully updated",
      ENABLE_ADDITIONAL_INFORMATION: "Enable Additional Information",
      INFORMATION_DESCRIPTION: " Information Description",
      INFORMATION_TITLE: "Information Title",
      VALIDATION: {
        ADD_VARIANTS: "Please add variant",
        SELECT_VARIANT_NAME: "This field is required",
        SELECT_VARIANT_CODE: "This field is required",
        SELECT_FUEL_TYPE: "This field is required",
        SELECT_ENGINE_CC: "This field is required",
        SELECT_TRANSMISSION_TYPE: "This field is required",
        SELECT_EMISSION: "This field is required",
        SELECT_PROPULSION: "This field is required",
        SELECT_COLOR_NAME: "This field is required",
        SELECT_COLOR_CODE: "This field is required",
        ENTER_ORDER_INDEX: "This field is required",
        HEX_CODE: "This field is required",
        HEX_CODE2: "This field is required",
        IMAGES: "This field is required",
        ORDER_INDEX_WARNING: "Index already exists",
      },
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! variant has been deleted!",
        CANCEL_CLICK: "Your data is safe!",
      },
      DELETE_COLOR: {
        SUCCESS: "The color has been successfully deleted.",
        VALIDATION: "Please select atleast one color to delete",
      },
    },
    FEATURES_AND_SPECS: {
      TITLE: "Features & Specifications",
      NEW: "new",
      MODIFIED: "modified",
      FILE_SUCCESSFULLY_IMPORTED: "File successfully imported.",
      IMPORT: "Import.",
      EXPORT: " Export.",
      VALIDATE: "Validate",
      FEATURES: "Features",
      FRONTEND_VISIBILITY: "Frontend Visibility",
      QUOTATION_VISIBILITY: "Quotation Visibility",
      ORDER_INDEX: "Order Index",
      ADD_NEW_KEY: "Add New Key",
      ACTION: "Actions",
      PERFORMANCE: "Performance",
      VALIDATION: {
        EXCEL_FILE: "Please upload only excel file",
      },
      DELETE_WARNING_TEXT:
        "Deleting this specification will remove it from all the models. Are you sure you want to proceed?",
    },
    SLIDERS: {
      TITLE: "Sliders",
      NEW: "new",
      MODIFIED: "modified",
      ADD_NEW_SLIDER: " Add New Slider",
      VALIDATE: "Validate",
      BTN_EDIT: "Edit",
      DELETE_NAME: "Delete",
      CTA_TEXT: "CTA Text",
      TARGET: "Target",
      SELECT_TARGET: "Select Target",
      NEW_TAB: "New Tab",
      SAME_TAB: "Same Tab",
      CTA_LINK: "CTA Link",
      NO_DATA_TO_DISPLAY: "No Data to Display",
      CLICK_TO_ADD: "Please Click on Add New Slide button to add.",
      ADD_NEW_SLIDE: "Add New Slide",
      SLIDE_TITLE: "Slide Title",
      SLIDE_IMAGE: "Slide Image",
      DESCRIPTION: "Description",
      ALT_TEXT:"Alt Text",
      CLOSE: "Close",
      SAVE: "Save",
      UPDATED_MSG: "The Sliders has been successfully updated.",
      // DESKTOP_DIMENSIONS: "Desktop (1288wX956h)",
      DESKTOP_DIMENSIONS: "Desktop (1000wX380h)",
      IMAGE_DIMENSIONS: "Mobile (650wX650h)",
      VALIDATION: {
        SLIDE_TITLE: "This field is required'",
        SLIDE_IMAGE: "This field is required",
        ADD_SLIDER: "Please add slider",
        DESCRIPTION: "This field is required",
        CTA_TEXT: "This field is required",
        VALIDE_CTA_LINK: "Please enter valid url",
      },
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! Data has been deleted!",
        CANCEL_CLICK: "Your data is safe!",
      },
    },
    VIEW_360: {
      TITLE: "360 Degree View",
      IMAGE_UPLOAD:"Image Upload",
      NEW: "new",
      MODIFIED: "modified",
      SUCCESSFULLY_UPDATED:
        "The 360 Degree View has been successfully updated.",
      VALIDATE: "Validate",
      TITLE_NAME: "Title",
      DISCLAIMER: "Disclaimer",
      DESCRIPTION: "Description",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      HTML: "HTML",
      IMAGE: "Image",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      HIGHLIGHTS: "Highlights",
      INTERIOR: "Interior",
      EXTERIOR: "Exterior",
      VALIDATION: {
        TITLE: "This field is required",
        DESCRIPTION: "This field is required",
        CTA_TEXT: "This field is required",
        CTA_LINK: "Please enter valid Link",
        HTML: "This field is required",
        HIGHLIGHTS: "This field is required",
        HTML_IMAGE:
          "Please enter either html or upload mobile and desktop images",
      },
      MARK_AS_DEFAULT:"Mark static image as default",
      CONFIGURATORS: {
        TITLE: "Configurator",
        LIST_IMAGES: "List of configurator images",
        GO_BTN: "GO",
        SELECT_VARIANT: "Select Variant",
        SELECT_COLOR: "Select Color",
        VALIDATION: {
          SELECT_VAR_COL_CODE:
            "Please select exterior configurator variant and color",
          SELECT_VARIANT: "Please select variant",
          SELECT_COLOR: "Please select color",
        },
        LISTS: {
          IMAGE1: "Image 1",
          IMAGE2: "Image 2",
          IMAGE3: "Image 3",
          IMAGE4: "Image 4",
          IMAGE5: "Image 5",
          IMAGE6: "Image 6",
          IMAGE7: "Image 7",
          IMAGE8: "Image 8",
          IMAGE9: "Image 9",
          IMAGE10: "Image 10",
          IMAGE11: "Image 11",
          IMAGE12: "Image 12",
          IMAGE13: "Image 13",
          IMAGE14: "Image 14",
          IMAGE15: "Image 15",
          IMAGE16: "Image 16",
          IMAGE17: "Image 17",
          IMAGE18: "Image 18",
          IMAGE19: "Image 19",
          IMAGE20: "Image 20",
          IMAGE21: "Image 21",
          IMAGE22: "Image 22",
          IMAGE23: "Image 23",
          IMAGE24: "Image 24",
          IMAGE25: "Image 25",
          IMAGE26: "Image 26",
          IMAGE27: "Image 27",
          IMAGE28: "Image 28",
          IMAGE29: "Image 29",
          IMAGE30: "Image 30",
          IMAGE31: "Image 31",
          IMAGE32: "Image 32",
          IMAGE33: "Image 33",
          IMAGE34: "Image 34",
          IMAGE35: "Image 35",
          IMAGE36: "Image 36",
          ANGLE1: "Angle 10%",
          ANGLE2: "Angle 20%",
          ANGLE3: "Angle 30%",
          ANGLE4: "Angle 40%",
          ANGLE5: "Angle 50%",
          ANGLE6: "Angle 60%",
          ANGLE7: "Angle 70%",
          ANGLE8: "Angle 80%",
          ANGLE9: "Angle 90%",
          ANGLE10: "Angle 100%",
          ANGLE11: "Angle 110%",
          ANGLE12: "Angle 120%",
          ANGLE13: "Angle 130%",
          ANGLE14: "Angle 140%",
          ANGLE15: "Angle 150%",
          ANGLE16: "Angle 160%",
          ANGLE17: "Angle 170%",
          ANGLE18: "Angle 180%",
          ANGLE19: "Angle 190%",
          ANGLE20: "Angle 200%",
          ANGLE21: "Angle 210%",
          ANGLE22: "Angle 220%",
          ANGLE23: "Angle 230%",
          ANGLE24: "Angle 240%",
          ANGLE25: "Angle 250%",
          ANGLE26: "Angle 260%",
          ANGLE27: "Angle 270%",
          ANGLE28: "Angle 280%",
          ANGLE29: "Angle 290%",
          ANGLE30: "Angle 300%",
          ANGLE31: "Angle 310%",
          ANGLE32: "Angle 320%",
          ANGLE33: "Angle 330%",
          ANGLE34: "Angle 340%",
          ANGLE35: "Angle 350%",
          ANGLE36: "Angle 360%",
          INT_STRETCH_IMG: "Stitch Image",
          INT_STRETCH_ANGLE: "Stitch Angle",
          INT_ANGLE1: "Angle 1",
          INT_ANGLE2: "Angle 2",
          INT_ANGLE3: "Angle 3",
          INT_ANGLE4: "Angle 4",
          INT_ANGLE5: "Angle 5",
          INT_ANGLE6: "Angle 6",
        },
        ENABLE_STATIC_VIEW: "Enable Static View",
      },
      DELETE: {
        TITLE: "DELETE VIDEO",
        TEXT: "VIDEO WILL BE DELETED.DO YOU WANT TO DELETE THE VIDEO?",
        OK_CLICK_VID: "VIDEO DELETED SUCCESSFULLY",
      },
    },
    CONFIGURE_AND_EXPLORE: {
      TITLE: "Configure & Explore",
      NEW: "new",
      MODIFIED: "modified",
      VALIDATE: "Validate",
      TITLE_NEW: "Title",
      DESCRIPTION: "Description",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      IMAGE: "Image",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      ADD_HIGHLIGHT: "Add Highlight",
      HIGHLIGHT: "Highlight",
      BTN_EDIT: "Edit",
      BTN_DELETE: "Delete",
      UPDATED_MSG: "The Configure & Explore has been successfully updated.",
      VALIDATION: {
        TITLE: "This field is required",
        DESCRIPTION: "This field is required",
        CTA_TEXT: "This field is required",
        VALID_CTA_LINK: "Please enter valid Link",
        IMAGE_DESKTOP: "This field is required",
        IMAGE_MOBILE: "This field is required",
      },
      DELETE_APPROVAL: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        CLICK_OK: "Poof! Data has been deleted!",
        CLICK_CANCEL: "Your data is safe!",
      },
    },
    PERFORMANCE: {
      TITLE: "Performance",
      NEW: "new",
      MODIFIED: "modified",
      VALIDATE: "Validate",
      TITLE_NAME: "Title",
      DESCRIPTION: "Description",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      IMAGE: "Image",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      ADD_HIGHLIGHT: "Add Highlight",
      HIGHLIGHT: "Highlight",
      BTN_EDIT: "Edit",
      BTN_DELETE: "Delete",
      UPDATED_MSG: "The Performance has been successfully updated.",
      VALIDATION: {
        TITLE: "This field is required",
        DESCRIPTION: "This field is required",
        CTA_TEXT: "This field is required",
        CTA_LINK: "This field is required",
        IMAGE_DESKTOP: "This field is required",
        IMAGE_MOBILE: "This field is required",
      },
      DELETE_APPROVAL: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        CLICK_OK: "Poof! Data has been deleted!",
        CLICK_CANCEL: "Your data is safe!",
      },
    },
    COMFORTABLE_EQUIPMENT: {
      TITLE: "Comfortable Equipment",
      NEW: "new",
      MODIFIED: "modified",
      VALIDATE: "Validate",
      TITLE_NAME: "Title",
      DESCRIPTION: "Description",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      IMAGE: "Image",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      ADD_HIGHLIGHT: "Add Highlight",
      HIGHLIGHT: "Highlight",
      BTN_EDIT: "Edit",
      BTN_DELETE: "Delete",
      UPDATED_MSG: "The Comfortable Equipment has been successfully updated.",
      VALIDATION: {
        TITLE: "This field is required",
        DESCRIPTION: "This field is required",
        CTA_TEXT: "This field is required",
        CTA_LINK: "This field is required",
        IMAGE_DESKTOP: "This field is required",
        IMAGE_MOBILE: "This field is required",
      },
      DELETE_APPROVAL: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        CLICK_OK: "Poof! Data has been deleted!",
        CLICK_CANCEL: "Your data is safe!",
      },
    },
    DETAILS: {
      TITLE: "Details",
      NEW: "new",
      MODIFIED: "modified",
      VALIDATE: "Validate",
      TITLE_NAME: "Title",
      DESCRIPTION: "Description",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      IMAGE: "Image",
      DESKTOP: "Desktop",
      MOBILE: "Mobile",
      ALT_TEXT:"Alt Text",
      VALIDATION: {
        TITLE: "This field is required",
        DESCRIPTION: "This field is required",
        CTA_TEXT: "This field is required",
        CTA_LINK: "This field is required",
        IMAGE_DESKTOP: "This field is required",
        IMAGE_MOBILE: "This field is required",
        VALID_CTA_LINK: "Please enter a valid link",
      },
    },
    MENU: {
      TITLE: "Menu",
      NEW: "new",
      MODIFIED: "modified",
      SUCCESS_MENU_UPDATE: "The Menu has been successfully updated.",
      ALL_MENU: "Menu List",
    },
    SEO_SETTINGS: {
      TITLE: "Seo Settings",
      VALIDATE: "Validate",
      NEW: "new",
      MODIFIED: "modified",
      TITLE_NAME: "Meta Title",
      DESCRIPTION: "Meta Description",
      KEYWORDS: "Keywords",
      TRACKING: "Tracking",
      SUCCESS_MENU_UPDATE: "The Seo Settings has been successfully updated.",
      VALIDATION: {
        TITLE: "This field is required",
        DESCRIPTION: "This field is required",
        KEYWORDS: "This field is required",
        TRACKING: "This field is required",
      },
    },
    VIDEO: {
      TITLE: "Video",
      SCCESS_MSG:"Video Deleted Successfully",
      COLUMNS: "COLUMNS",
      SAVE: "Save",
      NEXT: "Next",
      ADD_NEW: "Add New Video",
      EDIT: "Edit",
      VIEW: "View",
      PREVIEW: "Preview",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      NEW: "New",
      FORM: {
        TITLE: "Title",
        URL: "Youtube Url",
        DESCRIPTION: "Description",
        BUTTON_NAME: "Save",
        PLACEHOLDER_TITLE: "Enter Title",
        PLACEHOLDER_URL: "Enter Youtube Url",
        PLACEHOLDER_DESCRIPTION: "Enter Description",
      },
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! Data has been deleted!",
        CANCEL_CLICK: "Your video file has been safe!",
      },
      VALIDATION: {
        URL: "This field is required",
        VALID_URL: "Please enter a valid URL",
        NO_PAGE_SELECTED: "No page selected.Please select atleast one in",
        NO_CMS_PAGE:
          "You have not add any Page Please add atleast one Page from All Pages section",
      },
    },
    BRANDPRODUCT: {
      TITLE: "Related Product",
      RECOMMENDATION: "Recommendation",
      COLUMNS: "COLUMNS",
      SAVE: "Save",
      NEXT: "Next",
      PREVIEW: "Preview",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      NEW: "New",
      DRAG_ITEM: "Drag item here...",
      FORM: {
        TITLE: "Title",
        URL: "Youtube Url",
        DESCRIPTION: "Description",
        BUTTON_NAME: "Save",
        PLACEHOLDER_TITLE: "Enter Title",
        PLACEHOLDER_URL: "Enter Youtube Url",
        PLACEHOLDER_DESCRIPTION: "Enter Description",
      },
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! Data has been deleted!",
        CANCEL_CLICK: "Your data is safe!",
      },
      VALIDATION: {
        URL: "This field is required",
        VALID_URL: "Please enter a valid URL",
        NO_PAGE_SELECTED: "No page selected.Please select atleast one in",
        NO_CMS_PAGE:
          "You have not add any Page Please add atleast one Page from All Pages section",
      },
    },
    TOPIC: {
      TITLE: "Topics",
      TITLE_LIST: "Topics List",
      RECOMMENDATION: "Recommendation",
      COLUMNS: "COLUMNS",
      SAVE: "Save",
      NEXT: "Next",
      PREVIEW: "Preview",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      NEW: "New",
      DRAG_ITEM: "Drag item here...",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! Data has been deleted!",
        CANCEL_CLICK: "Your data is safe!",
      },
    },
    HIGHLIGHT: {
      TITLE: "Title",
      IMAGE: "Image",
      DESKTOP_DIMENSIONS: "Desktop (1288wX956h)",
      IMAGE_DIMENSIONS: "Mobile (768wX564h)",
      DESCRIPTION: "Description",
      ALT_TEXT:"Alt Text",
      CLOSE: "Close",
      SAVE: "Save",
    },
    KEY_FEATURES: {
      TITLE: "Key Features",
      FEATURE_LABEL: "Feature",
      ADD_BTN: "Add",
      INPUT_PLACEHOLDER: "Enter feature name",
      UPDATED_MSG: "The Key Features has been successfully updated.",
      DELETE_APPROVAL: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        CLICK_OK: "Poof! Data has been deleted!",
        CLICK_CANCEL: "Your data is safe!",
      },
    },
  },
  ecommerce: {
    NOTIFY_TITLE:"E-Commerce",
    LAYOUT: {
      TITLE: "E-Commerce Setting",
      SETTINGS: "Settings",
      ECOMMERCE: "E-Commerce",
      PRODUCT_PAGE: "Product Page",
      DOWNLOADABLE_CAT: "Downloadable Catalogue",
      ADD_TO_CART: "Add To Cart",
      ORDER_REQ: "Order Request",
      ORDERS_HISTORY: "Orders History",
      PRODUCT_PAYMENT: "Product Payment",
      PRODUCT_LISTING: "Product Listing",
      SEARCH_TITLE: "Search",
      FILTERS: "Filters",
      SORTING: "Sorting",
      DEFAULT_SORTING: "Default Sorting",
      A_Z_ORDER: "A-Z Sorting",
      PRICE_ORDER: "Price",
      BEST_SELLING_ORDER: "Best Selling",
      CATEGORY: "Category",
      PRODUCT_DETAIL_PAGE: "Product Detail Page",
      IMAGE: "Image",
      CATEGORY2: "Category",
      SHOW_SIMILAR_PRODUCT: "Show Similar Product",
      SEARCH: "Search...",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      PREV: "Previous",
      NEXT: "Next",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      REJECT: "Reject",
      REJECTED: "Rejected",
      PREVIEW: "Preview",
      NEW: "New",
    },
    APPROVAL: {
      TITLE: "CONFIRM APPROVAL",
      TEXT: "IT WILL APPROVE ECOMMERCE SETTING<br> <br>  press OK to APPROVE or Cancel to review again",
      OK_CLICK: "Ecommerce Setting has been approved!",
      CANCEL_CLICK: "APPROVAL REQUEST CANCELLED",
    },
    REJECT: {
      TITLE: "CONFIRM APPROVAL",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Ecommerce Setting has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED",
    },
  },
  allPage: {
    LAYOUT: {
      TITLE: "All Pages",
    },
    TABLE: {
      HEADER: {
        SNO: "S. No.",
        TITLE: "Title",
        DESC: "Description",
        TEMPLATE: "Template",
        CREATED: "Created On",
        STATUS: "Status",
      },
    },
    CONTENT_PAGE_TABLE: {
      SHOW_ENTRIES: " Show entries",
      NEW: "new",
      MODIFIED: "Modified",
      DELETED: "Deleted",
      PAGE: "Page",
      TITLE: "All Pages",
      GO_TO_PAGE: "Go to page",
      TITLE_HEADER: "Title",
      TEMPLATE: "Template",
      DESCRIPTION: "Description",
      CREATED_ON: "Created On",
      STATUS: "Status",
    },
    CMS_PAGE: {
      SELECT_CMS_PAGE: "Select CMS Page",
      ENTER_ANY_TITLE: "Enter any Title ...",
      CLEAR: "Clear",
      ADD_NEW_CMS_PAGE: "Add New Page",
      WARNING_IMAGE_MESSAGE: "Image template already exists",
      WARNING_VIDEO_MESSAGE: "Video template already exists",
    },
    CONTENT_PAGE: {
      NAVIGATIONTABS: {
        TITLE: "Multi Tabs",
        ADDNEW: "Add New Multi-Tab",
        EDIT_TAB: "Edit Multi-Tab",
        TABPLACEHOLDER: "Enter New-Tab Name*",
        ORDERINDEX: "Order Index",
        SAVE_AND_CONTINUE: "Save And Continue",
        REQUIRED: "This field is required",
		CLOSE:"Close"
      },
      LAYOUT: {
        SAVE_AND_CONTINUE: "Save And Continue",
        SEND_TO_APPROVE: "Send To Approve",
        PENDING: "Pending",
        APPROVED: "Approved",
        APPROVE: "Approve",
        BTN_REJECT: "Reject",
        SUCCESS_SEND_FOR_APPROVAL: "Content page sent for approval",
        PREVIEW: "Preview",
        PREVIEW_NEW_TAB:"New Tab Preview",
        PREV: "Previous",
        NEXT: "Next",
        NEW: "new",
        MODIFIED: "modified",
        CONTENT_PREVIEW: "Content Preview",
        APPROVAL: {
          TITLE: "CONFIRM APPROVAL",
          HTML: "IT WILL APPROVE THIS PAGE <br> <br> press OK to APPROVE or Cancel to review again",
          APPROVED: "approved",
          OK_CLICK: "Data has been approved!",
          CANCEL_CLICK:
            "APPROVAL REQUEST CANCELLED <br> Review all sections and proceed to approve",
        },
        REJECT: {
          TITLE: "Reason for Rejection",
          TEXT: "Please enter your reason",
          INPUT_VALIDATION: "Please enter reason!",
          INPUT_PLACEHOLDER: "Please enter your reason",
          OK_CLICK: "Page has been rejected!",
          CANCEL_CLICK: "REJECT REQUEST CANCELLED",
        },
      },
      CONTENT: {
        TITLE: "Highlights",
      },
      PRIMARY_DETAILS: {
        PENDING: "Pending",
        APPROVE: "Approve",
        APPROVED: "Approved",
        TITLE: "Primary Details",
        SHOW_TITLE: "Show title on Website",
        NEW: "new",
        MODIFIED: "Modified",
        TITLE_NEW: "Title",
        DESCRIPTION: "Description",
        SHORT_DESCRIPTION: "Short Description",
        LONG_DESCRIPTION: " Long Description",
        PAGE_URL: "Page URL",
        PAGE_URL_PLACEHOLDER: "Page URL",
        VALIDATION: {
          VALID_PAGE_URL: "Enter a valid page url",
          TITLE: "This field is required",
          SHORT_DESCRIPTION: "This field is required",
          LONG_DESCRIPTION: "This field is required",
          URL_REQ: "This field is required",
          URL_ALREADY_EXISTS: "Page url already exists",
          URL_CONTAIN_SPACES: "URL cannot contains spaces.",
        },
        BANNER_TITLE: "Banner Title",
      },
      FEATURE_IMAGE: {
        TITLE_MENU: "Feature Images & Video",
        TITLE: "Feature Images & Video",
        BANNER: "Banner",
        DELETE_NAME: "Delete",
        THUMBNAIL: "Thumbnail",
        DESKTOP: "Desktop",
        MOBILE: "Mobile",
        MEDIUM: "Medium",
        PLACEHOLDER: "Placeholder",
        UPLOAD_IMAGE_FILE: "Upload only image file",
        DELETE: {
          TITLE: "Are you sure?",
          TEXT: "Once deleted, you will not be able to recover this!",
          OK_CLICK: "Poof! Data has been deleted!",
          CANCEL_CLICK: "Your image file is safe!",
        },
        UPDATED: "The Feature Images & Video has been successfully updated",
      },
      FEATURE_VIDEO: {
        TITLE: "Feature Video",
        VIDEO_DELETED: "Video deleted successfully",
        VIDEO: "Video",
        DELETE_NAME: "Delete",
        THUMBNAIL: "Thumbnail",
        UPLOAD_IMAGE_FILE: "Upload only video file",
        DELETE: {
          TITLE: "Are you sure?",
          TEXT: "Once deleted, you will not be able to recover this!",
          OK_CLICK: "Poof! Video has been deleted!",
          CANCEL_CLICK: "Your video file is safe!",
        },
        VALIDATION: {
          ON_FILE_CHANGE: "Please upload only video files",
          ON_VIDEO_UPLOAD: "Max upload size is 10mb",
        },
      },
      SEO_SETTINGS: {
        TITLE: "Seo Settings",
        META_TITLE: "Meta Title",
        NEW: "new",
        MODAFIED: "modified",
        META_DESCRIPTION: "Meta Description",
        KEYWORDS: "Keywords",
        TRACKING: "Tracking",
      },
      PAGE_SETTINGS: {
        TITLE: "Page Settings",
        INCLUSIONS_IN_THE_MENU: "Inclusions in the Menu ",
        MODIFIED: "modified",
        INCLUSIONS_IN_THE_SITEMAP: "Inclusions in the Sitemap",
        IS_CRAWABLE: "Is Crawlable",
        INCLUSIONS_IN_THE_SITESEARCH: "Inclusions in the Site search",
        URL_REWRITE: "URL Rewrite",
        PAGE_STATUS: "Page Status",
        ACTIVE: "Active",
        INACTIVE: "Inactive",
        IS_INDEXABLE: "Is Indexable",
      },
      HTML_CONTENT: {
        TITLE: "HTML Content",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
      },
      AllCOMPONENTS: {
        TITLE: "All Compoenets",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The All Compoenets's has been successfully updated",
      },
      ONESLIDE: {
        TITLE: "One Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The oneslide has been successfully updated",
      },
      TWOSLIDE: {
        TITLE: "Two Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The  Two slide has been successfully updated",
      },
      THREESLIDE: {
        TITLE: "Three Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Three Slide has been successfully updated",
      },
      FOURSLIDE: {
        TITLE: "Four Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The four slide has been successfully updated",
      },
      LEFTSIDE: {
        TITLE: "Left Side",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Left Side has been successfully updated",
      },
      RIGHTSIDE: {
        TITLE: "Right Side",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Right Side has been successfully updated",
        TITLEINDEX: "Title",
        DESCRIPTION: " Description",
        ORDERINDEX: "Order Index",
      },
      PERFERENCE: {
        TITLE: "Preference Slide",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Preference has been successfully updated",
      },

      FAQS: {
        TITLE: "FAQ's",
        MODIFIED: "modified",
        HTML: "HTML",
        CONTENT: "HTML Content",
        UPDATED: "The Faq's has been successfully updated",
      },
      CATEGORY_DETAILS: {
        PENDING: "Pending",
        APPROVE: "Approve",
        APPROVED: "Approved",
        TITLE: "Category Details",
        NEW: "new",
        MODIFIED: "Modified",
        TITLE_NEW: "Title",
        NAME: "Name",
        BTN_ADD: "Add",
        TABLE: {
          NAME: "Name",
          ACTION: "Action",
        },
        VALIDATION: {
          TITLE: "This field is required",
        },
        DELETE: {
          TITLE: "Are you sure?",
          TEXT: "Once deleted, you will not be able to recover this!",
          OK_CLICK: "Poof! Data has been deleted!",
          CANCEL_CLICK: "Your category is safe!",
        },
      },
      UPLOADS: {
        PENDING: "Pending",
        APPROVE: "Approve",
        APPROVED: "Approved",
        TITLE: "Upload Documents",
        NEW: "new",
        MODIFIED: "Modified",
        TITLE_NEW: "Title",
        NAME: "Name",
        VALIDATION: {
          TITLE: "This field is required",
        },
        DELETE: {
          TITLE: "Are you sure?",
          TEXT: "Once deleted, you will not be able to recover this!",
          OK_CLICK: "Poof! Document has been deleted!",
          CANCEL_CLICK: "Your Document is safe!",
        },
        UPLOAD_BTN: "Upload",
        LIST: {
          NAME: "Name",
          ACTION: "Action",
        },
        UPLOAD_PDF_EXCEL_DOCS_FILE_ONLY:
          "Please Upload Pdf, Excel and Docs file only",
        UPLOAD_PDF_LESS_THAN: "Please upload file less then 15MB",
      },
      FORMS: {
        PENDING: "Pending",
        APPROVE: "Approve",
        APPROVED: "Approved",
        TITLE: "Forms",
        NEW: "new",
        MODIFIED: "Modified",
        TITLE_NEW: "Form",
        SELECT: "Select From here..........",
        NAME: "Name",
        VALIDATION: {
          TITLE: "This field is required",
        },
        DELETE: {
          TITLE: "Are you sure?",
          TEXT: "Once deleted, you will not be able to recover this!",
          OK_CLICK: "Poof! Document has been deleted!",
          CANCEL_CLICK: "Your Document is safe!",
        },
        UPLOAD_BTN: "Upload",
        LIST: {
          NAME: "Name",
          ACTION: "Action",
        },
        UPLOAD_PDF_EXCEL_DOCS_FILE_ONLY:
          "Please Upload Pdf, Excel and Docs file only",
        UPLOAD_PDF_LESS_THAN: "Please upload file less then 15MB",
      },
      MULTIPLE_TABS: {
        MULTI_TAB_ONE: "Multi Tab-1",
        MULTI_TAB_TWO: "Multi Tab-2",
        MULTI_TAB_THREE: "Multi Tab-3",
        MULTI_TAB_FOUR: "Multi Tabs",
        CONTACT_US: "Contact Us",
        CTA_LAYOUT: "CTA Layout",
        TESTIMONIAL: "Testimonial",
        TAB_TITLE: "TAB TITLE",
        IMAGE: "IMAGE",
        DESKTOP: "Desktop",
        MOBILE: "Mobile",
        ALT_TEXT:"Alt Text",
        CTA_TEXT: "CTA TEXT",
        CTA_LINK: "CTA LINK",
        FORMS: "Forms",
        SHORT_DESCRIPTION: "Short Description",
        DESCRIPTION: "Description",
        TITLE: "TITLE",
        ADD_NEW: "ADD NEW",
        ADD_TESTIMONIAL: "Add Testimonial",
        SECTION_TITLE: "Section Title",
        ADD_NEW_TAB: "ADD NEW TAB",
        VALIDATION_TITLE: "This field is required",
        ORDER_INDEX: "Order Index",
        SHORT_DESC_TITLE: "Short Description",
        MODEL_TEXT: "Model Title",
        UPDATED: "The Testimonials has been successfully updated",
        MULTI_TAB_UPDATED: "The Multi Tabs has been successfully updated",
      },
    },
    TESTIMONIAL: {
      TITLE: "Add Testimonial",
      USER_NAME: "User Name",
      DESIGNATION: "Designation",
      ORDERINDEX: "Order Index",
      ALT_TEXT:"Alt Text",
      TESTIMONIAL_DESCRIPTION: "Testimonial Desrciption",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      STATUS: "Status",
      ACTIVE: "Active",
      ORDERINDEX: "Order Index: ",
      EDIT: "Edit",
      DELETE: "Delete",
      VIEW: "View",
      PROFILE_IMG: "Profile Image",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      INVALID_ORDER_INDEX_ZERO : "Invalid order index: 0 not allowed.",
      DUPLICATE_ORDER_INDEX : "Duplicate Order Index not allowed"
    },
    HIGHLIGHT: {
      HIGHLIGHT: "Highlight",
      HIGHLIGHTS: "Highlights",
      ADD_HIGHLIGHT: "Add Highlight",
      EDIT_HIGHLIGHT: "Edit Highlight",
      VIEW_HIGHLIGHT: "View Highlight",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_TEXT2: "CTA Text2",
      CTA_TEXT3: "CTA Text3",
      CTA_LINK: "CTA Link",
      CTA_LINK2: "CTA Link2",
      CTA_LINK3: "CTA Link3",
      FORM: "Form",
      FORM2: "Form2",
      FORM3: "Form3",
      DESC: "Description",
      MOBILE: "Mobile",
      DESKTOP: "Desktop",
      CATEGORY: "Category",
      YOUTUBE_TITLE: "Enter Youtube Url",
      VALID_URL: "Please enter a valid URL",
      // MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      MOBILE_WITH_SIZE: "Mobile (768Wx564H)",
      SMALL_IMAGE: "Small Image (768wX564h)",
      // DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      DESKTOP_WITH_SIZE: "Desktop (768Wx564H)",
      LARGE_IMAGE: "Large Image",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
    },
    ONESLIDE: {
      ONESLIDE: "One Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      ALT_TEXT:"Alt Text",
      IMAGE: "Image",
      ICON: "Icon",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      /* MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)", */
      MOBILE_WITH_SIZE: "Mobile (768wX564h)",
      DESKTOP_WITH_SIZE: "Desktop (1928wX738h)",
      ICON_WITH_SIZE: "Icon (35wX40h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
      VALIDATION_TITLE: "This field is required",
      VALIDATION_PHONE: "This field is not a Phone Number",
      CTA_DIAL1: "Cta Dial 1",
      CTA_DIAL2: "Cta Dial 2",
      CTA_ORDER_INDEX: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
      ],
    },
    TWOSLIDE: {
      ONESLIDE: "Two Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      /* MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)", */
      MOBILE_WITH_SIZE: "Mobile (768wX564h)",
      DESKTOP_WITH_SIZE: "Desktop (850wX624h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    THREESLIDE: {
      ONESLIDE: "Three Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM_id: "Form ID",
      FORM: "Form",
      DESC: "Description",
      /* MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)", */
      MOBILE_WITH_SIZE: "Mobile (768wX426h)",
      DESKTOP_WITH_SIZE: "Desktop (544wX302h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    CONTACTUS: {
      ONESLIDE: "Contact us",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM_id: "Form ID",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    CTALAYOUT: {
      ONESLIDE: "CTA Layout",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM_id: "Form ID",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    FOURSLIDE: {
      ONESLIDE: "Four Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM_id: "Form ID",
      FORM: "Form",
      DESC: "Description",
      /*       MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)", */
      MOBILE_WITH_SIZE: "Mobile (398wX220h)",
      DESKTOP_WITH_SIZE: "Desktop (398wX220h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    LEFTSLIDE: {
      ONESLIDE: "Left Slide",
      ONESLIDES: "Left Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      /*       MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)", */
      MOBILE_WITH_SIZE: "Mobile (768wX564h)",
      DESKTOP_WITH_SIZE: "Desktop (1288wX956h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    RIGHTSLIDE: {
      ONESLIDE: "Right Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      /*       MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)", */
      MOBILE_WITH_SIZE: "Mobile (768wX564h)",
      DESKTOP_WITH_SIZE: "Desktop (1288wX956h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      ORDERINDEX: "Order Index",
    },
    PREFSLIDE: {
      ONESLIDE: "Preference Slide",
      ADD_ONESLIDE: "Add Slide",
      EDIT_ONESLIDE: "Edit Slide",
      VIEW_ONESLIDE: "View Slide",
      TITLE: "Title",
      IMAGE: "Image",
      CTA_TEXT: "CTA Text",
      CTA_LINK: "CTA Link",
      FORM: "Form",
      DESC: "Description",
      MOBILE_WITH_SIZE: "Mobile (768wX1230h)",
      DESKTOP_WITH_SIZE: "Desktop (964wX1070h)",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      TITLEINDEX: "Title",
      DESCRIPTION: "Description",
      ORDERINDEX: "Order Index",
    },
    FAQS: {
      TITLE: "FAQS",
      FAQ: "FAQ",
      ADD: "Add FAQ",
      EDIT: "Edit FAQ",
      QUESTION: "Question",
      ANSWER: "Answer",
      BTN_SAVE: "Save",
      BTN_CLOSE: "Close",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! FAQ has been deleted!",
        CANCEL_CLICK: "Your FAQ is safe!",
      },
      VALIDATION: {
        QUES: "This field is required",
      },
      UPDATED: "The FAQ's has been successfully updated",
    },
    DRIVINGSUPPORT: {
      TITLE: "Driving Support",
    },
    SUZUKISUPPORT: {
      TITLE: "Suzuki Support",
    },
    TEMPLATE: {
      TITLE: "Template",
      BTN_ADD: "Add",
      PLACEHOLDER: "Select...",
    },
    HIGHLIGHT_UPDATED: "The Highlight has been successfully updated",
    CATEGORY_UPDATED: "The Category has been successfully updated",
    UPLOADS_UPDATED: "The Uploads Document has been successfully updated",
    FORMS_UPDATED: "The Form has been successfully updated",
    SLIDE_ORDER_INDEX: [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
      { value: 6, label: 6 },
      { value: 7, label: 7 },
      { value: 8, label: 8 },
      { value: 9, label: 9 },
      { value: 10, label: 10 },
      { value: 11, label: 11 },
      { value: 12, label: 12 },
      { value: 13, label: 13 },
      { value: 14, label: 14 },
      { value: 15, label: 15 },
      { value: 16, label: 16 },
      { value: 17, label: 17 },
      { value: 18, label: 18 },
      { value: 19, label: 19 },
      { value: 20, label: 20 },
      { value: 21, label: 21 },
      { value: 22, label: 22 },
      { value: 23, label: 23 },
      { value: 24, label: 24 },
      { value: 25, label: 25 },
      { value: 26, label: 26 },
      { value: 27, label: 27 },
      { value: 28, label: 28 },
      { value: 29, label: 29 },
      { value: 30, label: 30 },
],
    TEMPLATE_OPTIONS: [
      { value: "cms_pages", label: "CMS Pages" },
      // { value: 'finance_options', label: 'Finance Options' },
      // { value: 'service_page', label: 'Service Page' },
      // { value: 'calculate_payment', label: 'Calculate Payment' },
      // { value: 'warranty', label: 'Warranty' },
      // { value: 'news_and_blogs', label: 'News and Blogs' },
      // { value: 'get_quote', label: 'Get a Quote' },
      { value: "image_gallery", label: "Image Gallery" },
      { value: "video_gallery", label: "Video Gallery" },
      // { value: 'promotions', label: 'Promotions' },
      // { value: 'cms_page_with_cta', label: 'CMS page with CTA' },
      { value: "cms_form_pages", label: "CMS form pages" },
      { value: "all_components", label: "CMS Template with All Components" },
    ],
  },
  siteSettings: {
    LAYOUT: {
      LOGO:"Logo",
      DEALER_NAME:"Dealer Name",
      NOTIFY_TITLE:"Site Settings",
      TITLE: "Site Settings",
      PREVIEW: "Preview",
      PREV: "Previous",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      PENDING: "Pending",
      APPROVE: "Approve",
      EMAIL_SEND : "Send Notifications",
      APPROVED: "Approved",
      BTN_REJECT: "Reject",
      NEXT: "Next",
      ADD_NEW_MENU: "Add New Menu",
      ADD_ITEMS: "Add Items",
      CREATE_AN_ITEM: "Create An Item",
      EDIT_A_MENU: "Edit A Menu",
      MENU_NAME: "Menu Name",
      SAVE: "Save",
      EDIT_AN_ITEM: "Edit An Item",
      ITEM_NAME: "Item Name",
      TARGET: "Target",
      SELECT_TARGET: "Select Target",
      NEW_TAB: "New Tab",
      SAME_TAB: "Same Tab",
      URL: "URL",
      EVENT: "Event",
      ENTER_URL: "Enter URL",
      NEW: "new",
      MODIFIED: "modified",
      SOCIAL_MEDIA: "Social Media",
      GOOGLE_ANALYTICS: "Analytics",
      HEAD: "Analytics (Head)",
      BODY: "Analytics (Body)",
      ENTER_GOOGLE_ANALYTIC_CURSOR_AUTO: "Enter google analytic cursor-auto",
      GOOGLE_TAG: "Google Tag",
      ENTER_GOOGLE_TAG: "Enter google tag",
      TWITTER: "Twitter",
      ENTER_TWITTER_URL: "Enter twitter url",
      FACEBOOK: "Facebook",
      TIKTOK: "TIKTOK",
      ENTER_FACEBOOK_URL: "Enter facebook url",
      INSTAGRAM: "Instagram",
      ENTER_INSTAGRAM_URL: "Enter instagram url",
      YOUTUBE: "YouTube",
      ENTER_YOUTUBE_URL: "Enter youtube url",
      LINKEDIN: "Linkedin",
      ENTER_LINKEDIN_URL: "Enter Linkedin url",
      ENTER_TIKTOK_URL: "Enter Tiktok url",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        CANCEL_CLICK: "Your data is safe!",
      },
      SUCCESS_SITE_SETTING_SENT_FOR_APPROVAL: "Site Settings sent for approval",
      SUCCESSS_SITE_SETTING_APPROVED: "Site Settings has been approved!",
      VALIDATION: {
        URL: "This field is required",
        VALID_URL: "Please enter a valid URL",
        SELECT_TAB: "This field is required",
        ITEM_NAME: "This field is required",
        MENU_NAME: "This field is required",
        VALID_MENU_NAME: "Please enter a valid title",
        VALID_SCRIPT: "Please enter valid script",
      },
      APPROVAL: {
        TITLE: "CONFIRM APPROVAL",
        HTML: "IT WILL APPROVE THIS SECTION <br> <br> press OK to APPROVE or Cancel to review again",
        OK_CLICK: "Menu has been approved!",
        CANCEL_CLICK: "APPROVAL REQUEST CANCELLED",
      },
      REJECT: {
        TITLE: "Reason for Rejection",
        TEXT: "Please enter your reason",
        INPUT_VALIDATION: "Please enter reason!",
        INPUT_PLACEHOLDER: "Please enter your reason",
        OK_CLICK: "Site settings has been rejected!",
        CANCEL_CLICK: "REJECT REQUEST CANCELLED",
      },
      SITE_VARIABLES: "Site Variables",
      CURRENCY: "Currency",
      LANGUAGE: "Language",
      LANGUAGE_CODE: "Language Code",
      COUNTRY_CODE: "Country Code",
      ENTER_CURRENCY: "Enter Currency",
      ENTER_LANGUAGE: "Enter Language",
      ENTER_LANGUAGE_CODE: "Enter Language Code",
      ENTER_COUNTRY_CODE: "Enter Country Code",
      THEME_SETTING: "Theme Setting",
      CUSTOMER_PRIVACY_POLICY: "Customer Privacy Policy",
      EMPLOYEE_PRIVACY_POLICY: "Employee Privacy Policy",
      FEATURES_SPECS: "Features & Specs",
      CMP_SETTINGS: {
        TITLE: "CMP Settings",
        CMP: "CMP",
        URL: "URL",
        ENTER_URL: "Enter CMP settings url",
        VALID_URL: "Please enter a valid URL",
      },
    },
  },
  formSettings: {
    LAYOUT: {
      TITLE: "Form Settings",
      PREVIEW: "Preview",
      PREV: "Previous",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      NEXT: "Next",
      BTN_REJECT: "Reject",
      ADD_NEW_MENU: "Add New Menu",
      ADD_ITEMS: "Add Items",
      CREATE_AN_ITEM: "Create An Item",
      EDIT_A_MENU: "Edit A Menu",
      MENU_NAME: "Menu Name",
      SAVE: "Save",
      EDIT_AN_ITEM: "Edit An Item",
      ITEM_NAME: "Item Name",
      TARGET: "Target",
      SELECT_TARGET: "Select Target",
      NEW_TAB: "New Tab",
      SAME_TAB: "Same Tab",
      URL: "URL",
      EVENT: "Event",
      ENTER_URL: "Enter URL",
      NEW: "new",
      MODIFIED: "modified",
      DISPLAY_NAME: "Display name",
      ENABLE_INFO: "Enable Info",
      INFO_TEXT: "Info Text",
      VIEW_FIELD: "View Field",
      EDIT_FIELD: "Edit Field",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        CANCEL_CLICK: "Your data is safe!",
      },
      SUCCESS_FORM_SETTING_SENT_FOR_APPROVAL: "Form setting sent for approval",
      SUCCESSS_FORM_SETTING_APPROVED: "Form Setting has been approved!",
      FORM_SETTINGS: "Form Settings",
      ADD_NEW_FORM: "Add New Form",
      EDIT_FORM: "Edit Form",
      DRAG_ITEM_HERE: "Drag Item Here",
      MANDATORY: "Mandatory",
      SELECT: "Select",
      TRUE: "True",
      FALSE: "False",
      CANCEL: "Cancel",
      FORM_DETAILS: "Form Details",
      FORM_TITLE: "Form Title",
      FORM_NAME: "Form Name",
      CTA_NAME: "CTA Name",
      REDIRECT: "Redirect",
      CAPTCHA: "Enable Captcha",
      REDIRECT_URL: "Redirect URL",
      BUSINESS_ID: "Business Type",
      LEAD_SOURCE_ID: "Lead Source",
      LEAD_MODE_ID: "Lead Generated From",
      API_KEY: "Api key",
      INPUT_TYPE: "Input Type",
      VALIDATION: {
        REDIRECT_URL: "This field is required",
        BUSINESS_ID: "This field is required",
        LEAD_SOURCE_ID: "This field is required",
        LEAD_MODE_ID: "This field is required",
        API_KEY: "This field is required",
        ALREADY_TAKEN: "This form name is already taken.",
        CTA_NAME: "This field is required",
        FORM_NAME: "This field is required",
        VALID_FORM_NAME: "form name contain only string and underscore",
        FORM_TITLE: "This field is required",
        DISPLAY_NAME: "display name is required",
        NO_FIELD_ADDED:
          "You have not added any Field. Please add atleast one Field.",
        URL: "This field is required",
        VALID_URL: "Please enter a valid URL",
        SELECT_TAB: "This field is required",
        ITEM_NAME: "This field is required",
        MENU_NAME: "This field is required",
        VALID_MENU_NAME: "Please enter a valid title",
        VALID_SCRIPT: "Please enter valid script",
      },
      APPROVAL: {
        TITLE: "CONFIRM APPROVAL",
        HTML: "IT WILL APPROVE THIS SECTION <br> <br> press OK to APPROVE or Cancel to review again",
        SENT_FOR_APPROVAL: "Form Settings sent for approval",
        OK_CLICK: "Form Settings has been approved!",
        CANCEL_CLICK: "APPROVAL REQUEST CANCELLED",
      },
      REJECT: {
        TITLE: "Reason for Rejection",
        TEXT: "Please enter your reason",
        INPUT_VALIDATION: "Please enter reason!",
        INPUT_PLACEHOLDER: "Please enter your reason",
        OK_CLICK: "Form settings has been rejected!",
        CANCEL_CLICK: "REJECT REQUEST CANCELLED",
      },
      INPUT_TYPE_ARRAY: ["text", "text area", "dropdown", "radio", "checkbox"],
    },
  },
  commonConst: {
    REQUIRED: "This field is required",
    DELETE: "Delete",
    EDIT: "Edit",
    VIEW: "View",
    APPLY: "Apply",
    DELETED: "deleted",
    SCRIPT_ADDED: "You are not allowed to enter any tag in this field",
    MY_SWAL_TITLE: "Are you sure?",
    MY_SWAL_TEXT: "Once deleted, you will not be able to recover this!",
    MY_SWAL_MSG: "Poof! data has been deleted!",
    MY_SWAL_SAVE_MSG: "Your data is safe!",
    ONLY_FOUR_TOOGLE_IS_ALLOWED:
      "Only Four Toggle can be active at any time.Please Disable another To Enable it.",
  },
  dealerMap: {
    NOTIFY_TITLE:"Dealer Location",
    TITLE: "Maps Setting",
    LONGITUDE: "Longitude",
    LATITUDE: "Latitude",
    DEFAULT_RADIUS: "Default Radius",
    DEFAULT_LANDMARK: "Default Landmark",
    SELECT: "Select",
    LONGITUDE_CANNOT_BE_EMPTY: "Longitude cannot be empty",
    LATITUDE_CANNOT_BE_EMPTY: "Latitude cannot be empty",
    LONGITUDE_VALUE_ONLY_BETWEEN:
      "Longitude value must be in between -180 to 180",
    LATITUDE_VALUE_ONLY_BETWEEN: "Latitude must be in between -90 to 90",
    BOOK_A_CALL_BACK: "Book a Call Back",
    BOOK_A_SERVICE: "Book a Service",
    BOOK_A_TEST_DRIVE: "Book a Test Drive",
    APPROVE: "Approve",
    APPROVED: "Approved",
    SAVE_AND_CONTINUE: "Save And Continue",
    PENDING: "Pending",
    SEND_TO_APPROVE: "Send To Approve",
    PREVIOUS: "Previous",
    BTN_REJECT: "Reject",
    NEXT:"Next",
    APPROVE_CONFIRMATION: {
      OK: "Ok",
      CANCEL: "Cancel",
      PROMPT_MSG: "CONFIRM APPROVAL",
      MSG_BODY:
        "IT WILL APPROVE Maps Setting <br> <br> press OK to APPROVE or Cancel to review again",
      OK_CLICK: "Maps Setting has been approved!",
      CANCEL_CLICK: "APPROVAL REQUEST CANCELLED",
    },
    REJECT: {
      TITLE: "Reason for Rejection",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Maps setting has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED",
    },
  },
  dealerSeo:{
    SEO_SETTINGS:"Seo Settings",
    META_title:"Meta Title",
    META_DESC:"Meta Description",
    KEYWORDS:"Keywords",
    ENTER_TEXT:"Enter Text"

  },
  contactUs: {
    NOTIFY_TITLE:"Contact Us",
    LAYOUT: {
      TITLE: "Contact Us",
      PHONE: "PHONE",
      ADDRESS: "ADDRESS",
      EMAIL_ADRESS: "EMAIL ADDRESS",
      PHONE1: "Phone 1",
      PHONE2: "Phone 2",
      TOLL_NO: "Toll no.",
      ADDRESS_LINE1: "Address line 1",
      ADDRESS_LINE2: "Address line 2",
      ADDRESS_LINE3: "Address line 3",
      ADDRESS_LINE4: "Address line 4",
      EMAIL: "Email",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      PENDING: "Pending",
      APPROVED: "Approved",
      APPROVE: "Approve",
      PREVIEW: "Preview",
      PREV: "Previous",
      NEXT: "Next",
      NEW: "new",
      BTN_REJECT: "Reject",
      MODIFIED: "modified",
      CONTENT_PREVIEW: "Content Preview",
      APPROVAL: {
        TITLE: "CONFIRM APPROVAL",
        HTML: "IT WILL APPROVE THIS PAGE <br> <br> press OK to APPROVE or Cancel to review again",
        OK_CLICK: "Contact Us Page has been approved!",
        CANCEL_CLICK:
          "APPROVAL REQUEST CANCELLED <br> Review all sections and proceed to approve",
      },

      VALIDATION: {
        REQUIRED: "This field is required",
        EMAIL_VALIDATION: "Please enter valid email address",
      },
    },
    REJECT: {
      TITLE: "Reason for Rejection",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Contact us has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED",
    },
  },
  testimonial: {
    LAYOUT: {
      TITLE: "Testimonial",
    },
    TESTIMONIAL: {
      NOTIFY_TITLE:"Testimonials",
      INVALID_ORDER_INDEX_ZERO : "Invalid order index: 0 not allowed.",
      DUPLICATE_ORDER_INDEX : "Duplicate Order Index not allowed",
      VIEW_TESTIMONIAL: "View Testimonial",
      ADD_TESTIMONIAL: "Add Testimonial",
      EDIT_TESTIMONIAL: "Edit Testimonial",
      CATEGORIES: "Category",
      HOMEPAGE: "Home Page",
      MODELPAGE: "Model Page",
      MODELPAGES: "Model Pages",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      STATUS: "Status",
      SELECT_MODELPAGE: "Select Model Page",
      TITLE: "User Name",
      USER_NAME: "User Name",
      AUTHOR_NAME: "Designation",
      ORDER_INDEX: "Order Index",
      AUTHOR_ORG_NAME: "Author Organization Name",
      AUTHOR_DESG: "Designation",
      SHORT_DESC: "Short Description",
      TESTIMONIAL_DESC: "Testimonial Description",
      PROFILE_IMG: "Profile Image",
      PENDING: "Pending",
      APPROVED: "Approved",
      APPROVE: "Approve",
      BTN_REJECT: "Reject",
      SAVE_AND_CONTINUE: "Save",
      SEND_TO_APPROVE: "Send To Approve",
      CLOSE: "Close",
      UPDATE: "Update",
      APPROVAL: {
        TITLE: "CONFIRM APPROVAL",
        HTML: "IT WILL APPROVE THIS PAGE <br> <br> press OK to APPROVE or Cancel to review again",
        OK_CLICK: "Testimonial has been approved!",
        CANCEL_CLICK:
          "APPROVAL REQUEST CANCELLED <br> Review all sections and proceed to approve",
      },
      REJECT: {
        TITLE: "Reason for Rejection",
        TEXT: "Please enter your reason",
        INPUT_VALIDATION: "Please enter reason!",
        INPUT_PLACEHOLDER: "Please enter your reason",
        OK_CLICK: "Testimonial has been rejected!",
        CANCEL_CLICK: "REJECT REQUEST CANCELLED",
      },
    },
  },
  EMI_Calculator: {
    LAYOUT: {
      TITLE: "EMI Calculator Setting",
      SETTINGS: "Settings",
      EMI_Calculator: "EMI_Calculator",
      SEARCH: "Search...",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      PREV: "Previous",
      NEXT: "Next",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      REJECT: "Reject",
      REJECTED: "Rejected",
      PREVIEW: "Preview",
      NEW: "New",
    },
    EMI_Calculator_Fields: {
      Vehicle_Price: "Vehicle Price",
      Vehicle_Price_Dropdown: [
        "Please Select",
        "On Road Price",
        "Selling Price",
      ],
      Downpayments: "Down payment%",
      DownpaymentAmount: "Down payment Amount",
      Payment_Term: "Payment Term",
      Minimun: "Minimum",
      Maximum: "Maximum",
      CITY_TO_BE_SHOWN: "City to be shown",
      INTREST_TO_BE_SHOWN: "Interest to be shown",
      Payment_Term_Dropdown: [12, 24, 36, 48, 60, 72, 84, 96],
      Interest_Rate_Selection_Allowed: "Interest Rate Selection Allowed?",
      Charges_On_Vehicle_Price: "Charges To Be Added in Price of Vehicle",
      Initial_payment_to_be_based_on_condition:
        "Initial payment to be based on condition",
      Vehicle_Charges_to_be_based_on_condition:
        "Vehicle Price based on condition",
      Charges_On_Down_Payment: "Charges To Be Added in Down Payment",
      Table: {
        Address_Level_1: "Address Level 1",
        Address_Level_2: "Address Level 2",
        Address_Level_3: "Address Level 3",
        Address_Level_4: "Address Level 4",
        Dealer_Code: "Dealer Code",
        Model: "Model",
        Intrest_Rate: "Interest Rate",
        ACTIONS: "Actions",
      },
      Value: "Value",
      Charges: "Charges",
      Action: "Action",
      ADD_ROW: "Add Row",
      ADD_CONDITION: "Add Condition",
      DELETE_CONDITION: "Delete Condition",
      MODELPAGE: "Model Page",
      MODELPAGES: "Model Pages",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      STATUS: "Status",
      SELECT_MODELPAGE: "Select Model Page",
      TITLE: "Title",
      AUTHOR_NAME: "Author Name",
      ORDER_INDEX: "Order Index",
      AUTHOR_ORG_NAME: "Author Organization Name",
      AUTHOR_DESG: "Author Designation",
      SHORT_DESC: "Short Description",
      EMI_Calculator_DESC: "EMI Calculator Description",
      PROFILE_IMG: "Profile Image",
      PENDING: "Pending",
      APPROVED: "Approved",
      APPROVE: "Approve",
      BTN_REJECT: "Reject",
      SAVE_AND_CONTINUE: "Save",
      SEND_TO_APPROVE: "Send To Approve",
      CLOSE: "Close",
      UPDATE: "Update",
      APPROVAL: {
        TITLE: "CONFIRM APPROVAL",
        TEXT: "IT WILL APPROVE EMI CALCULATOR SETTING<br> <br>  press OK to APPROVE or Cancel to review again",
        OK_CLICK: "EMI CALCULATOR Setting has been approved!",
        CANCEL_CLICK: "APPROVAL REQUEST CANCELLED",
      },
      REJECT: {
        TITLE: "Reason for Rejection",
        TEXT: "Please enter your reason",
        INPUT_VALIDATION: "Please enter reason!",
        INPUT_PLACEHOLDER: "Please enter your reason",
        OK_CLICK: " EMI CALCULATOR  has been rejected!",
        CANCEL_CLICK: "REJECT REQUEST CANCELLED",
      },
    },
  },
  theme: {
    THEME_SETTING: "Theme Setting",
    TITLE: "THEME TITLE",
    COLORS: "Colors",
    FONT_SIZE: "Font Size",
    HOMEPAGE: "Home Page",
    CMS_PAGE: "CMS pages",
    CAR_PAGES: "Model Page",
    FLOATING_MENU: "Floating Menu",
    CMS_TEMPLATEPAGE: "CMS Template Page",
    BANNER1: "Banner 1",
    BANNER2: "Banner 2",
    BANNER3: "Banner 3",

    HOMEPAGE_BANNER1_TITLE_FONT_SIZE: "Title Font Size",
    HOMEPAGE_BANNER1_DESC_FONT_SIZE: "Desc Font Size",
    HOMEPAGE_BANNER1_CTA_FONT_SIZE: "CTA Font Size",
    HOMEPAGE_BANNER1_TITLE_COLOR: "Title Color",
    HOMEPAGE_BANNER1_DESC_COLOR: "Desc Color",
    HOMEPAGE_BANNER1_CTA_COLOR: "CTA Color",

    HOMEPAGE_BANNER2_TITLE_FONT_SIZE: "Title Font Size",
    HOMEPAGE_BANNER2_DESC_FONT_SIZE: "Desc Font Size",
    HOMEPAGE_BANNER2_CTA_FONT_SIZE: "CTA Font Size",
    HOMEPAGE_BANNER2_TITLE_COLOR: "Title Color",
    HOMEPAGE_BANNER2_DESC_COLOR: "Desc Color",
    HOMEPAGE_BANNER2_CTA_COLOR: "CTA Color",

    HOMEPAGE_BANNER3_TITLE_FONT_SIZE: "Title Font Size",
    HOMEPAGE_BANNER3_DESC_FONT_SIZE: "Desc Font Size",
    HOMEPAGE_BANNER3_CTA_FONT_SIZE: "CTA Font Size",
    HOMEPAGE_BANNER3_TITLE_COLOR: "Title Color",
    HOMEPAGE_BANNER3_DESC_COLOR: "Desc Color",
    HOMEPAGE_BANNER3_CTA_COLOR: "CTA Color",

    FLOATING_MENU_FONT_SIZE: "Title Font Size",
    CMS_PAGE_TITLE_FONT_SIZE: "Banner Title Font Size",
    MODEL_TITLE_FONT_SIZE: "Title Font Size",
    MODEL_PRICE_FONT_SIZE: "Price Font Size",
    MODEL_PRICE_TITLE_FONT_SIZE: "Price Title Font Size",
    CMS_TEMPLATEPAGE_TITLE_FONT_SIZE: "Title Font Size",

    FLOATING_MENU_COLOR: "Title Color",
    FLOATING_MENU_BG_COLOR: "Background Color",
    CMS_PAGE_TITLE_COLOR: "Banner Title Color",
    MODEL_TITLE_COLOR: "Title Color",
    MODEL_PRICE_COLOR: "Price Color",
    MODEL_PRICE_TITLE_COLOR: "Price Title Color",
    CMS_TEMPLATEPAGE_TITLE_COLOR: "Title Color",

    PENDING: "Pending",
    APPROVED: "Approved",
    APPROVE: "Approve",
    SAVE_AND_CONTINUE: "Save",
    SEND_TO_APPROVE: "Send To Approve",
    CLOSE: "Close",
    UPDATE: "Update",
    APPROVAL: {
      TITLE: "CONFIRM APPROVAL",
      HTML: "IT WILL APPROVE THIS PAGE <br> <br> press OK to APPROVE or Cancel to review again",
      OK_CLICK: "Testimonial has been approved!",
      CANCEL_CLICK:
        "APPROVAL REQUEST CANCELLED <br> Review all sections and proceed to approve",
    },
    ADD_THEME: "Add theme",
    EDIT_THEME: "Edit Theme",
    VIEW_THEME: "View Theme",
    UPDATED: "Theme Setting has been successfully updated",
    APPLIED: "Applied",

    HOMEPAGE_BANNER1_TITLE_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    HOMEPAGE_BANNER1_DESC_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    HOMEPAGE_BANNER1_CTA_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#001151 (Suzuki Blue)", value: "#001151" },
    ],
    HOMEPAGE_BANNER1_TITLE_FONT_SIZE_ARRAY: [
      { label: "30px", value: "30px" },
      { label: "24px", value: "24px" },
    ],
    HOMEPAGE_BANNER1_DESC_FONT_SIZE_ARRAY: [{ label: "14px", value: "14px" }],
    HOMEPAGE_BANNER1_CTA_FONT_SIZE_ARRAY: [{ label: "14px", value: "14px" }],

    HOMEPAGE_BANNER2_TITLE_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    HOMEPAGE_BANNER2_DESC_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    HOMEPAGE_BANNER2_CTA_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#001151 (Suzuki Blue)", value: "#001151" },
    ],
    HOMEPAGE_BANNER2_TITLE_FONT_SIZE_ARRAY: [
      { label: "30px", value: "30px" },
      { label: "24px", value: "24px" },
    ],
    HOMEPAGE_BANNER2_DESC_FONT_SIZE_ARRAY: [{ label: "14px", value: "14px" }],
    HOMEPAGE_BANNER2_CTA_FONT_SIZE_ARRAY: [{ label: "14px", value: "14px" }],

    HOMEPAGE_BANNER3_TITLE_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    HOMEPAGE_BANNER3_DESC_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    HOMEPAGE_BANNER3_CTA_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#001151 (Suzuki Blue)", value: "#001151" },
    ],
    HOMEPAGE_BANNER3_TITLE_FONT_SIZE_ARRAY: [
      { label: "30px", value: "30px" },
      { label: "24px", value: "24px" },
    ],
    HOMEPAGE_BANNER3_DESC_FONT_SIZE_ARRAY: [{ label: "14px", value: "14px" }],
    HOMEPAGE_BANNER3_CTA_FONT_SIZE_ARRAY: [{ label: "14px", value: "14px" }],

    CMS_PAGE_TITLE_FONT_SIZE_ARRAY: [
      { label: "70px", value: "70px" },
      { label: "60px", value: "60px" },
    ],
    CMS_PAGE_TITLE_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    CAR_PAGE_TITLE_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    CAR_PAGE_TITLE_FONT_SIZE_ARRAY: [
      { label: "90px", value: "90px" },
      { label: "60px", value: "60px" },
    ],
    CAR_PAGE_PRICE_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    CAR_PAGE_PRICE_FONT_SIZE_ARRAY: [{ label: "30px", value: "30px" }],
    CAR_PAGE_PRICE_TITLE_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    CAR_PAGE_PRICE_TITLE_FONT_SIZE_ARRAY: [{ label: "14px", value: "14px" }],
    FLOATING_MENU_TITLE_FONT_SIZE_ARRAY: [
      { label: "14px", value: "14px" },
      { label: "11px", value: "11px" },
    ],
    FLOATING_MENU_TITLE_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#000000 (Black)", value: "#000000" },
    ],
    FLOATING_MENU_BG_COLOR_ARRAY: [
      { label: "#FFFFFF (White)", value: "#FFFFFF" },
      { label: "#001151 (Suzuki Blue)", value: "#001151" },
    ],
        LANDING_PAGE: "Landing Page",
        LANDINGPAGE_BANNER1: "Banner 1",
        LANDINGPAGE_BANNER2: "Banner 2",
        LANDINGPAGE_BANNER3: "Banner 3",

        LANDINGPAGE_BANNER1_TITLE_FONT_SIZE: "Title Font Size",
        LANDINGPAGE_BANNER1_DESC_FONT_SIZE: "Desc Font Size",
        LANDINGPAGE_BANNER1_CTA_FONT_SIZE: "CTA Font Size",
        LANDINGPAGE_BANNER1_TITLE_COLOR: "Title Color",
        LANDINGPAGE_BANNER1_DESC_COLOR: "Desc Color",
        LANDINGPAGE_BANNER1_CTA_COLOR: "CTA Color",

        LANDINGPAGE_BANNER2_TITLE_FONT_SIZE: "Title Font Size",
        LANDINGPAGE_BANNER2_DESC_FONT_SIZE: "Desc Font Size",
        LANDINGPAGE_BANNER2_CTA_FONT_SIZE: "CTA Font Size",
        LANDINGPAGE_BANNER2_TITLE_COLOR: "Title Color",
        LANDINGPAGE_BANNER2_DESC_COLOR: "Desc Color",
        LANDINGPAGE_BANNER2_CTA_COLOR: "CTA Color",

        LANDINGPAGE_BANNER3_TITLE_FONT_SIZE: "Title Font Size",
        LANDINGPAGE_BANNER3_DESC_FONT_SIZE: "Desc Font Size",
        LANDINGPAGE_BANNER3_CTA_FONT_SIZE: "CTA Font Size",
        LANDINGPAGE_BANNER3_TITLE_COLOR: "Title Color",
        LANDINGPAGE_BANNER3_DESC_COLOR: "Desc Color",
        LANDINGPAGE_BANNER3_CTA_COLOR: "CTA Color",

        LANDINGPAGE_BANNER1_TITLE_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#000000 (Black)', value: '#000000' },
        ],
        LANDINGPAGE_BANNER1_DESC_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#000000 (Black)', value: '#000000' },
        ],
        LANDINGPAGE_BANNER1_CTA_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#001151 (Suzuki Blue)', value: '#001151' },
        ],
        LANDINGPAGE_BANNER1_TITLE_FONT_SIZE_ARRAY: [
            { label: '30px', value: '30px' },
            { label: '24px', value: '24px' },
        ],
        LANDINGPAGE_BANNER1_DESC_FONT_SIZE_ARRAY: [
            { label: '14px', value: '14px' },
        ],
        LANDINGPAGE_BANNER1_CTA_FONT_SIZE_ARRAY: [
            { label: '14px', value: '14px' },
        ],

        LANDINGPAGE_BANNER2_TITLE_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#000000 (Black)', value: '#000000' },
        ],
        LANDINGPAGE_BANNER2_DESC_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#000000 (Black)', value: '#000000' },
        ],
        LANDINGPAGE_BANNER2_CTA_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#001151 (Suzuki Blue)', value: '#001151' },
        ],
        LANDINGPAGE_BANNER2_TITLE_FONT_SIZE_ARRAY: [
            { label: '30px', value: '30px' },
            { label: '24px', value: '24px' },
        ],
        LANDINGPAGE_BANNER2_DESC_FONT_SIZE_ARRAY: [
            { label: '14px', value: '14px' },
        ],
        LANDINGPAGE_BANNER2_CTA_FONT_SIZE_ARRAY: [
            { label: '14px', value: '14px' },
        ],

        LANDINGPAGE_BANNER3_TITLE_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#000000 (Black)', value: '#000000' },
        ],
        LANDINGPAGE_BANNER3_DESC_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#000000 (Black)', value: '#000000' },
        ],
        LANDINGPAGE_BANNER3_CTA_COLOR_ARRAY: [
            { label: '#FFFFFF (White)', value: '#FFFFFF' },
            { label: '#001151 (Suzuki Blue)', value: '#001151' },
        ],
        LANDINGPAGE_BANNER3_TITLE_FONT_SIZE_ARRAY: [
            { label: '30px', value: '30px' },
            { label: '24px', value: '24px' },
        ],
        LANDINGPAGE_BANNER3_DESC_FONT_SIZE_ARRAY: [
            { label: '14px', value: '14px' },
        ],
        LANDINGPAGE_BANNER3_CTA_FONT_SIZE_ARRAY: [
            { label: '14px', value: '14px' },
        ],
  },
  widget: {
    NOTIFY_TITLE:"Widgets",
    LAYOUT: {
      TITLE: "Widgets",
      PREVIEW: "Preview",
      PREV: "Previous",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      NEXT: "Next",
      BTN_REJECT: "Reject",
      ADD_NEW_WIDGET: "Add Widget",
      EDIT_NEW_WIDGET: "Edit Widget",
      VIEW_WIDGET: "View Widget",
      ADD_NEW: "Add New",
      ADD_TITLE: "Add a New Title",
      EDIT_TITLE: "Edit Title",
      VIEW_TITLE: "View Title",
      SAVE: "Save",
      WIDGET_TITLE: "Title",
      WIDGET_DESCRIPTION: "Description",
      WIDGET_STATUS: "Status",
      WIDGET_ACTIVE: "Active",
      WIDGET_INACTIVE: "Inactive",
      WIDGET_CREATE: "Create",
      WIDGET_UPDATE: "Update",
      WIDGET_MAP_TITLE: "Title",
      WIDGET_MAP_IDENTIFIER: "Identifier",
      CANCEL: "Cancel",
      NEW: "new",
      MODIFIED: "modified",
      DELETED: "deleted",
      WIDGET_LIST: "Widget List",
      WIDGET_MAP: "Widget Map",
      WIDGET_MAP_DRAG: "Drag item here...",
      PLEASE_MAP_WIDGET: "Please Map the Widget",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        CANCEL_CLICK: "Your data is safe!",
      },
      WIDGET_SENT_FOR_APPROVAL: "Widget sent for approval",
      WIDGET_APPROVED: "Widget has been approved!",
      VALIDATION: {
        WIDGET_TITLE: "This field is required",
        WIDGET_TITLE_VALID: "Please enter valid title",
        WIDGET_MAP_TITLE: "Title required",
        WIDGET_MAP_TITLE_ALREADY_EXISTS: "Title already exists",
        MULTIPLE_SPACES: "Can not contain double spacing",
      },
      APPROVAL: {
        TITLE: "CONFIRM APPROVAL",
        HTML: "IT WILL APPROVE THIS SECTION <br> <br> press OK to APPROVE or Cancel to review again",
        OK_CLICK: "Widget has been approved!",
        CANCEL_CLICK:
          "APPROVAL REQUEST CANCELLED <br> Review all sections and proceed to approve",
      },
    },
    REJECT: {
      TITLE: "Reason for Rejection",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Widget has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED",
    },
  },
  blog: {
    LAYOUT: {
      TITLE: "Blogs",
      BLOG_TITLE: "Blog",
      CATEGORY_TITLE: "Category",
    },
    PRIMARY_DETAILS: {
      MAIN_TITLE: "Primary Details",
      CATEGORIES: "Categories",
      TITLE: "Title",
      ORDER_INDEX: "Order Index",
      DESCRIPTION: " Description",
      SHORT_DESCRIPTION: "Short Description",
      AUTHOR_NAME: "Author Name",
      SLUG: "Slug",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      NEW: "new",
      MODIFIED: "Modified",
      PAGE_URL: "Page URL",
      PAGE_URL_PLACEHOLDER: "Page URL",
      VALIDATION: {
        PLEASE_SELECT_CATEGORY: "Please select category",
        REQUIRED: "This field is required",
        ORDER_INDEX: "The order index must be between 0 and 99.",
        VALID_SLUG: "Enter a valid page slug",
        NO_SPACE_URL: "URL cannot contains spaces.",
        SHORT_DESCRIPTION: "This field is required",
        LONG_DESCRIPTION: "This field is required",
        URL_REQ: "This field is required",
        URL_ALREADY_EXISTS: "Page url already exists",
        URL_CONTAIN_SPACES: "URL cannot contains spaces.",
      },
    },
    MEDIA: {
      TITLE: "Media",
      DESKTOP: {
        TITLE: "Desktop",
        BTN_DELETE: "Delete",
      },
      MOBILE: {
        TITLE: "Mobile",
        BTN_DELETE: "Delete",
      },
      UPDATE_MEDIA_SUCCESS_MSG: "The Media has been successfully updated",
      DELETE: {
        TITLE: "Are you sure?",
        TEXT: "Once deleted, you will not be able to recover this!",
        OK_CLICK: "Poof! Data has been deleted!",
        CANCEL_CLICK: "Your image file is safe!",
      },
    },
    SEO: {
      TITLE: "Seo Settings",
      FORM: {
        TITLE: "Title",
        DESC: "Description",
        KEYWORDS: "Keywords",
        TRACKING: "Tracking",
      },
      UPDATE_SEO_SUCCESS_MSG: "The Seo settings has been successfully updated",
    },
    ALL_PAGE: {
      LAYOUT: {
        NOTIFY_TITLE:"Blogs",
        PRIMARY_DETAILS: "Primary Details",
        MEDIA: "Media",
        SEO_SETTINGS: "Seo Settings",
        BTN_PENDING: "Pending",
        BTN_APPROVE: "Approve",
        BTN_APPROVED: "Approved",
        BLOG_SENT_FOR_APPROVAL: "Blog sent for approval",
        BTN_PREV: "Previous",
        BTN_NEXT: "Next",
        BTN_SAVE_CONTINUE: "Save And Continue",
        BTN_SEND_APPROVE: "Send To Approve",
        BTN_REJECT: "Reject",
        APPROVAL: {
          TITLE: "CONFIRM APPROVAL",
          HTML: "IT WILL APPROVE THIS BLOG <br> <br> press OK to APPROVE or Cancel to review again",
          APPROVED_MSG: "The blog has been successfully approved!",
          OK_CLICK: "Blog has been approved!",
          CANCEL_CLICK:
            "APPROVAL REQUEST CANCELLED <br> Review all sections and proceed to approve",
        },
        REJECT: {
          TITLE: "Reason for Rejection",
          TEXT: "Please enter your reason",
          INPUT_VALIDATION: "Please enter reason!",
          INPUT_PLACEHOLDER: "Please enter your reason",
          OK_CLICK: "Blog has been rejected!",
          CANCEL_CLICK: "REJECT REQUEST CANCELLED",
        },
      },
      BTN_ADD_BLG: "Add Blog",
      BTN_CLEAR: "Clear",
    },
    ALL_PAGE_TABLE: {
      TITLE: "Blogs",
      HEADER: {
        SNO: "S. No.",
        TITLE: "Title",
        DESC: "Description",
        AUTHOR: "Author Name",
        CREATED: "Created On",
        STATUS: "Status",
      },
    },
  },
  categories: {
    LAYOUT: {
      TITLE: "Title",
      DESC: "Description",
      SLUG: "Slug",
      BTN_SAVE: "Save",
      BTN_CANCEL: "Cancel",
      BTN_REJECT: "Reject",
      ADD_CATEGORY: "Add Category",
      VIEW_CATEGORY: "View Category",
      EDIT_CATEGORY: "Edit Category",
      SENT_FOR_APPROVAL: "Category sent for approval",
      SAVE_AND_CONTINUE: "Save And Continue",
      SEND_TO_APPROVE: "Send To Approve",
      PENDING: "Pending",
      APPROVE: "Approve",
      APPROVED: "Approved",
      SEARCH: "Search....",
      NO_BLOG_MSG:
        "You have not add any Blogs Please add at least one Blog from Blogs section",
    },
    APPROVAL: {
      TITLE: "CONFIRM APPROVAL",
      TEXT: "IT WILL APPROVE THIS CATEGORY <br> <br> press OK to APPROVE or Cancel to review again",
      CLICK_OK: "Category has been approved!",
      CLICK_CANCEL:
        "APPROVAL REQUEST CANCELLED <br> Review all sections and proceed to approve",
    },
    REJECT: {
      TITLE: "Reason for Rejection",
      TEXT: "Please enter your reason",
      INPUT_VALIDATION: "Please enter reason!",
      INPUT_PLACEHOLDER: "Please enter your reason",
      OK_CLICK: "Category has been rejected!",
      CANCEL_CLICK: "REJECT REQUEST CANCELLED",
    },
    VALIDATION: {
      TITLE: "This field is required",
      DESC: "This field is required",
      SLUG: "This field is required",
    },
  },
  dealerLocation: {
    LAYOUT: {
      TITLE: "Dealer Location",
    },
  },
  notifications: {
    SENT_FOR_APPROVAL_TITLE: "Request Approval",
    SENT_FOR_APPROVAL_DESC: "Request Approval",
    APPROVED_TITLE: "Approved",
    APPROVED_DESC: "has been approved!",
    REJECTED_TITLE: "Rejected",
  },
  htmlEditor: {
    PLACEHOLDER: "Start writing...",
  },
  loader: {
    text: "Loading your content...",
  },

  commonHeader: {
    V_CAR: "4W-Vehicle",
    V_BIKES: "2W-Vehicle",
    V_MARINES: "Marine-Vehicle",
    CAR: "Car",
    BIKES: "Bike",
    MARINES: "Marine",
    SELECT_LOB: "Select LOB",
    LOB_4W: "LOB set as 4W-Vehicle",
    LOB_2W: "LOB set as 2W-Vehicle",
    LOB_MARINES: "LOB set as Marine-Vehicle",
    LOB_CONFIRMATION: {
      OK: "Ok",
      CANCEL: "Cancel",
      TITLE: "CONFIRM APPROVAL",
      TEXT: "Are you sure? Would you like to change LOB ?",
      OK_CLICK: "Poof! LOB has been changed!",
      CANCEL_CLICK: "Change request cancelled"
    }
  },
};
