import { FETCH_FORM_ALL } from "../actions";

/* Function for content Forms Reducer */
const contentFormsReducer = (formField = [], action) => {
    switch (action.type) {
        case FETCH_FORM_ALL:
            return action.payload;
        default:
            return formField;

    }
};

export default contentFormsReducer;