import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Breadcrumb from "./Breadcrumb";
// import Configuration from "./Configuration";

import useWindowSize from "../../hooks/useWindowSize";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeader from "./CommonHeader";
import AppIcon from "../../assets/images/icons/App.png";
import CommonLeftMenu from "../../views/commonleftmenu";
import CommonAppSelector from "../../views/commonAppSelector";

const AdminLayout = ({ children }) => {
  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);

  const { collapseMenu, layout, subLayout, headerFixedLayout } =
    configContext.state;
  const { dispatch } = configContext;
/* Function for get out side click */
  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  });

/* Function for load data when window change */
  useEffect(() => {
    if (
      windowSize.width > 992 &&
      windowSize.width <= 1024 &&
      layout !== "horizontal"
    ) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }

    if (layout === "horizontal" && windowSize.width < 992) {
      dispatch({ type: actionType.CHANGE_LAYOUT, layout: "vertical" });
    }
  }, [dispatch, layout, windowSize]);

/* Function for get out side click for mobile */
  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  };

  let mainClass = ["pcoded-wrapper"];
  if (layout === "horizontal" && subLayout === "horizontal-2") {
    mainClass = [...mainClass, "container"];
  }

  let common = (
    <React.Fragment>
      <Navigation />
    </React.Fragment>
  );

  let mainContainer = (
    <React.Fragment>
      <NavBar />
      <div className="pcoded-main-container">
        <div className={mainClass.join(" ")}>
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb />
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  if (windowSize.width < 992) {
    let outSideClass = ["nav-outside"];
    if (collapseMenu) {
      outSideClass = [...outSideClass, "mob-backdrop"];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, "mob-fixed"];
    }

    common = (
      <div className={outSideClass.join(" ")} ref={ref}>
        {common}
      </div>
    );

    mainContainer = (
      <div>
        {/* <div className="pcoded-outside" onClick={() => mobileOutClickHandler}> */}
        {mainContainer}
        {/* </div> */}
      </div>
    );
  }

  const [headerHeight, setHeaderHeight] = useState("");

  //   const useCloseWhenClickOutside= (toggleState ,setToggleState, refName)=>{
  //     useEffect(()=>{
  //         const ifClickedOutside =(e)=>{
  //          
  //           
  //           e.target !== appButtonRef.current && !appButtonRef.current.contains(e.target) && toggleState && refName.current && !refName.current.contains(e.target) && refName.current.children && setToggleState(false)

  //         }
  //         document.addEventListener("mousedown",ifClickedOutside)

  //         return()=>{
  //           document.removeEventListener("mousedown",ifClickedOutside)
  //         }

  //       },[toggleState])
  // }
  // useCloseWhenClickOutside(
  //   showSubMenu, setShowSubMenu, submenuRef
  // )

  useEffect(() => {
    let height = document.getElementById("commonHeader")?.clientHeight + "px";
    setHeaderHeight(height);
  }, []);

  const location = useLocation();  
  if(!location.pathname.includes("/manage-allPage")) {
    sessionStorage.setItem("tablePagination", Number(1));
    sessionStorage.setItem("itemToShow", Number(10));
  }  


  return (
    <React.Fragment>
      <CommonHeader />
      {/* {common} */}

      <div className="d-flex " style={{ maxWidth: "100vw" }} >
        <CommonAppSelector headerHeight={headerHeight} />
        <CommonLeftMenu headerHeight={headerHeight} />

        <div style={{ maxHeight: `calc( 100vh - ${headerHeight} )` }} className="main-container__layout">
          {mainContainer}
        </div>
      </div>
      {/* { configBlock && <Configuration/> } */}

    </React.Fragment>
  );
};

export default AdminLayout;
