import * as _helper from "./helper";
import { API_URL, CONFIG } from "../config/constant";

let BASE_URL = API_URL;
// let BASE_URL = "http://localhost:5000";



const api = async (method, endpoint, data, options) => {
  try {
    let user = _helper.getUser();
    let idToken = _helper.getLocalStorage('id_token');
    let authToken = _helper.getLocalStorage("access_token");

    const url = `${BASE_URL}/${endpoint}`;

    const headers = {
      'Authorization': `Bearer ${authToken}`,
      'token_payload': JSON.stringify({ ...user, default_dealer: user?.dealer_code, id_token: idToken, lob: user?.lob, user_type: user?.user_type, dealer_code: user?.dealer_code, access_token: authToken }),
      ...(!(data instanceof FormData)) && { 'Content-Type': 'application/json' }
    }

    const response = await fetch(url, {
      method,
      headers: { ...headers },
      body: data ? (data instanceof FormData ? (data) : JSON.stringify(data)) : undefined
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.error("response content api ", responseData);

      if (responseData?.message === "The incoming token has expired" || responseData?.message === "Access denied" || responseData?.message === 'Access denied Role!' || responseData?.status === 401) {
        const URL = CONFIG.LOGIN_REDIRECT_URL;
        localStorage.clear();
        window.location.href = URL;
      }
    }
    if (responseData?.statusCode === 200 || responseData?.statusCode === 201 || responseData?.statusCode === 409) {
      if (responseData?.data) {
        responseData.data = await _helper.decrypt(responseData?.data);
      }
      return responseData;
    } else {
      _helper.sendResponse("error", responseData?.message);
      return Promise.reject((responseData?.message) || "Something went wrong");
    }
  } catch (error) {
    console.error("error content api", error);
    throw error;
  }
}

export default api;
