import React, { useEffect, useRef, useState } from "react";
import * as _ from 'lodash';
import fullLogo from "../../../assets/images/SuzukiLogo.png";
import notificationIcon from "../../../assets/images/icons/notification-2-line.png";
import userIcon from "../../../assets/images/profile.svg";
import Submenu from "./subMenu";
import NotificationSubmenu from "./NotificationSubmenu";
import { useHistory } from "react-router-dom";
import { fetchAllNotifications, updateNotificationSeenAt } from "../../../services/api/notifications";
import { useCloseWhenClickOutside } from "../../../views/commonleftmenu/useCloseWhenClickOutside";

import { ROLE_PUBLISHER } from "../../../constants/userRoles";
import * as _helper from '../../../utils/helper';
import { CONSTANTS } from "../../../_helper/constant";
const CommonHeaderConst = CONSTANTS.commonHeader;

const CommonHeader = ({ hamburger, setHamburger, menuRef }) => {

  const user = _helper.getUser();

  const history = useHistory();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);

  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showSubMenu, setShowSubmenu] = useState(false);
  let lobArray = user?.lobList ? user?.lobList : [];
console.log("lobArray" , lobArray, user)
  const lobOptionsArray = [
    {
      label: CommonHeaderConst.V_CAR,
      value: "1"
    },
    {
      label: CommonHeaderConst.V_BIKES,
      value: "2"
    },
    {
      label: CommonHeaderConst.V_MARINES,
      value: "3"
    }
  ]
  let lobOptions = lobOptionsArray.filter(e => {
    if (lobArray?.indexOf(Number(e?.value)) >= 0)
      return e;
  })
console.log("lobOptions", lobOptions)
  const [selectedLob, setSelectedLob] = useState(_helper.getLocalStorage('selectedLob') !== null ? _helper.getLocalStorage('selectedLob') : lobOptions[0]?.value);


  const UserSubMenuRef = useRef(null);
  const notificationBarRef = useRef(null);
  useCloseWhenClickOutside(showSubMenu, setShowSubmenu, UserSubMenuRef, UserSubMenuRef)
  useCloseWhenClickOutside(showNotificationPopup, setShowNotificationPopup, notificationBarRef)

  /* Function for get notification list */
  const getNotifications = async () => {
    const response = await fetchAllNotifications();
    if (response && response?.statusCode === 200) {

      const result = response?.data;

      //0-sentforapproval,1-approved,2-rejected
      if (user && user.role === ROLE_PUBLISHER) {
        const data = await _.filter(result, function (n) { return n?.type === 0; });
        const first10 = data?.slice(0, 10);
        const resultData = await data?.filter(obj => !obj.hasOwnProperty('seenAt'))
        const count = resultData ? (resultData.length > 10 ? 10 : resultData.length) : 0;
        await setNotificationCount(count);
        await setNotificationData(first10);

      } else {
        const data = await _.filter(result, function (n) { return n?.type !== 0; });
        const first10 = data?.slice(0, 10);
        const resultData = await data?.filter(obj => !obj.hasOwnProperty('seenAt'))
        const count = resultData ? (resultData.length > 10 ? 10 : resultData.length) : 0;
        await setNotificationCount(count);
        await setNotificationData(first10);

      }
    }
  }

  /* Function for see notification */
  const callNotificationSeenAt = async () => {
    if (notificationCount > 0) {
      const type = user.role === ROLE_PUBLISHER ? 0 : 1;
      const response = await updateNotificationSeenAt(type);
      await setNotificationCount(0);
    }
    await setShowNotificationPopup(!showNotificationPopup)
  }

  /* Function for call get notification when popup open or closed */
  useEffect(() => {
    let intervalId = setInterval(function () {
      let id_token = _helper.getLocalStorage('id_token');
      let access_token = _helper.getLocalStorage('access_token');

      if (id_token && access_token) {
        getNotifications();
        clearInterval(intervalId);
      }
    }, 1000);
  }, [showNotificationPopup]);

  /* Function for onLobChange */
  const onLobChange = async (lob) => {
    setSelectedLob(lob)

    _helper.setLocalStorage('selectedLob', lob)
    if (lob == 1) _helper.sendResponse("success", CommonHeaderConst.LOB_4W)
    else if (lob == 2) _helper.sendResponse("success", CommonHeaderConst.LOB_2W)
    else _helper.sendResponse("success", CommonHeaderConst.LOB_MARINES);
    history.push("/dashboard");
    window.location.reload();

  };

  /* Function for update lob in localstorage and move to dashboard */
  useEffect(() => {
    _helper.removeLocalStorage('user')
    _helper.setUser(JSON.stringify({ ...user, lob: selectedLob }))
  }, [selectedLob]);

  return (
    <>
      <div className="commonHeader" id="commonHeader">
        <div className="commonHeader__inner">
          <div className="header__logo">
            <a>
              <img src={fullLogo} alt="MarutiSuzukiLogo" width={"100%"} height={"100%"} />
            </a>
          </div>
          <div className="flex-1">
            <div className="">
              <ul className="navbar navbar--user">
                <label className="LOB-TEST">{CommonHeaderConst.SELECT_LOB}</label>
                <li className="navbar__links navbar__links--user " >
                  <select className="lob-dropdown" onChange={(e) => onLobChange(e.target.value)}>
                    {lobOptions.map(e => { return (<option value={e.value} selected={e.value == selectedLob}>{e.label} </option>) })}
                  </select>
                </li>
                <li ref={notificationBarRef} className="navbar__links navbar__links--user ">
                  <div className="user__icon">
                    <img
                      onClick={callNotificationSeenAt}
                      src={notificationIcon}
                      alt="notification"
                      width={"100%"}
                    />
                  </div>
                  {notificationCount > 0 &&
                    <div className="notification__count cursor-pointer">
                      {notificationCount}
                    </div>
                  }
                  {
                    showNotificationPopup && <NotificationSubmenu notificationData={notificationData} setShowNotificationPopup={setShowNotificationPopup} />
                  }
                </li>

                <li ref={UserSubMenuRef} className={"navbar__links navbar__links--user "} >
                  <div className="user__icon user__icon--profile" onClick={() => {
                    setShowSubmenu(!showSubMenu)
                  }}>
                    <img src={userIcon} style={{ width: "25px", height: "25px" }} alt="user" width={"100%"} />
                  </div>
                  {showSubMenu && <Submenu showSubMenu={showSubMenu} setShowSubmenu={setShowSubmenu} />}
                </li>

              </ul>
            </div>
          </div>
        </div>
        <div className="border h-px"></div>
      </div>
    </>
  )
}

export default CommonHeader;
