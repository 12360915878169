export const BASENAME = process.env.REACT_APP_BASENAME; // don't add '/' at end off BASENAME
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_TITLE = process.env.REACT_APP_BASE_TITLE;
export const API_URL = process.env.REACT_APP_API_URL;

export const AWS_URL = process.env.REACT_APP_AWS_URL;
/// local changes 
export const CONFIG = {
  layout: "vertical", // vertical, horizontal local changes
  subLayout: "", // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: true, // mini-menu
  layoutType: "menu-light", // menu-dark, menu-light, dark
  navIconColor: false,
  headerBackColor: "header-blue", // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: "navbar-default", // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: "brand-blue", // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  rtlLayout: false,
  navFixedLayout: true, // only for vertical layouts
  headerFixedLayout: false, // only for vertical layouts
  boxLayout: false,
  navDropdownIcon: "style1", // style1, style2, style3
  navListIcon: "style1", // style1, style2, style3, style4, style5, style6
  navActiveListColor: "active-default", // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: "title-default", // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: true,
  layout6Background: "linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)", // used only for pre-layout = layout-6
  layout6BackSize: "", // 'auto' - for background pattern, 'cover' - for background images & used only for pre-layout = layout-6
  jwt: {
    secret: process.env.REACT_APP_JWT_SECRET,
    timeout: process.env.REACT_APP_JWT_TIME_OUT,
  },

  CRYPTO: {
    SECRET: "vAAA6sdmpNWjPPKqCc7rdxs09lwHzfr3",
    IV: "01d9814fbe2b69cc44997135734eca75",
    ALGORITHM: "aes-256-ctr",
  },
  MODEL_MANUFACTURER_CODE: process.env.REACT_APP_MODEL_MANUFACTURER_CODE,
  COMMON_MASTERS_URL: process.env.REACT_APP_COMMON_MASTERS_URL,
  DEALER_CODE: process.env.REACT_APP_DEALER_CODE,

  SEGMENTS_URL: `${process.env.REACT_APP_COMMON_MASTERS_URL}/items/PHL/category/segment_type`,
  FUEL_URL: `${process.env.REACT_APP_COMMON_MASTERS_URL}/items/PHL/category/fuel_type`,
  EMISSION_URL: `${process.env.REACT_APP_COMMON_MASTERS_URL}/items/PHL/category/emission_type`,
  TRANSMISSION_URL: `${process.env.REACT_APP_COMMON_MASTERS_URL}/items/PHL/category/transmission_type`,
  GEARBOX_URL: `${process.env.REACT_APP_COMMON_MASTERS_URL}/items/PHL/category/gearbox_type`,
  PROPULSION_URL: `${process.env.REACT_APP_COMMON_MASTERS_URL}/items/PHL/category/propulsion_type`,

  AWS: {
    path: process.env.REACT_APP_AWS_PATH,
    bucket_name: process.env.REACT_APP_AWS_BUCKET_NAME,
  },
  SCRIPT_HEAD_PATH: process.env.REACT_APP_SCRIPT_HEAD_PATH,
  SCRIPT_BODY_PATH: process.env.REACT_APP_SCRIPT_BODY_PATH,
  LQS_URL: process.env.REACT_APP_LQS_URL,

  PREVIEW_URL: process.env.REACT_APP_PREVIEW_URL,

  LOGIN_REDIRECT_URL: process.env.REACT_APP_LOGIN_REDIRECT_URL_SPH,


  GENERATE_TOKENS_URL: process.env.REACT_APP_GENERATE_TOKENS_URL,
  USER_PROFILE_URL: process.env.REACT_APP_USER_PROFILE_URL,
  DISTRIBUTERS_URL: process.env.REACT_APP_DISTRIBUTERS_URL,
  SUZUKI_UI_URL: process.env.REACT_APP_SUZUKI_UI_URL,
  LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
  LOGOUT_REDIRECT_URL: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
  MAIN_URL: process.env.REACT_APP_MAIN_URL,
  LQS_BASE_URL:process.env.REACT_APP_LQS_BASE_URL,
  LQS_X_API_KEY:process.env.REACT_APP_LQS_X_API_KEY,

  COMMON_MASTER_LOB: process.env.REACT_APP_COMMON_MASTER_LOB,
  COMMON_MASTERS_X_API_KEY: process.env.REACT_APP_COMMON_MASTERS_X_API_KEY,
  CMS_URL: process.env.REACT_APP_CMS_URL,
  LQS_URL: process.env.REACT_APP_LQS_URL,
  COMMON_MASTERS_USERAPPS_URL: process.env.REACT_APP_COMMON_MASTERS_USERAPPS_URL,
  REACT_APP_IS_QA_ENV: process.env.REACT_APP_IS_QA_ENV,
  COUNTRY_CODE:process.env.REACT_APP_COUNTRY_CODE,
  COMMON_MASTERS_URL_SELLING_PRICE: process.env.REACT_APP_COMMON_MASTERS_SELLING_PRICE_URL
  //code comment
};