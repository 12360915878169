import React from 'react';

const NavIcon = ({ items }) => {
    let navIcons = false;
    if (items.img) {
        navIcons = <span className="pcoded-micon">
                       <img src={items.img} width="18px" />
                    </span>;
    } else if(items.icon) {
        navIcons = <span className="pcoded-micon">
                       <i className={items.icon} />
                    </span>;
    }

    return (
        <React.Fragment>
            {navIcons}
        </React.Fragment>
    );
};

export default NavIcon;
