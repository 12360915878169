import React, { useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import { NavLink, useHistory } from "react-router-dom";

import NavIcon from "../NavIcon";
import NavBadge from "../NavBadge";

import { ConfigContext } from "../../../../../contexts/ConfigContext";
import * as actionType from "../../../../../store/actions";
import useWindowSize from "../../../../../hooks/useWindowSize";
import * as _helper from "../../../../../utils/helper";
// import AppIcon from "../../assets/images/icons/App.png";
import RightArrow from "../../../../../assets/images/arrow-right.svg";

const NavItem = ({ layout, item }) => {
    const windowSize = useWindowSize();
    const configContext = useContext(ConfigContext);
    const { dispatch } = configContext;

    const history = useHistory();

    const changeSideBar = async (e, item_name) => {
        e.preventDefault();
        const changeResponse = await _helper.saveConfirmation(item_name?.url);
        const changes = _helper.getSessionStorage('valueChange');

        if (changes === 'true') {
            if (changeResponse && changeResponse === true) {
                _helper.removeSessiontorage('valueChange');
                history.push(`${item_name?.url}`);
            }
        } else {
            history.push(`${item_name?.url}`);
        }
    }

    let itemTitle = item.title;
    if (item.icon) {
        itemTitle = <span className="pcoded-mtext">{item.title}</span>;
    }

    let itemTarget = '';
    if (item.target) {
        itemTarget = '_blank';
    }

    let subContent;
    if (item.external) {
        subContent = (
            <a href={item.url} target='_blank' rel='noopener noreferrer'>
                <NavIcon items={item} />
                {itemTitle}
                <NavBadge items={item} />
            </a>
        );
    } else {
        subContent = (
            <NavLink to={item.url} onClick={(e) => changeSideBar(e, item)} className="leftmenu__nav-link text-nowrap" exact={true} target={itemTarget}>
                {/* <NavIcon items={item} /> */}
                {itemTitle}
                <div className='leftmenu__nav-arrow'>
                    <img src={RightArrow} />
                </div>
                <NavBadge items={item} />
            </NavLink>
        );
    }
    let mainContent = '';
    if (layout === 'horizontal') {
        mainContent = (
            <ListGroup.Item as='li' bsPrefix=' ' onClick={() => dispatch({ type: actionType.NAV_CONTENT_LEAVE })}>{subContent}</ListGroup.Item>
        );
    } else {
        if (windowSize.width < 992) {
            mainContent = (
                <ListGroup.Item as='li' bsPrefix=' ' className={item.classes} onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}>{subContent}</ListGroup.Item>
            );
        } else {
            mainContent = (
                <ListGroup.Item as='li' bsPrefix=' ' className={item.classes}>{subContent}</ListGroup.Item>
            );
        }
    }

    return (
        <React.Fragment>
            {mainContent}
        </React.Fragment>
    );
};

export default NavItem;
