import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ConfigContext } from "../../../../contexts/ConfigContext";
import * as actionType from "../../../../store/actions";
import miniLogo from "../../../../assets/images/suzuki-logo.png";
import fullLogo from "../../../../assets/images/suzuki-original-logo.png";

const NavLogo = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;

  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  const navbar = document.getElementsByClassName("navbar-collapsed")

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo">
        <Link to="#" className="b-brand">
          {collapseMenu?<>
          <div className="b-bg logo--mini">
            {/* <i className="feather icon-trending-up" /> */}
            <img src={miniLogo} alt="Suzuki Logo" width="20px" />
          </div>
          <div className="logo--full">
            {/* <i className="feather icon-trending-up" /> */}
            <img src={fullLogo} alt="Suzuki Logo" width="150px" height={"50px"} />
          </div></>:
          <div className="logo--full logo--withoutCollpase">
          {/* <i className="feather icon-trending-up" /> */}
          <img src={fullLogo} alt="Suzuki Logo" width="150px" height={"50px"} />
        </div>
          }
          {/* <span className="b-title">Suzuki CMS</span> */}
        </Link>
        <Link
          to="#"
          className={toggleClass.join(" ")}
          id="mobile-collapse"
          onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}
        >
          <span>dddd</span>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
