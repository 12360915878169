import * as ACTIONS from "../actions";

/* Function for model Brochure Reducer */
export const modelBrochureReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.MODEL_BROCHURE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for mobile File Reducer */
export const mobileFileReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.MOBILE_FILE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for desktop File Reducer */
export const desktopFileReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.DESKTOP_FILE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for variant Color Icon Reducer */
export const variantColorIconReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.COLOR_ICON:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for variant Color Image Reducer */
export const variantColorImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.COLOR_IMAGE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for banner Image Reducer */
export const bannerImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.BANNER_IMAGE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for homepage Banner Image Reducer */
export const homepageBannerImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.HOMEPAGE_BANNER_IMAGE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for homepage Banner Image For Mobile Reducer */
export const homepageBannerImageMobReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.HOMEPAGE_BANNER_IMAGE_MOB:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};


/* Function for landing Banner Image Reducer */
export const landingBannerImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.LANDING_BANNER_IMAGE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for landing Banner Image For Mobile Reducer */
export const landingBannerImageMobReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.LANDING_BANNER_IMAGE_MOB:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for landing Banner Image2 Reducer */
export const landingBannerImageReducer2 = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.LANDING_BANNER_IMAGE2:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for landing Banner Image2 For Mobile Reducer */
export const landingBannerImageMobReducer2 = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.LANDING_BANNER_IMAGE_MOB2:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for landing Banner Image3 Reducer */
export const landingBannerImageReducer3 = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.LANDING_BANNER_IMAGE3:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for landing Banner Image3 For Mobile Reducer */
export const landingBannerImageMobReducer3 = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.LANDING_BANNER_IMAGE_MOB3:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};


/* Function for feature Image Desk Banner Reducer */
export const featureImageDeskBannerReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.FEATURE_IMAGE_DESKTOP_BANNER:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for feature Image Desk Medium Reducer */
export const featureImageDeskMediumReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.FEATURE_IMAGE_DESKTOP_MEDIUM:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for Pages Reducer */
export const featureImageDeskThumbReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.FEATURE_IMAGE_DESKTOP_THUMB:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for feature Image Mob Banner Reducer */
export const featureImageMobBannerReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.FEATURE_IMAGE_MOB_BANNER:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for feature Image Mob Medium Reducer */
export const featureImageMobMediumReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.FEATURE_IMAGE_MOB_MEDIUM:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
/* Function for feature Image Mob Thumb Reducer */
export const featureImageMobThumbReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.FEATURE_IMAGE_MOB_THUMB:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
/* Function for feature Image Desk Placeholder Reducer */
export const featureImageDeskPlaceholderReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.FEATURE_IMAGE_DESKTOP_PLACEHOLDER:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for feature Image Mob Placeholder Reducer */
export const featureImageMobPlaceholderReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.FEATURE_IMAGE_MOB_PLACEHOLDER:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for ext Mobile File Reducer */
export const extMobileFileReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_MOBILE_FILE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for ext Desktop File Reducer */
export const extDesktopFileReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.EXT_DESKTOP_FILE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
/* Function for all Page Image Reducer */
export const allPageImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.ALL_PAGE_IMAGE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};
/* Function for all Page Mob Image Reducer */
export const allPageMobImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.ALL_PAGE_MOB_IMAGE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for blog Desktop Image Reducer */
export const blogDesktopImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.BLOG_DESKTOP:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for blog Mobile Image Reducer */
export const blogMobileImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.BLOG_MOBILE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for testimonial Image Reducer */
export const testimonialImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.TESTIMONIAL_PROFILE_IMG:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for slider Image Reducer */
export const sliderImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.SLIDER_IMG:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for slider Image Reducer */
export const slidermobileImageReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.URLMOBILE:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for upload Img Reducer */
export const uploadImgReducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.UPLOAD_IMG:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};

/* Function for upload testimonial profile Reducer */
export const testimonialprofilereducer = (file_url = '', action) => {
    switch (action.type) {
        case ACTIONS.TESTIMONIAL_PROFILE_IMG_URL:
            return action.payload;

        case "RESET":
            return '';

        default:
            return file_url;

    }
};