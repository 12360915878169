import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CONFIG } from "../../../../config/constant";
import { ListGroup, Dropdown } from "react-bootstrap";
import * as _helper from "../../../../utils/helper";
import avatar4 from "../../../../assets/images/user/avatar-4.jpg";
import { ConfigContext } from "../../../../contexts/ConfigContext";

const NavRight = () => {
  const history = useHistory();

  const user = _helper.getUser();
  const configContext = useContext(ConfigContext);
  const { rtlLayout } = configContext.state;

  const handleLogout = async () => {
    try {
      localStorage.clear();
      const URL = CONFIG.LOGOUT_REDIRECT_URL;
      window.location.href = URL;
    } catch (err) {
      return err;
    }
  };

  return (
    <React.Fragment>
      <ListGroup
        as="ul"
        bsPrefix=" "
        className="ml-auto navbar-nav"
        id="navbar-right"
      >
        {/* Settings */}
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout}>
            <i
              onClick={() => {
                history.push("/settings");
              }}
              className="icon feather icon-settings"
              style={{ cursor: "pointer" }}
            />
          </Dropdown>
        </ListGroup.Item>

        {/* User menu */}
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle variant="link" id="dropdown-basic">
              <img
                src={avatar4}
                className="img-radius"
                height={"35px"}
                alt="User Profile"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                <span>{user?.["username"]}</span>
                <Link
                  className="dud-logout"
                  style={{ color: "black" }}
                  title="Logout"
                  to=""
                  onClick={handleLogout}
                >
                  <i className="feather icon-log-out" />
                </Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default NavRight;
