import React from 'react';
import { CONFIG } from "../../config/constant";
import { useHistory } from "react-router-dom";
import * as _helper from "../../utils/helper";


const AuthGuard = ({ children }) => {

    const history = useHistory();
    let user = _helper.getUser();
    const currDate = new Date();

    if (user) {
        const expDate = new Date(1000 * user?.exp);

        if (expDate < currDate) {
            _helper.sendResponse('error', 'Session expired,Please login');
            _helper?.logoutHandler();
        }
    } else {
        history.push('/login-process')
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default AuthGuard;
