import React, { useEffect, useRef, useState } from "react";
import { CONFIG } from "../../config/constant";
import AppIcon from "../../assets/images/icons/App.png";
import Navigation from "../../layouts/AdminLayout/Navigation";
import { DISTRIBUTOR } from "../../constants/userRoles";
import * as _helper from "../../utils/helper"
import SearchIcon from "../../assets/images/search.svg"
import navigation from "../../../src/menu-items.js";
import {useHistory } from "react-router-dom";


import { CONSTANTS } from "./../../_helper/constant.js";
const LEFTMENU_CONST = CONSTANTS.commonLeftMenu;

const CommonLeftMenu = ({ headerHeight }) => {

  const user = _helper.getUser();
  const submenuRef = useRef(null);
  const appButtonRef = useRef(null);
  const [showSubMenu, setShowSubMenu] = useState("");
  const [defaultApp, setDefaultApp] = useState("CMS");

  const [defaultDealer, setDefaultDealer] = useState("");
  const [dealersList, setDealersList] = useState([]);
  const [searchItem, setsearchItem]=useState("")
  const [searchData, setsearchData]=useState([])

  const history = useHistory();



  const listItems = dealersList?.map((item, index) =>
    <React.Fragment key={index}>
      <li className="leftmenu__submenuLink">
        <div href={""}>
          <a draggable={false}>
            <div className="leftmenu__submenutab">
              <div className="submenuTitle">
                <div className="submenuTitle__tab">
                  {item?.name} - {item?.code}
                  {defaultDealer === item?.code ? (
                    <>
                      <div className="col text-center">
                        <div className="defaultSelected"> {LEFTMENU_CONST.DEFAULT} </div>
                      </div>
                      <div></div>
                    </>
                  ) : (
                    <>
                      <div className="setAsDefault">
                        <button onClick={() => {
                          _helper.setLocalStorage("default_dealer", item?.code)
                          setDefaultDealer(item?.code);
                          setShowSubMenu("");
                        }} >
                          {LEFTMENU_CONST.SET_AS_DEFAULT}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </a>
        </div>
      </li>
    </React.Fragment>
  );


  const headers = {};

  const api = async (method, url) => {
    try {
      const response = await fetch(url, { method, headers: { ...headers } });
      const responseData = await response?.json();

      if (responseData) {
        return responseData;
      } else {
        _helper.sendResponse("error", responseData?.message);
        return Promise.reject((responseData?.message) || "Something went wrong");
      }
    } catch (error) {
      console.error("error common left menu ", error);
      throw error;
    }
  }

  const fetchDealer = async (parent_dealer_code) => {

    const response = await api('GET', `${CONFIG?.COMMON_MASTERS_URL}/parentDealers/${parent_dealer_code}/dealers?lob=${user.lob}`);
    const default_dealer = _helper.getLocalStorage('default_dealer');
    let dealer = '';

    if (response) {
      if (response?.data?.length > 0) {
        dealer = (default_dealer && default_dealer !== 'undefined') ? default_dealer : response?.data[0]?.code;
      } else {
        dealer = parent_dealer_code;
      }
      _helper.setLocalStorage("default_dealer", dealer);
      await setDefaultDealer(dealer);
      await setDealersList(response?.data);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      const user = _helper.getUser();
      await fetchDealer(user?.parent_dealer_code);
    }

    if (user?.parent_dealer_code === DISTRIBUTOR) {
      _helper.setLocalStorage("default_dealer", `${DISTRIBUTOR}`);
      setDefaultDealer(`${DISTRIBUTOR}`);
      setDealersList([]);
    } else {
      fetchData();
    }
    const temparray=[]
    navigation?.items[0]?.children?.map((item)=>{
      if(item?.children){
        item?.children?.map((subitem)=>{temparray.push(subitem)})
      }
      else{
      temparray.push(item)
      }
    })
    setsearchData(temparray)
  }, []);

  function filterBySearch(list, searchItem) {
    return list.filter(item => item?.title?.toLowerCase()?.includes(searchItem?.toLowerCase()));
}

  return (
    <>
      <div
        className="commonLeftMenu__container"
        style={{
          overflow: "auto",
          maxWidth: "240px",
          margin: "0px",
          backgroundColor: "#FFF",
          padding: "16px",
          maxHeight: `calc( 100vh - ${headerHeight} )`,
        }}
      >
        {/* Searchbar Start */}
        <div style={{ padding: "2px", position: "relative" }}>
          <div className="commonLeftMenu__searchBarIcon">
            <img src={SearchIcon} />
          </div>
          <input
            style={{ width: "100%" }}
            className="commonLeftMenu__searchBar"
            placeholder="Search"
            value={searchItem}
            onChange={(e) => {
              setsearchItem(e.target.value);
              filterBySearch(searchData, e.target.value);
            }}
          />
        </div>

        {searchItem && (
          <div className="searchItem">
            {filterBySearch(searchData, searchItem)
              ?.length > 0 ? (
              filterBySearch(searchData, searchItem)?.map(
                (item) => {
                  return (
                    <div
                      className="searchResults"
                      onClick={() => {
                        history.push(item?.url);
                        setsearchItem("");
                      }}
                    >
                      {item?.title}
                    </div>
                  );
                }
              )
            ) : (
              <div className="searchResults">{LEFTMENU_CONST?.NO_RESULT_FOUND}</div>
            )}
          </div>
        )}
        <Navigation />

        {/* *************************** Dealers ***************************/}

        {user && user?.user_type === DISTRIBUTOR ? null : (
          <div className="navbar__links--leftmenuApp">
            <button
              className="navbar__submenuButton"
              ref={appButtonRef}
              onClick={() => {
                showSubMenu === "Dealers"
                  ? setShowSubMenu("")
                  : setShowSubMenu("Dealers");
              }}
            >
              <>
                <div style={{ paddingBottom: "0.5rem" }}>
                  <img src={AppIcon} height={18} width={18} />
                </div>
                <div className="text">
                  {CONSTANTS.app.LAYOUT.DEALERS}
                  <br></br> ({defaultDealer})
                </div>
              </>
            </button>
            <div className="navbar__submenuOuter">
              {showSubMenu === "Dealers" && (
                <>
                  <div className="leftmenu__submenu">
                    <div
                      className="leftmenu__submenuOuter"
                      style={{ height: `calc(100vh - ${headerHeight})` }}
                      ref={submenuRef}
                    >
                      <div className="leftmenu__submenuOuter--button"> </div>
                      <h4 className="font-bold py-2 px-2">
                        {CONSTANTS.app.LAYOUT.SWITCH_TO}
                      </h4>
                      <ul>{listItems}</ul>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CommonLeftMenu