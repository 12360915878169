import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import "./services";

import { Provider } from "react-redux";
import { ConfigProvider } from "./contexts/ConfigContext";

import "./index.scss";
import "./unreset.css"
import App from "./App";
import store from "./store";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
