export const BOX_LAYOUT = 'BOX_LAYOUT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const RESET = 'RESET';
export const RTL_LAYOUT = 'RTL_LAYOUT';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALISE = 'ACCOUNT_INITIALISE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';


export const CREATE = 'CREATE';
export const FETCH = 'FETCH_';
export const FETCH_ALL = 'FETCH_ALL';
export const MOBILE_FILE = 'MOBILE_FILE';
export const DESKTOP_FILE = 'DESKTOP_FILE';
export const COLOR_ICON = 'COLOR_ICON';
export const COLOR_IMAGE = 'COLOR_IMAGE';
export const MODEL_BROCHURE = 'MODEL_BROCHURE';
export const EXT_MOBILE_FILE = 'EXT_MOBILE_FILE';
export const EXT_DESKTOP_FILE = 'EXT_DESKTOP_FILE';
export const SLIDER_IMG = 'SLIDER_IMG';
export const URLMOBILE = 'URLMOBILE';


export const FETCH_CMS_ALL = 'FETCH_ALL_CMS';
export const FETCH_ALL_PAGES = 'FETCH_ALL_PAGES';
export const BANNER_IMAGE = 'BANNER_IMAGE';

export const HOMEPAGE_BANNER_IMAGE = 'HOMEPAGE_BANNER_IMAGE';
export const HOMEPAGE_BANNER_IMAGE_MOB = 'HOMEPAGE_BANNER_IMAGE_MOB';

export const LANDING_BANNER_IMAGE = 'LANDING_BANNER_IMAGE';
export const LANDING_BANNER_IMAGE_MOB = 'LANDING_BANNER_IMAGE_MOB';

export const LANDING_BANNER_IMAGE2 = 'LANDING_BANNER_IMAGE2';
export const LANDING_BANNER_IMAGE_MOB2 = 'LANDING_BANNER_IMAGE_MOB2';

export const LANDING_BANNER_IMAGE3 = 'LANDING_BANNER_IMAGE3';
export const LANDING_BANNER_IMAGE_MOB3 = 'LANDING_BANNER_IMAGE_MOB3';


export const FEATURE_IMAGE_DESKTOP_BANNER = 'FEATURE_IMAGE_DESKTOP_BANNER';
export const FEATURE_IMAGE_DESKTOP_THUMB = 'FEATURE_IMAGE_DESKTOP_THUMB';
export const FEATURE_IMAGE_DESKTOP_MEDIUM = 'FEATURE_IMAGE_DESKTOP_MEDIUM';
export const FEATURE_IMAGE_MOB_BANNER = 'FEATURE_IMAGE_MOB_BANNER';
export const FEATURE_IMAGE_MOB_THUMB = 'FEATURE_IMAGE_MOB_THUMB';
export const FEATURE_IMAGE_MOB_MEDIUM = 'FEATURE_IMAGE_MOB_MEDIUM';
export const FEATURE_IMAGE_DESKTOP_PLACEHOLDER = 'FEATURE_IMAGE_DESKTOP_PLACEHOLDER';
export const FEATURE_IMAGE_MOB_PLACEHOLDER = 'FEATURE_IMAGE_MOB_PLACEHOLDER';

export const BLOG_DESKTOP = 'BLOG_DESKTOP';
export const BLOG_MOBILE = 'BLOG_MOBILE';

export const FETCH_HOMEPAGE_ALL = 'FETCH_ALL_HOMEPAGE';
export const FETCH_LANDINGPAGE_ALL = 'FETCH_ALL_LANDINGPAGE';
export const FETCH_FORM_FIELD_ALL = 'FETCH_ALL_FORM_FIELD';
export const FETCH_FORM_ALL = 'FETCH_ALL_FORM';

export const ALL_PAGE_IMAGE = 'ALL_PAGE_IMAGE';
export const ALL_PAGE_MOB_IMAGE = 'ALL_PAGE_MOB_IMAGE';


export const FETCH_WIDGET_LIST = 'FETCH_LIST_WIDGET';
export const FETCH_WIDGET_MAP_LIST = 'FETCH_MAP_LIST_WIDGET';

export const FETCH_DEALER_LOCATION = "FETCH_DEALER_LOCATION";

export const TESTIMONIAL_PROFILE_IMG = 'TESTIMONIAL_PROFILE_IMG';
export const TESTIMONIAL_PROFILE_IMG_URL = 'TESTIMONIAL_PROFILE_IMG_URL';
export const UPLOAD_IMG = "UPLOAD_IMG"


export const INT_IMAGE1 = "INT_IMAGE1"
export const INT_IMAGE2 = "INT_IMAGE2"
export const INT_IMAGE3 = "INT_IMAGE3"
export const INT_IMAGE4 = "INT_IMAGE4"
export const INT_IMAGE5 = "INT_IMAGE5"
export const INT_IMAGE6 = "INT_IMAGE6"
export const INT_IMAGE7 = "INT_IMAGE7"
export const INT_IMAGE8 = "INT_IMAGE8"
export const INT_IMAGE9 = "INT_IMAGE9"
export const INT_IMAGE10 = "INT_IMAGE10"
export const INT_IMAGE11 = "INT_IMAGE11"
export const INT_IMAGE12 = "INT_IMAGE12"
export const INT_IMAGE13 = "INT_IMAGE13"
export const INT_IMAGE14 = "INT_IMAGE14"
export const INT_IMAGE15 = "INT_IMAGE15"
export const INT_IMAGE16 = "INT_IMAGE16"
export const INT_IMAGE17 = "INT_IMAGE17"
export const INT_IMAGE18 = "INT_IMAGE18"
export const INT_IMAGE19 = "INT_IMAGE19"
export const INT_IMAGE20 = "INT_IMAGE20"
export const INT_IMAGE21 = "INT_IMAGE21"
export const INT_IMAGE22 = "INT_IMAGE22"
export const INT_IMAGE23 = "INT_IMAGE23"
export const INT_IMAGE24 = "INT_IMAGE24"
export const INT_IMAGE25 = "INT_IMAGE25"
export const INT_IMAGE26 = "INT_IMAGE26"
export const INT_IMAGE27 = "INT_IMAGE27"
export const INT_IMAGE28 = "INT_IMAGE28"
export const INT_IMAGE29 = "INT_IMAGE29"
export const INT_IMAGE30 = "INT_IMAGE30"
export const INT_IMAGE31 = "INT_IMAGE31"
export const INT_IMAGE32 = "INT_IMAGE32"
export const INT_IMAGE33 = "INT_IMAGE33"
export const INT_IMAGE34 = "INT_IMAGE34"
export const INT_IMAGE35 = "INT_IMAGE35"
export const INT_IMAGE36 = "INT_IMAGE36"


export const EXT_IMAGE1 = "EXT_IMAGE1"
export const EXT_IMAGE2 = "EXT_IMAGE2"
export const EXT_IMAGE3 = "EXT_IMAGE3"
export const EXT_IMAGE4 = "EXT_IMAGE4"
export const EXT_IMAGE5 = "EXT_IMAGE5"
export const EXT_IMAGE6 = "EXT_IMAGE6"
export const EXT_IMAGE7 = "EXT_IMAGE7"
export const EXT_IMAGE8 = "EXT_IMAGE8"
export const EXT_IMAGE9 = "EXT_IMAGE9"
export const EXT_IMAGE10 = "EXT_IMAGE10"
export const EXT_IMAGE11 = "EXT_IMAGE11"
export const EXT_IMAGE12 = "EXT_IMAGE12"
export const EXT_IMAGE13 = "EXT_IMAGE13"
export const EXT_IMAGE14 = "EXT_IMAGE14"
export const EXT_IMAGE15 = "EXT_IMAGE15"
export const EXT_IMAGE16 = "EXT_IMAGE16"
export const EXT_IMAGE17 = "EXT_IMAGE17"
export const EXT_IMAGE18 = "EXT_IMAGE18"
export const EXT_IMAGE19 = "EXT_IMAGE19"
export const EXT_IMAGE20 = "EXT_IMAGE20"
export const EXT_IMAGE21 = "EXT_IMAGE21"
export const EXT_IMAGE22 = "EXT_IMAGE22"
export const EXT_IMAGE23 = "EXT_IMAGE23"
export const EXT_IMAGE24 = "EXT_IMAGE24"
export const EXT_IMAGE25 = "EXT_IMAGE25"
export const EXT_IMAGE26 = "EXT_IMAGE26"
export const EXT_IMAGE27 = "EXT_IMAGE27"
export const EXT_IMAGE28 = "EXT_IMAGE28"
export const EXT_IMAGE29 = "EXT_IMAGE29"
export const EXT_IMAGE30 = "EXT_IMAGE30"
export const EXT_IMAGE31 = "EXT_IMAGE31"
export const EXT_IMAGE32 = "EXT_IMAGE32"
export const EXT_IMAGE33 = "EXT_IMAGE33"
export const EXT_IMAGE34 = "EXT_IMAGE34"
export const EXT_IMAGE35 = "EXT_IMAGE35"
export const EXT_IMAGE36 = "EXT_IMAGE36"

export const CONFIG_STATIC_EXT_MOB ="CONFIG_STATIC_EXT_MOB"
export const CONFIG_STATIC_EXT_DESK="CONFIG_STATIC_EXT_DESK"
export const CONFIG_STATIC_INT_DESK="CONFIG_STATIC_INT_DESK"
export const CONFIG_STATIC_INT_MOB="CONFIG_STATIC_INT_MOB"


