import React from 'react';
import { ListGroup } from 'react-bootstrap';
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";
import { DISTRIBUTOR } from "../../../../../constants/userRoles";
import * as _helper from '../../../../../utils/helper'

const NavGroup = ({ layout, group }) => {
    let navItems = '';
    const user = _helper.getUser();

    if (group.children) {
        const groups = group.children;

        navItems = Object.keys(groups).map(item => {

            if (groups[item]?.id === 'landing-page' || groups[item]?.id === 'emicalculator' || groups[item]?.id === 'ecommerce' || groups[item]?.id === 'contactUs'){ 
                if (user?.lob == '1') item = groups[item];
            } else {
                item = groups[item];
            }

            // if (dealer?.user_type !== DISTRIBUTOR && groups[item].id === 'carmodels') {
            //     // item = groups[item];
            // } else {
            //     item = groups[item];
            // }

            switch (item.type) {
                case 'collapse':
                    return <NavCollapse key={item.id} collapse={item} type="main" />
                case 'item':
                    return <NavItem layout={layout} key={item.id} item={item} />;
                default:
                    return false;
            }
        });
    }

    return (
        <React.Fragment>
            {/* <ListGroup.Item as='li' bsPrefix=' ' key={group.id} className="leftMenu__nav-item pcoded-menu-caption"><label>{"abcd"}</label></ListGroup.Item> */}
            {navItems}
        </React.Fragment>
    );
};

export default NavGroup;
