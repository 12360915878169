import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form'
import modelsReducer from './reducers/models';
// import cmsPagesReducer from './reducers/cmsPages';
import homePagesReducer from './reducers/homePage';
import landingPagesReducer from './reducers/landingPage';
import * as reducer from './reducers/fileUploader';
import formFieldsReducer from './reducers/formField';
import contentFormsReducer from './reducers/form';
import allPagesReducer from './reducers/allPages';
import contentWidgetsReducer from './reducers/widgets';
import widgetMapsReducer from './reducers/widgets_map';
import getDealerLocationReducer from "./reducers/dealerLocation";
import * as configurator from './reducers/configurator';

/* Function for mapping all Reducers */
const reducers = combineReducers({
    form: formReducer,
    models: modelsReducer,
    // cmsPages: cmsPagesReducer,
    allPages: allPagesReducer,
    homePage: homePagesReducer,
    forms: contentFormsReducer,
    formField: formFieldsReducer,
    brochure: reducer.modelBrochureReducer,
    mobile_url: reducer.mobileFileReducer,
    desk_url: reducer.desktopFileReducer,
    coloricon_url: reducer.variantColorIconReducer,
    colorimg_url: reducer.variantColorImageReducer,
    banner_url: reducer.bannerImageReducer,
    homepage_banner_url: reducer.homepageBannerImageReducer,
    homepage_banner_url_mob: reducer.homepageBannerImageMobReducer,

    feature_image_desk_banner_url: reducer.featureImageDeskBannerReducer,
    feature_image_desk_thumb_url: reducer.featureImageDeskThumbReducer,
    feature_image_desk_medium_url: reducer.featureImageDeskMediumReducer,
    feature_image_mob_banner_url: reducer.featureImageMobBannerReducer,
    feature_image_mob_thumb_url: reducer.featureImageMobThumbReducer,
    feature_image_mob_medium_url: reducer.featureImageMobMediumReducer,
    feature_image_desk_placeholder_url: reducer.featureImageDeskPlaceholderReducer,
    feature_image_mob_placeholder_url: reducer.featureImageMobPlaceholderReducer,

    ext_mobile_url: reducer.extMobileFileReducer,
    ext_desk_url: reducer.extDesktopFileReducer,
    all_page_url: reducer.allPageImageReducer,
    all_page_mob_url: reducer.allPageMobImageReducer,
    profile_img_url: reducer.testimonialImageReducer,
    slider_img_url: reducer.sliderImageReducer,
    urlMobile: reducer.slidermobileImageReducer,

    blog_desk_url: reducer.blogDesktopImageReducer,
    blog_mobile_url: reducer.blogMobileImageReducer,
    upload_img_url: reducer.uploadImgReducer,

    widget: contentWidgetsReducer,
    widgetMap: widgetMapsReducer,
    landingPage:landingPagesReducer,
    dealerLocation: getDealerLocationReducer,
    dealerLocation:getDealerLocationReducer,
    landingpage_banner_url: reducer.landingBannerImageReducer,
    landingpage_banner_url_mob: reducer.landingBannerImageMobReducer,
    landingpage_banner_url2: reducer.landingBannerImageReducer2,
    landingpage_banner_url_mob2: reducer.landingBannerImageMobReducer2,
    landingpage_banner_url3: reducer.landingBannerImageReducer3,
    landingpage_banner_url_mob3: reducer.landingBannerImageMobReducer3,

    config_int_image1: configurator.configuratorIntImage1Reducer,
    config_int_image2: configurator.configuratorIntImage2Reducer,
    config_int_image3: configurator.configuratorIntImage3Reducer,
    config_int_image4: configurator.configuratorIntImage4Reducer,
    config_int_image5: configurator.configuratorIntImage5Reducer,
    config_int_image6: configurator.configuratorIntImage6Reducer,
    config_int_image7: configurator.configuratorIntImage7Reducer,
    config_int_image8: configurator.configuratorIntImage8Reducer,
    config_int_image9: configurator.configuratorIntImage9Reducer,
    config_int_image10: configurator.configuratorIntImage10Reducer,
    config_int_image11: configurator.configuratorIntImage11Reducer,
    config_int_image12: configurator.configuratorIntImage12Reducer,
    config_int_image13: configurator.configuratorIntImage13Reducer,
    config_int_image14: configurator.configuratorIntImage14Reducer,
    config_int_image15: configurator.configuratorIntImage15Reducer,
    config_int_image16: configurator.configuratorIntImage16Reducer,
    config_int_image17: configurator.configuratorIntImage17Reducer,
    config_int_image18: configurator.configuratorIntImage18Reducer,
    config_int_image19: configurator.configuratorIntImage19Reducer,
    config_int_image20: configurator.configuratorIntImage20Reducer,
    config_int_image21: configurator.configuratorIntImage21Reducer,
    config_int_image22: configurator.configuratorIntImage22Reducer,
    config_int_image23: configurator.configuratorIntImage23Reducer,
    config_int_image24: configurator.configuratorIntImage24Reducer,
    config_int_image25: configurator.configuratorIntImage25Reducer,
    config_int_image26: configurator.configuratorIntImage26Reducer,
    config_int_image27: configurator.configuratorIntImage27Reducer,
    config_int_image28: configurator.configuratorIntImage28Reducer,
    config_int_image29: configurator.configuratorIntImage29Reducer,
    config_int_image30: configurator.configuratorIntImage30Reducer,
    config_int_image31: configurator.configuratorIntImage31Reducer,
    config_int_image32: configurator.configuratorIntImage32Reducer,
    config_int_image33: configurator.configuratorIntImage33Reducer,
    config_int_image34: configurator.configuratorIntImage34Reducer,
    config_int_image35: configurator.configuratorIntImage35Reducer,
    config_int_image36: configurator.configuratorIntImage36Reducer,

    config_ext_image1: configurator.configuratorExtImage1Reducer,
    config_ext_image2: configurator.configuratorExtImage2Reducer,
    config_ext_image3: configurator.configuratorExtImage3Reducer,
    config_ext_image4: configurator.configuratorExtImage4Reducer,
    config_ext_image5: configurator.configuratorExtImage5Reducer,
    config_ext_image6: configurator.configuratorExtImage6Reducer,
    config_ext_image7: configurator.configuratorExtImage7Reducer,
    config_ext_image8: configurator.configuratorExtImage8Reducer,
    config_ext_image9: configurator.configuratorExtImage9Reducer,
    config_ext_image10: configurator.configuratorExtImage10Reducer,
    config_ext_image11: configurator.configuratorExtImage11Reducer,
    config_ext_image12: configurator.configuratorExtImage12Reducer,
    config_ext_image13: configurator.configuratorExtImage13Reducer,
    config_ext_image14: configurator.configuratorExtImage14Reducer,
    config_ext_image15: configurator.configuratorExtImage15Reducer,
    config_ext_image16: configurator.configuratorExtImage16Reducer,
    config_ext_image17: configurator.configuratorExtImage17Reducer,
    config_ext_image18: configurator.configuratorExtImage18Reducer,
    config_ext_image19: configurator.configuratorExtImage19Reducer,
    config_ext_image20: configurator.configuratorExtImage20Reducer,
    config_ext_image21: configurator.configuratorExtImage21Reducer,
    config_ext_image22: configurator.configuratorExtImage22Reducer,
    config_ext_image23: configurator.configuratorExtImage23Reducer,
    config_ext_image24: configurator.configuratorExtImage24Reducer,
    config_ext_image25: configurator.configuratorExtImage25Reducer,
    config_ext_image26: configurator.configuratorExtImage26Reducer,
    config_ext_image27: configurator.configuratorExtImage27Reducer,
    config_ext_image28: configurator.configuratorExtImage28Reducer,
    config_ext_image29: configurator.configuratorExtImage29Reducer,
    config_ext_image30: configurator.configuratorExtImage30Reducer,
    config_ext_image31: configurator.configuratorExtImage31Reducer,
    config_ext_image32: configurator.configuratorExtImage32Reducer,
    config_ext_image33: configurator.configuratorExtImage33Reducer,
    config_ext_image34: configurator.configuratorExtImage34Reducer,
    config_ext_image35: configurator.configuratorExtImage35Reducer,
    config_ext_image36: configurator.configuratorExtImage36Reducer,
    config_static_ext_image_desk:configurator.extConfiguratorStaticDesktopFileReducer,
    config_static_ext_image_mob:configurator.extConfiguratorStaticMobileFileReducer,
    testimonial_profile_img_url: reducer.testimonialprofilereducer,
    config_static_int_image_desk:configurator?.intConfiguratorStaticDesktopFileReducer,
    config_static_int_image_mob:configurator?.intConfiguratorStaticMobileFileReducer
});

export default reducers;
