import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./config/constant";
import { Toaster } from 'react-hot-toast';
import { CONFIG } from "./config/constant";
import * as _helper from "./utils/helper";
import axios from 'axios';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const App = () => {
  useEffect(() => {
    const timeInterval = setInterval(async () => {
      console.log("app.js====")
      let lob = _helper.getLocalStorage("selectedLob");
      console.log(lob)
      if (lob == null) {
        _helper.setLocalStorage("selectedLob", "1");
      }
      let intervalTime = localStorage.getItem("intervalTime") ? localStorage.getItem("intervalTime") : "1";
      let lastAcivity = localStorage.getItem('ula');
      const decrypted = _helper.decrypt(lastAcivity);
      let diffMs = Math.abs(new Date(decrypted) - new Date());
      let seconds = Math.floor((diffMs / 1000));
      let minute = Math.floor((seconds / 60));
      

      const idTokens = await _helper?.decodeToken(localStorage.getItem('id_token')); 
      console.log("e")
      // let diffMss = Math.abs(new Date(idTokens.exp * 1000) - new Date()); 
      let diffMss = Math.abs(new Date(idTokens.iat * 1000 + (15*60*1000) - new Date())); 

      let secondss = Math.floor((diffMss / 1000));
      let minutes = Math.floor((secondss / 60));
      console.log("minutes", minutes);
      console.log("minutesssss", minute)
      console.log("minute difference", minutes - minute)
      let popUpTime = localStorage.getItem('popUpTime') ? localStorage.getItem("popUpTime") : "0";

      if(minutes <= 0) {  
       
        const MySwal = withReactContent(Swal);
       // var timeSession = 'Your session is going to get expire in ' + minutes + ' minutes Do you wish to continue the session ?';
       
       /// session expired in 15 minutes
       var timeSession = 'Your session is going to get expire. Do you wish to continue the session. ?';
       
        const response = await MySwal.fire({ text: timeSession , type: "warning", confirmButtonText: 'Yes', denyButtonText: 'No', showDenyButton: true })
            .then(async (item) => {
                if (item.value) {
                  const response = await axios.post(CONFIG.GENERATE_TOKENS_URL, {}, { withCredentials: true })
                  if (response) {
                    _helper.setLocalStorage('id_token', response.data.data.idToken)
                    _helper.setLocalStorage('access_token', response.data.data.accessToken)
                  } else {
                    clearInterval(timeInterval);
                    _helper.logoutHandler();
                  } 
                    return true;
                } else {
                  clearInterval(timeInterval);
                    _helper.logoutHandler(); 
                    return false;
                }
            }); 

          localStorage.setItem("popUpTime", "1");
       // clearInterval(timeInterval);
        //await _helper.logoutHandler();
      }
       // if (+intervalTime % 15 === 0) { 

      //   localStorage.setItem("intervalTime", "1");

      //   const response = await axios.post(CONFIG.GENERATE_TOKENS_URL, {}, { withCredentials: true })

      //   if (response) {
      //     _helper.setLocalStorage('id_token', response.data.data.idToken)
      //     _helper.setLocalStorage('access_token', response.data.data.accessToken)
      //   } else {
      //     clearInterval(timeInterval);
      //     _helper.logoutHandler();
      //   }
      // } else {
      //   localStorage.setItem("intervalTime", JSON.parse(intervalTime) + 1)
      // }

    }, 60000)
  }, []) 

  return (
    <React.Fragment>
      <Router basename={BASENAME}>
        <Toaster position="top-center" reverseOrder={false} />
        {renderRoutes(routes)}
      </Router>
    </React.Fragment>
  );
}; 

export default App;
